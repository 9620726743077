import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Row,
  Col,
  message,
  Form,
  Breadcrumb,
  Modal,
  Select,
  DatePicker,
  Input,
  Popover,
} from "antd";
import { Axios } from "../../axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, HashRouter } from "react-router-dom";
import G2 from "@antv/g2";
import { AreaSelectForm, ImgViewer, rules, FlieList, popoverTable } from "../../common";
import filter from "../../common/filter";
import time from "../../common/filter";
import Filter from "../../common/filter";
import { Ossupload } from "../../common/upLoad";
import moment from "moment";
import { UploadOutlined } from "@ant-design/icons";
let StatuspageNum = 1,
  timeout;
let detailCustId;
const cStyle = {
  padding: "12px 24px 12px 24px",
  marginBottom: "15px",
  borderRadius: "4px",
  background: "#fff",
  fontSize: "14px",
};
const tStyle = {
  color: "#222",
  lineHeight: "30px",
  fontWeight: "650",
  fontSize: "16px",
  margin: "0px -24px 15px -24px",
  borderBottom: "1px solid #efe5e5",
  padding: "0 24px 8px 24px",
};
const dStyle = {
  color: "#222",
  lineHeight: "30px",
  margin: "10px 0px 0px 0px",
};
const tStyle1 = {
  color: "#222",
  lineHeight: "30px",
  fontWeight: "650",
  fontSize: "16px",
  margin: "0px -24px 15px -24px",
  borderBottom: "1px solid #efe5e5",
  padding: "0 0 8px 0",
};
const TabsStyle = {
  fontWeight: "650",
  fontSize: "16px",
};
class Shengpi2M extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      selectOption: [],
      loading: false,
      confirmLoading: false,
      StatusList: [],
      SearchBrokerName: "",
    };
  }

  componentDidMount () { }
  handleSearch = value => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(
      this.setState(
        {
          SearchBrokerName: value,
        },
        () => {
          StatuspageNum = 1;
          this.StatusListFun(1, this.state.SearchBrokerName);
        }
      ),
      300
    );
  };
  // 单击确定按钮提交表单
  handleSubmit = e => {
    e.preventDefault();
    const { index, detailCustId } = this.props;
    console.log(detailCustId);
    this.formRef.current
      .validateFields()
      .then(async values => {
        values.bizId = detailCustId;
        values.code = index;
        try {
          this.setState({ confirmLoading: true });
          let res = await Axios("POST", "/brokeruser/module/transfer", values);
          if (res.code == "200") {
            this.props.refreshTable();
            message.success(res.message);
            this.setState({ visible: false, confirmLoading: false });
            this.formRef.current.resetFields();
          } else {
            message.error(res.message);
          }
        } catch (err) {
          message.error(err.message);
          this.setState({ confirmLoading: false });
        }
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };
  StatusListFun = async (pageNum, value) => {
    try {
      let obj = {
        pageNum: pageNum,
        pageSize: 10,
        status: "0",
      };
      if (value) obj.brokerUserName = value;
      let { userInfo = {} } = this.props;
      if ("{}" == JSON.stringify(userInfo))
        userInfo = JSON.parse(localStorage.getItem("legal_userInfo") || {});
      if (userInfo.job == "director") obj.departId = userInfo.departId;
      if (
        this.props.operInfo.userRole === "1" ||
        userInfo.job == "senior" ||
        userInfo.job == "director"
      ) {
        Axios("get", "/brokeruser/all/page", obj).then(val => {
          this.setState({
            StatusList: this.state.StatusList.concat(val.data.dataList),
          });
        });
      }
    } catch (err) {
      // message.error(err.message);
    }
  };
  // 弹出框设置
  showModal = () => {
    this.setState({
      StatusList: [],
      visible: true,
    });
    StatuspageNum = 1;
    this.StatusListFun(1);
  };
  handleCancel = e => {
    this.setState({ visible: false });
  };
  handleSelectChange = value => {
    console.log("select=" + value);
  };

  render () {
    const { brokerUserId } = this.props;
    let { userInfo = {} } = this.props;
    if ("{}" == JSON.stringify(userInfo))
      userInfo = JSON.parse(localStorage.getItem("legal_userInfo") || {});
    const { userRole } = this.props.operInfo;
    const { StatusList } = this.state;
    return (
      <span>
        {userRole === "1" || userInfo.job == "senior" || userInfo.job == "director" ? (
          <span onClick={this.showModal} style={{ float: "right" }}>
            {" "}
            {this.props.children}{" "}
          </span>
        ) : null}
        <Modal
          confirmLoading={this.state.loading}
          title="分配业务员"
          open={this.state.visible}
          onOk={this.handleSubmit}
          onCancel={this.handleCancel}
        >
          <Form
            ref={this.formRef}
            layout="horizontal"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
          >
            <Form.Item
              label="业务员"
              style={{ width: "100%" }}
              name="toBrokerUserId"
              // initialValue={brokerUserId}
              rules={[
                {
                  required: true,
                  message: "请选择业务员",
                },
              ]}
            >
              <Select
                onPopupScroll={(e, value) => {
                  e.persist();
                  const { target } = e;
                  if (target.scrollTop + target.offsetHeight + 2 >= target.scrollHeight) {
                    StatuspageNum = StatuspageNum + 1;
                    this.StatusListFun(StatuspageNum, this.state.SearchBrokerName);
                  }
                }}
                placeholder="请选择"
                style={{ width: "100%" }}
                allowClear
                // showSearch
                onSearch={e => {
                  this.handleSearch(e);
                }}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Select.Option key={this.props.userInfo.id} value={this.props.userInfo.id}>
                  {this.props.userInfo.userName || this.props.userInfo.brokerName || ""}
                  （当前自己）
                </Select.Option>

                {StatusList.map(v => {
                  if (this.props.userInfo.id != v.id)
                    return (
                      <Select.Option key={v.id} value={v.id}>
                        {v.userName}（
                        {v.userRole == "1"
                          ? "代理商"
                          : filter.JobType(v.job) || "员工"}
                        ）
                      </Select.Option>
                    );
                })}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </span>
    );
  }
}

let Shengpi2 = connect(
  state => {
    return state;
  },
  dispatch => ({})
)(Shengpi2M);
class Tables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      OperhisList: [],
    };
  }
  componentDidMount () {
    if (this.props.code == "7") {
      this.getOperhisList();
    }
  }
  // 单击确定按钮提交表单
  getOperhisList = async () => {
    let { code, detailCustId } = this.props;
    console.log(this.props, "this.props");
    try {
      let res = await Axios("get", "/brokeruser/module/operhis", {
        code: code,
        bizId: detailCustId,
      });
      if (res.code === "200") {
        this.setState({
          OperhisList: res.data || [],
        });
      } else {
        message.error(res.message);
      }
    } catch (err) {
      this.setState({ loading: false });
    }
  };
  render () {
    return (
      <Table
        dataSource={this.state.OperhisList}
        columns={[
          {
            title: "ID",
            dataIndex: "id",
          },
          {
            title: "操作类型",
            dataIndex: "operAction",
          },
          {
            title: "操作人",
            dataIndex: "operName",
          },
          {
            title: "操作时间",
            dataIndex: "operDate",
            render: (t, r) => filter.dateTime_HM(t + r.operTime),
          },
          {
            title: "备注",
            dataIndex: "memo",
          },
        ]}
        size="middle"
        pagination={
          this.state.OperhisList.length > 10
            ? {
              size: "large",
              showQuickJumper: true,
              pageSize: 10,
            }
            : false
        }
        bordered={true}
      />
    );
  }
}
class ContactList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      confirmLoading: false,
      childrenList: [],
    };
  }
  render () {
    return (
      <span>
        <span
          onClick={() => {
            this.setState({ visible: true });
          }}
        >
          {this.props.children}
        </span>
        <Modal
          confirmLoading={this.state.confirmLoading}
          title="其他联系人"
          visible={this.state.visible}
          width="70%"
          onCancel={() => {
            this.setState({
              visible: false,
            });
          }}
          footer={null}
        >
          <Table
            dataSource={this.props.Infos}
            rowKey={record => record.id}
            columns={[
              {
                title: "姓名",
                dataIndex: "otherDebtName",
                render: (t, r) => <span>{t || "-"}</span>,
              },
              {
                title: "关系",
                dataIndex: "otherDeptRelation",
                render: (t, r) => <span>{t || "-"}</span>,
              },
              {
                title: "手机号",
                dataIndex: "otherDebtMobile",
                render: (t, r) => <span>{t || "-"}</span>,
              },
              {
                title: "身份证",
                dataIndex: "otherDebtIdCard",
                render: t => t || "-",
              },
              {
                title: "家庭地址",
                dataIndex: "otherDebtHomeAddress",
                render: t => t || "-",
              },
              {
                title: "工作地址",
                dataIndex: "otherDebtWorkAddress",
                render: t => t || "-",
              },
            ]}
            size="middle"
            pagination={{
              size: "large",
              showQuickJumper: true,
              pageSize: 10,
            }}
            bordered={true}
          />
        </Modal>
      </span>
    );
  }
}
const LegalCaseRecoveriesDetail = ({ id, type }) => {
  const [loading, setLoading] = useState(false);
  const [valueInfo, setValueInfo] = useState({
    batchInfo: {},
    caseInfo: {},
    propertyReturnInfos: [],
  });
  const getDataDetail = async () => {
    try {
      setLoading(true);
      let res = await Axios("get", "/property/batch/case/detail", { caseId: id });
      if (res.code == "200") {
        setValueInfo(res.data || {});
      } else {
        message.error(res.message);
      }
      setLoading(false);
    } catch (err) {
      // message.error(err.message)
      setLoading(false);
    }
  };
  useEffect(() => {
    getDataDetail();
  }, []);
  return (
    <div className="pageContainer">
      <Row className="headNav">
        <Col span={24}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={type == 1 ? "/LegalCaseManage" : "/RecoveriesManage"}>
                {type == 1 ? "案件管理" : "回款管理"}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{type == 1 ? "案件详情" : "回款详情"}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={24} className="title">
          <span className="name">{type == 1 ? "案件详情" : "回款详情"}</span>
        </Col>
      </Row>
      <div className="scrollContainer">
        <div style={cStyle}>
          <div style={tStyle}>基本信息</div>
          <Row style={dStyle}>
            <Col span={8}>案件ID：{valueInfo.caseInfo.caseId || "-"}</Col>
          </Row>
          <Row style={dStyle}>
            <Col span={8}>房号：{valueInfo.caseInfo.roomNo || "-"}</Col>
            <Col span={8}>姓名：{valueInfo.caseInfo.name || "-"}</Col>
            <Col span={8}>联系电话：{valueInfo.caseInfo.mobileNo || "-"}</Col>
          </Row>
          <Row style={dStyle}>
            <Col span={8}>欠费年限：{valueInfo.caseInfo.oweAmtYear}</Col>
            <Col span={8}>
              欠费金额：
              {valueInfo.caseInfo.oweAmt
                ? valueInfo.caseInfo.oweAmt +
                ((valueInfo.caseInfo.oweAmt + "").includes("元") ? "" : "元")
                : "-"}
            </Col>
            <Col span={8}>
              案件类型：
              {valueInfo.caseInfo.caseType == "1"
                ? "催收案件"
                : valueInfo.caseInfo.caseType == "2"
                  ? "诉讼案件"
                  : "-"}
            </Col>
          </Row>
          <Row style={dStyle}>
            <Col span={8}>案件备注：{valueInfo.caseInfo.caseMemo || "-"}</Col>
          </Row>
        </div>
        <div style={cStyle}>
          <div style={tStyle}>批次信息</div>
          <Row style={dStyle}>
            <Col span={8}>批次号：{valueInfo.batchInfo.batchNo || "-"}</Col>
            <Col span={8}>批次ID：{valueInfo.batchInfo.batchId || "-"}</Col>
            <Col span={8}>
              催收区域：{valueInfo.batchInfo.collectionProvince || "-"}{" "}
              {valueInfo.batchInfo.collectionCity || ""}
            </Col>
          </Row>
          <Row style={dStyle}>
            <Col span={8}>客户名称：{valueInfo.batchInfo.customName || "-"}</Col>
            <Col span={8}>案件等级：{valueInfo.batchInfo.caseLevel || "-"}</Col>
            <Col span={8}>业务渠道：{valueInfo.batchInfo.channel || "-"}</Col>
          </Row>
          <Row style={dStyle}>
            <Col span={8}>销售专员：{valueInfo.batchInfo.saleMan || "-"}</Col>
            <Col span={8}>物业市场运维：{valueInfo.batchInfo.propertyMarketOperName || "-"}</Col>
            <Col span={8}>委案日期：{valueInfo.batchInfo.entrustDateStr || "-"}</Col>
          </Row>
          <Row style={dStyle}>
            <Col>备注：{valueInfo.batchInfo.batchMemo || "-"}</Col>
          </Row>
        </div>
        <div style={cStyle}>
          <div style={tStyle}>回款信息</div>
          <Table
            dataSource={valueInfo.propertyReturnInfos || []}
            columns={[
              {
                title: "序号",
                dataIndex: "index",
                render: (text, record, index) => index + 1,
              },
              {
                title: "回款金额（元）",
                dataIndex: "returnAmt",
              },
              {
                title: "回款日期",
                dataIndex: "returnDate",
                render: (text, record) => (text ? text.slice(0, 10) : "-"),
              },
              popoverTable(6, {
                title: "备注",
                dataIndex: "returnMemo",
              }),
              {
                title: "操作人",
                dataIndex: "createdName",
              },
              {
                title: "审核状态",
                dataIndex: "processStatus",
                render: (text, record) => (text == "1" ? "未审核" : text == "2" ? "审核通过" : "-"),
              },
            ]}
            size="middle"
            pagination={false}
            bordered={true}
          />
        </div>
        {/* <div style={cStyle}>
                    <div style={tStyle}>跟进记录</div>
                    <Table
                        rowKey={record => record.id}
                        dataSource={valueInfo.propertyFollowRecords || []}
                        columns={[
                            {
                                title: "序号",
                                dataIndex: "index",
                                render: (text, record, index) => index + 1,
                            },
                            popoverTable(50, {
                                title: "跟进备注",
                                dataIndex: "followMemo",
                            }),
                            {
                                title: "跟进人",
                                dataIndex: "followOperName",
                            },
                            {
                                title: "跟进时间",
                                dataIndex: "followTime",
                            },
                            {
                                title: "下次跟进时间",
                                dataIndex: "nextFollowTime",
                                render: (text, record) => (text ? text.slice(0, 10) : "-"),
                            },
                        ]}
                        size="middle"
                        pagination={{
                            size: "large",
                            showQuickJumper: true,
                            pageSize: 10,
                        }}
                        bordered={true}
                    />
                </div> */}
      </div>
    </div>
  );
};
export { Shengpi2, Tables, ContactList, LegalCaseRecoveriesDetail };
