import React, { Component } from "react";
import { Row, Breadcrumb, message, Form, Select, Divider, Col, Tooltip, Table, Modal, Spin, DatePicker, Tabs, Radio, Button } from "antd";
import { Axios } from "../../axios";
import Filter from "../../common/filter";
import G2 from "@antv/g2";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import { Personal, Setting } from "./personal";
import { Funnel } from "./filter";

const { TabPane } = Tabs;
const { Option } = Select;
const { MonthPicker } = DatePicker;
let TabsArr = ["意向客户数", "付费客户数", "待续费客户数", "续费客户数", "成交额"];
let TextName = {
  0: {
    title: "新增意向客户统计图（单位：个）",
    tr: "新增意向客户数",
    tr1: "意向客户总数",
    tr2: "意向客户个人情况",
  },
  1: {
    title: "新增付费客户统计图（单位：个）",
    tr: "新增付费客户数",
    tr1: "付费客户总数",
    tr2: "付费客户个人情况",
  },
  2: {
    title: "新增待续费客户统计图（单位：个）",
    tr: "新增待续费客户数",
    tr1: "待续费客户总数",
    tr2: "待续费客户个人情况",
  },
  3: {
    title: "新增续费客户统计图（单位：个）",
    tr: "新增续费客户数",
    tr1: "续费客户总数",
    tr2: "续费客户个人情况",
  },
  4: {
    title: "新增成交额统计图（单位：万元）",
    tr: "新增成交额",
    tr1: "成交额总数",
    tr2: "成交额个人情况",
  },
};
if (!window.routerCache.perforIndicators.Funnel) {
  window.routerCache.perforIndicators.Funnel = {};
}
window.routerCache.perforIndicators.other = {
  thisObj: {},
  nowsaleCode: window.routerCache.perforIndicators.saleCode || "0",
};
class PerforIndicators extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Times: window.routerCache.perforIndicators.Times || "-",
      activeKey: localStorage.getItem("perforIndicators-activeKey") || "1",
      personal: window.routerCache.perforIndicators.personal || false,
      perData: window.routerCache.perforIndicators.perData || {},
      saleCode: window.routerCache.perforIndicators.saleCode || "0",
    };
  }

  componentDidMount () {
    window.winhcFn.changeDept = () => {
      try {
        if (this.state.activeKey) {
          window.routerCache.perforIndicators.other.thisObj["fengkong" + this.state.activeKey]();
        }
      } catch (err) {
        console.log(err);
      }
    };
  }
  changePer = (data, t) => {
    delete data.sectionCode;
    data.dateFrom = t.timeFrom;
    data.dateEnd = t.timeEnd;
    this.setState({
      personal: true,
      perData: data,
      Times: t.date,
    });
  };
  componentWillUnmount () {
    window.routerCache.perforIndicators.personal = this.state.personal;
    window.routerCache.perforIndicators.perData = this.state.perData;
    window.routerCache.perforIndicators.saleCode = this.state.saleCode;
    window.routerCache.perforIndicators.Times = this.state.Times;
    window.winhcFn.changeDept = false;
    window.winhcFn.menuClickFn = false;
  }

  render () {
    return (
      <div className="pageContainer perforIndicators">
        <Row className="headNav">
          <Col span={24}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link
                  to="/PerforIndicators"
                  onClick={() => {
                    if (this.state.personal) {
                      this.setState(
                        {
                          personal: false,
                        },
                        () => {
                          setTimeout(() => {
                            window.winhcFn.changeDept();
                          }, 500);
                        }
                      );
                    }
                  }}
                >
                  业务分析
                </Link>
              </Breadcrumb.Item>
              {this.state.personal ? <Breadcrumb.Item>{TextName[this.state.activeKey].tr2}</Breadcrumb.Item> : ""}
            </Breadcrumb>
          </Col>
          <Col span={24} className="title">
            <span className="name">业务分析管理</span>
          </Col>
        </Row>
        <div className="scrollContainer" style={this.state.personal ? { display: "none" } : {}}>
          <div className="scrollMain" style={{ paddingTop: "0" }}>
            <Tabs
              activeKey={this.state.activeKey}
              onChange={item => {
                this.setState(
                  {
                    activeKey: item,
                  },
                  () => {
                    localStorage.setItem("perforIndicators-activeKey", this.state.activeKey);
                  }
                );
              }}
            >
              {TabsArr.map((v, i) => (
                <TabPane tab={v} key={i}>
                  <CommonPage key={i} type={"fengkong" + i} changePer={this.changePer} saleCode={i} activeKey={this.state.activeKey} />
                </TabPane>
              ))}

              <TabPane tab="销售漏斗" key="5">
                <Funnel />
              </TabPane>
            </Tabs>
          </div>
        </div>
        {this.state.personal ? (
          <div className="scrollContainer">
            <div className="scrollMain">
              <Personal type={"fengkong5"} perData={this.state.perData} saleCode={this.state.activeKey} Times={this.state.Times} />
            </div>
          </div>
        ) : (
          ""
        )}
        <style>{`
                    .ant-table-pagination {
                        padding-right: 10px;
                    }
                    .perforIndicators .ant-tabs-bar{
                        margin-bottom:0;
                    }
                    .perforIndicators .ant-tabs-nav-scroll{
                        background: #fff;
                    }
                `}</style>
      </div>
    );
  }
}

export default PerforIndicators;

class CommonPageM extends Component {
  constructor(props) {
    super(props);
    let jiduStart = "",
      jiduEnd = "",
      sl = parseInt((new Date().getMonth() + 1) / 4);
    if (sl == 0) {
      jiduStart = new Date().getFullYear() - 1 + "-04-01";
      jiduEnd = new Date().getFullYear() + "-03-31";
    } else if (sl == 1) {
      jiduStart = new Date().getFullYear() - 1 + "-07-01";
      jiduEnd = new Date().getFullYear() + "-06-30";
    } else if (sl == 2) {
      jiduStart = new Date().getFullYear() - 1 + "-10-01";
      jiduEnd = new Date().getFullYear() + "-09-30";
    } else if (sl == 3) {
      jiduStart = new Date().getFullYear() + "-01-01";
      jiduEnd = new Date().getFullYear() + "-12-31";
    }
    console.log(jiduStart, jiduEnd);
    let quan = JSON.parse(localStorage.getItem("legal_userInfo") || '{}')
    this.state = {
      spinning: false,
      sectionCode: window.routerCache.perforIndicators[this.props.type].sectionCode || "1",
      dateFrom1: window.routerCache.perforIndicators[this.props.type].dateFrom1 || moment(Filter.timetrans(new Date(new Date().getFullYear(), 0, 1), "day"), "YYYY-MM-DD"),
      dateEnd1: window.routerCache.perforIndicators[this.props.type].dateEnd1 || moment(Filter.timetrans(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), "day"), "YYYY-MM-DD"),
      dateFrom2: window.routerCache.perforIndicators[this.props.type].dateFrom2 || jiduStart,
      dateEnd2: window.routerCache.perforIndicators[this.props.type].dateEnd2 || jiduEnd,
      dateFrom3: window.routerCache.perforIndicators[this.props.type].dateFrom3 || new Date().getFullYear() + "-01-01",
      dateEnd3: window.routerCache.perforIndicators[this.props.type].dateEnd3 || new Date().getFullYear() + "-12-31",
      allData: {},
      chartStyle: {},
      queryRange:
        ["4", "5", "6", "senior"].includes(String((this.props.userInfo || {}).brokerType || (this.props.userInfo || {}).job || (quan || {}).brokerType || (quan || {}).job)) ? 'all' : 'self'
    };
    this.sendBody = {};
    console.log(this.props, 'this.props.userInfothis.props.userInfo');
  }
  getData = async () => {
    try {
      this.sendBody.sectionCode = this.state.sectionCode;
      this.sendBody.queryRange = this.state.queryRange || undefined;
      if (this.sendBody.sectionCode == "1") {
        this.sendBody.dateFrom = Filter.timetrans(this.state.dateFrom1._d, "day").substr(0, 8) + "01";
        this.sendBody.dateEnd = Filter.timetrans(new Date(this.state.dateEnd1._d.getFullYear(), this.state.dateEnd1._d.getMonth() + 1, 0), "day");
      } else if (this.sendBody.sectionCode == "2") {
        this.sendBody.dateFrom = this.state.dateFrom2;
        this.sendBody.dateEnd = this.state.dateEnd2;
      } else if (this.sendBody.sectionCode == "3") {
        this.sendBody.dateFrom = this.state.dateFrom3;
        this.sendBody.dateEnd = this.state.dateEnd3;
      }

      this.sendBody.saleCode = this.props.saleCode;
      this.setState({ spinning: true });
      let url = "/brokeruser/customer/risk";
      if (this.props.saleCode === 4) {
        url = "/brokeruser/amt/risk";
      }
      let res = await Axios("get", url, this.sendBody);
      if (res.code === "200") {
        res.data.businessAnalysisVOS.forEach((item, i) => {
          item.index = i + 1;
          if (this.sendBody.sectionCode == "1") {
            item.date = item.timeFrom.substr(0, 7);
          } else if (this.sendBody.sectionCode == "2") {
            if (item.timeFrom.substring(5, 7) == "01") {
              item.date = item.timeFrom.substr(0, 4) + "第一季度";
            } else if (item.timeFrom.substring(5, 7) == "04") {
              item.date = item.timeFrom.substr(0, 4) + "第二季度";
            } else if (item.timeFrom.substring(5, 7) == "07") {
              item.date = item.timeFrom.substr(0, 4) + "第三季度";
            } else if (item.timeFrom.substring(5, 7) == "10") {
              item.date = item.timeFrom.substr(0, 4) + "第四季度";
            }
          } else if (this.sendBody.sectionCode == "3") {
            item.date = item.timeFrom.substr(0, 4);
          }
        });
        if (this.props.saleCode == 4) {
          res.data.businessAnalysisVOS.push({
            index: false,
            date: "合计",
            newAmt: res.data.newAmtSum,
            totalAmt: res.data.totalAmtSum,
            rate: res.data.totalRate,
          });
        } else {
          res.data.businessAnalysisVOS.push({
            index: false,
            date: "合计",
            newCustomer: res.data.newCustomerSum,
            totalCustomer: res.data.totalCustomerSum,
            rate: res.data.totalRate,
          });
        }
        this.setState(
          {
            allData: res.data,
            chartStyle:
              res.data.businessAnalysisVOS.length > 20
                ? {
                  width: 150 + res.data.businessAnalysisVOS.length * 60 + "px",
                  margin: "0 auto",
                }
                : { overflow: "hidden" },
          },
          this.getChart
        );

        console.log(res);
      } else {
        message.error(res.message);
      }
      this.setState({ spinning: false });
    } catch (err) {
      if (err.message) {
        message.error(err.message);
      }
      this.setState({ spinning: false });
      console.log(err);
    }
  };
  getChart = () => {
    let chartData = [];
    if (this.props.saleCode === 4) {
      this.state.allData.businessAnalysisVOS.forEach(val => {
        if (val.index) {
          chartData.push({
            name: TextName[this.props.saleCode].tr,
            日期: val.date,
            金额: window._SYT_util.formatMoney(window._SYT_util.math.accDiv(val.newAmt * 1, 10000), 2) * 1, // 变成万元
            totalCustomer: window._SYT_util.formatMoney(window._SYT_util.math.accDiv(val.totalAmt * 1, 10000), 2) * 1, // 变成万元
            rate: val.rate,
          });
          // chartData.push({
          //     name: TextName[this.props.saleCode].tr1,
          //     日期: val.date,

          //     rate: val.rate,
          // });
        }
      });
    } else {
      this.state.allData.businessAnalysisVOS.forEach(val => {
        if (val.index) {
          chartData.push({
            name: TextName[this.props.saleCode].tr,
            日期: val.date,
            金额: val.newCustomer,
            totalCustomer: val.totalCustomer,
            rate: val.rate,
          });
        }
      });
    }
    let name = this.props.saleCode === 4 ? "万" : "人";
    document.getElementById("chartId" + this.props.saleCode).innerHTML = "";
    let chart = new G2.Chart({
      container: "chartId" + this.props.saleCode,
      forceFit: true,
      height: 300,
      padding: [15, 50, 70, 100],
    });
    chart.source(chartData);
    chart.interval().position("日期*金额").color("name").adjust().size(40);
    chart.line().position("日期*rate").color("orange");

    chart.tooltip(true, {
      custom: true, // 表示使用自定义的模板显示 tooltip
      // html: '', // tooltip 的 html 外层模板
      // itemTpl:'<tr><td></td><td style="color:{color}">{name}</td><td>{value}</td></tr>', // 使用 html 时每一个显示项的模板，默认支持 index, color, name, value 这四个字段。
      offset: 50, // 偏移量，设置tooltip 显示位置距离 x 轴方向上的偏移
      customFollow: false, // 设置 tooltip 是否跟随鼠标移动，默认为 true，跟随。
      htmlContent: (title, items) => {
        console.log(title, items);
        let _html = '<div class="ac-tooltip" style="position:absolute;visibility: hidden;background-color:rgba(255, 255, 255, 0.9);padding:10px;border-radius:5px;color:#666;box-shadow: 0px 0 5px #ccc;"><div style="line-height:30px">' + title + "</div>";
        items.forEach((item, key) => {
          if (key == 0) {
            _html += '<div style="line-hegiht:30px"><span style="color:#38a0ff">' + item.name + '</span><span style="margin-left:15px">' + item.value + name + "</span></div>";
            _html += '<div style="line-hegiht:30px"><span style="color:#30bd73">' + TextName[this.props.saleCode].tr1 + '</span><span style="margin-left:15px">' + item.point._origin.totalCustomer + name + "</span></div>";
          } else if (key == 1) {
            _html += '<div style="line-hegiht:30px"><span style="color:#f9a100">' + item.name + '</span><span style="margin-left:15px">' + item.value + "%</span></div>";
          }
        });
        _html += "</div>";
        return _html;
      },
    });

    chart.scale("rate", {
      alias: "新增占比",
    });

    chart.axis("金额", {});

    chart.axis("rate", {
      label: {
        formatter: function formatter (val) {
          return val + "%";
        },
      },
    });

    chart.render();
  };
  componentDidMount () {
    this.getData();
    window.routerCache.perforIndicators.other.thisObj[this.props.type] = this.getData;
  }

  componentWillUnmount () {
    window.routerCache.perforIndicators[this.props.type].sectionCode = this.state.sectionCode;
    window.routerCache.perforIndicators[this.props.type].dateFrom1 = this.state.dateFrom1;
    window.routerCache.perforIndicators[this.props.type].dateEnd1 = this.state.dateEnd1;
    window.routerCache.perforIndicators[this.props.type].dateFrom2 = this.state.dateFrom2;
    window.routerCache.perforIndicators[this.props.type].dateEnd2 = this.state.dateEnd2;
    window.routerCache.perforIndicators[this.props.type].dateFrom3 = this.state.dateFrom3;
    window.routerCache.perforIndicators[this.props.type].dateEnd3 = this.state.dateEnd3;
    // window.routerCache.perforIndicators[this.props.type].queryRange = this.state.queryRange;
  }

  render () {
    const { operInfo = {}, userInfo = {} } = this.props;
    const quanxian = String(userInfo.brokerType || userInfo.job || "");
    console.log(quanxian, userInfo, 'quanxianquanxianquanxianquanxian');
    const { type, activeKey } = this.props;
    const { allData } = this.state;
    let columnsDate = [
      {
        title: "序号",
        dataIndex: "index",
      },
      {
        title: "日期",
        dataIndex: "date",
      },
      {
        title: TextName[this.props.saleCode].tr,
        dataIndex: this.props.saleCode === 4 ? "newAmt" : "newCustomer",
        render: (t, r) => (this.props.saleCode === 4 ? window._SYT_util.formatMoney(window._SYT_util.math.accDiv(t * 1, 10000), 2) : t),
      },
      {
        title: TextName[this.props.saleCode].tr1,
        dataIndex: this.props.saleCode === 4 ? "totalAmt" : "totalCustomer",
        render: (t, r) => (this.props.saleCode === 4 ? window._SYT_util.formatMoney(window._SYT_util.math.accDiv(t * 1, 10000), 2) : t),
      },
      {
        title: "新增占比",
        dataIndex: "rate",
        render: t => t + "%",
      },
    ];
    if (operInfo.userRole !== "2")
      columnsDate.push({
        title: "操作",
        dataIndex: "do",
        render: (t, r) => (
          <span>
            {r.index ? (
              <span
                className="whc_link"
                onClick={() => {
                  this.props.changePer(this.sendBody, r);
                }}
              >
                查看明细
              </span>
            ) : (
              ""
            )}
          </span>
        ),
      });
    return (
      <div style={{ padding: "20px 0" }}>
        <Row type="flex" style={{ position: "relative" }}>
          <Radio.Group
            value={this.state.sectionCode}
            onChange={e => {
              this.setState(
                {
                  sectionCode: e.target.value,
                },
                this.getData
              );
            }}
          >
            <Radio.Button value="1">按月</Radio.Button>
            <Radio.Button value="2">按季度</Radio.Button>
            <Radio.Button value="3">按年</Radio.Button>
          </Radio.Group>
        </Row>
        <div
          style={
            this.state.sectionCode == "1"
              ? {
                padding: "15px 0",
                display: "flex",
                justifyContent: "space-between",
              }
              : { display: "none" }
          }
        >
          <span>
            选择月份：
            <MonthPicker
              placeholder="开始时间"
              value={this.state.dateFrom1}
              style={{ width: 150 }}
              onChange={e => {
                if (e) {
                  if (Filter.timetrans(this.state.dateEnd1._d, "pattern").substr(0, 6) * 1 - Filter.timetrans(e._d, "pattern").substr(0, 6) * 1 > 100) {
                    message.warning("最多可展示12个月数据信息");
                    return;
                  }
                  this.setState(
                    {
                      dateFrom1: e,
                    },
                    this.getData
                  );
                }
              }}
            />
            &nbsp;&nbsp;~&nbsp;&nbsp;
            <MonthPicker
              placeholder="结束时间"
              value={this.state.dateEnd1}
              style={{ width: 150 }}
              onChange={e => {
                if (e) {
                  if (Filter.timetrans(e._d, "pattern").substr(0, 6) * 1 - Filter.timetrans(this.state.dateFrom1._d, "pattern").substr(0, 6) * 1 > 100) {
                    message.warning("最多可展示12个月数据信息");
                    return;
                  }
                  this.setState(
                    {
                      dateEnd1: e,
                    },
                    this.getData
                  );
                }
              }}
            />
          </span>
          <span>
            数据范围：
            <Select
              disabled={quanxian == 'groupLeader' && activeKey == 1}
              defaultValue="lucy"
              value={this.state.queryRange}
              style={{ width: 120 }}
              onChange={e => {
                this.setState(
                  {
                    queryRange: e,
                  },
                  this.getData
                );
              }}
            >
              {["4", "5", "6", "senior"].includes(quanxian) && <Option value="all">全部</Option>}
              {/* 部门组长的付费客户数只可以看本人的 */}
              {["director", "groupLeader"].includes(quanxian) && <Option value="depart">部门</Option>}
              <Option value="self">本人</Option>
            </Select>
          </span>
        </div>
        <div style={this.state.sectionCode == "2" ? { padding: "15px 0" } : { display: "none" }}>
          选择季度：
          <Select
            placeholder="开始时间"
            style={{ width: 150 }}
            value={this.state.dateFrom2}
            onChange={e => {
              if (parseInt(this.state.dateEnd2.replace("-", "")) - parseInt(e.replace("-", "")) >= 100) {
                message.warning("最多可展示4个季度数据信息");
                return;
              }
              this.setState(
                {
                  dateFrom2: e,
                },
                this.getData
              );
            }}
          >
            <Option value="2022-10-01">2022年第四季度</Option>
            <Option value="2022-07-01">2022年第三季度</Option>
            <Option value="2022-04-01">2022年第二季度</Option>
            <Option value="2022-01-01">2022年第一季度</Option>
            <Option value="2021-10-01">2021年第四季度</Option>
            <Option value="2021-07-01">2021年第三季度</Option>
            <Option value="2021-04-01">2021年第二季度</Option>
            <Option value="2021-01-01">2021年第一季度</Option>
            <Option value="2020-10-01">2020年第四季度</Option>
            <Option value="2020-07-01">2020年第三季度</Option>
            <Option value="2020-04-01">2020年第二季度</Option>
            <Option value="2020-01-01">2020年第一季度</Option>
            <Option value="2019-10-01">2019年第四季度</Option>
            <Option value="2019-07-01">2019年第三季度</Option>
            <Option value="2019-04-01">2019年第二季度</Option>
            <Option value="2019-01-01">2019年第一季度</Option>
            <Option value="2018-10-01">2018年第四季度</Option>
            <Option value="2018-07-01">2018年第三季度</Option>
            <Option value="2018-04-01">2018年第二季度</Option>
            <Option value="2018-01-01">2018年第一季度</Option>
            <Option value="2017-10-01">2017年第四季度</Option>
            <Option value="2017-07-01">2017年第三季度</Option>
            <Option value="2017-04-01">2017年第二季度</Option>
            <Option value="2017-01-01">2017年第一季度</Option>
            <Option value="2016-10-01">2016年第四季度</Option>
            <Option value="2016-07-01">2016年第三季度</Option>
            <Option value="2016-04-01">2016年第二季度</Option>
            <Option value="2016-01-01">2016年第一季度</Option>
            <Option value="2015-10-01">2015年第四季度</Option>
            <Option value="2015-07-01">2015年第三季度</Option>
            <Option value="2015-04-01">2015年第二季度</Option>
            <Option value="2015-01-01">2015年第一季度</Option>
          </Select>
          &nbsp;&nbsp;~&nbsp;&nbsp;
          <Select
            placeholder="结束时间"
            value={this.state.dateEnd2}
            style={{ width: 150 }}
            onChange={e => {
              if (parseInt(e.replace("-", "")) - parseInt(this.state.dateFrom2.replace("-", "")) >= 100) {
                message.warning("最多可展示4个季度数据信息");
                return;
              }
              this.setState(
                {
                  dateEnd2: e,
                },
                this.getData
              );
            }}
          >
            <Option value="2022-12-31">2022年第四季度</Option>
            <Option value="2022-09-30">2022年第三季度</Option>
            <Option value="2022-06-30">2022年第二季度</Option>
            <Option value="2022-03-31">2022年第一季度</Option>
            <Option value="2021-12-31">2021年第四季度</Option>
            <Option value="2021-09-30">2021年第三季度</Option>
            <Option value="2021-06-30">2021年第二季度</Option>
            <Option value="2021-03-31">2021年第一季度</Option>
            <Option value="2020-12-31">2020年第四季度</Option>
            <Option value="2020-09-30">2020年第三季度</Option>
            <Option value="2020-06-30">2020年第二季度</Option>
            <Option value="2020-03-31">2020年第一季度</Option>
            <Option value="2019-12-31">2019年第四季度</Option>
            <Option value="2019-09-30">2019年第三季度</Option>
            <Option value="2019-06-30">2019年第二季度</Option>
            <Option value="2019-03-31">2019年第一季度</Option>
            <Option value="2018-12-31">2018年第四季度</Option>
            <Option value="2018-09-30">2018年第三季度</Option>
            <Option value="2018-06-30">2018年第二季度</Option>
            <Option value="2018-03-31">2018年第一季度</Option>
            <Option value="2017-12-31">2017年第四季度</Option>
            <Option value="2017-09-30">2017年第三季度</Option>
            <Option value="2017-06-30">2017年第二季度</Option>
            <Option value="2017-03-31">2017年第一季度</Option>
            <Option value="2016-12-31">2016年第四季度</Option>
            <Option value="2016-09-30">2016年第三季度</Option>
            <Option value="2016-06-30">2016年第二季度</Option>
            <Option value="2016-03-31">2016年第一季度</Option>
            <Option value="2015-12-31">2015年第四季度</Option>
            <Option value="2015-09-30">2015年第三季度</Option>
            <Option value="2015-06-30">2015年第二季度</Option>
            <Option value="2015-03-31">2015年第一季度</Option>
          </Select>
        </div>
        <div style={this.state.sectionCode == "3" ? { padding: "15px 0" } : { display: "none" }}>
          选择年份：
          <Select
            placeholder="开始时间"
            style={{ width: 150 }}
            value={this.state.dateFrom3}
            onChange={e => {
              this.setState(
                {
                  dateFrom3: e,
                },
                this.getData
              );
            }}
          >
            <Option value="2022-01-01">2022</Option>
            <Option value="2021-01-01">2021</Option>
            <Option value="2020-01-01">2020</Option>
            <Option value="2019-01-01">2019</Option>
            <Option value="2018-01-01">2018</Option>
            <Option value="2017-01-01">2017</Option>
            <Option value="2016-01-01">2016</Option>
            <Option value="2015-01-01">2015</Option>
          </Select>
          &nbsp;&nbsp;~&nbsp;&nbsp;
          <Select
            placeholder="结束时间"
            style={{ width: 150 }}
            value={this.state.dateEnd3}
            onChange={e => {
              this.setState(
                {
                  dateEnd3: e,
                },
                this.getData
              );
            }}
          >
            <Option value="2022-12-31">2022</Option>
            <Option value="2021-12-31">2021</Option>
            <Option value="2020-12-31">2020</Option>
            <Option value="2019-12-31">2019</Option>
            <Option value="2018-12-31">2018</Option>
            <Option value="2017-12-31">2017</Option>
            <Option value="2016-12-31">2016</Option>
            <Option value="2015-12-31">2015</Option>
          </Select>
        </div>
        <div style={{ marginTop: "15px", marginBottom: "10px" }}>
          {/* 新增付费客户统计图（单位：个） */}
          {TextName[this.props.saleCode].title}
        </div>
        <Divider />
        <div
          style={{
            width: "100%",
            overflow: "auto",
            overflowY: "hidden",
          }}
        >
          <div style={this.state.chartStyle} id={"chartId" + this.props.saleCode} className=""></div>
        </div>
        {this.state.spinning ? (
          <Spin
            size="large"
            spinning={this.state.spinning}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: "30%",
              left: "0",
              right: "0",
            }}
          />
        ) : (
          ""
        )}

        <Row type="flex" justify="space-between" style={{ marginBottom: "15px" }}>
          <div style={{ fontWeight: "bold", paddingTop: "5px" }}>
            统计列表（单位：
            {this.props.saleCode === 4 ? "万元" : "个"}）
          </div>
        </Row>

        <Table
          dataSource={allData.businessAnalysisVOS || []}
          loading={this.state.spinning}
          columns={columnsDate}
          size="middle"
          pagination={{
            size: "large",
            showQuickJumper: true,
            pageSize: 10,
          }}
        />
      </div>
    );
  }
}
let CommonPage = connect(state => {
  return state;
})(CommonPageM);
