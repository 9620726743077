import React from "react";
import { Button, Table, Row, Col, message, Form, Breadcrumb, Input, Modal, Select } from "antd";
import { Axios } from "../../axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import * as tabWindowActions from "../../redux/actions/tabwindow";
import { Link, HashRouter } from "react-router-dom";
import G2 from "@antv/g2";
import { AreaSelectForm, ImgViewer, popoverTable, rules } from "../../common";
import { Shengpi2, Tables } from "./Common";
import filter from "../../common/filter";
import time from "../../common/filter";
import log from "eslint-plugin-react/lib/util/log";

let detailCustId;

class MainM extends React.Component {
    formRef = React.createRef();
    TablesRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadingList: false,
            companyInfo: {},
            legalCloudOperHis: [],
            visible: false,
            clueFollowRecord: [],
            trailCompanyVO: {},
        };
    }

    componentDidMount() {
        detailCustId = this.props.match.params.id;
        this.getData();
    }

    getData = async () => {
        try {
            this.setState({
                loading: true,
            });
            let res = await Axios(
                "get",
                "/brokeruser/clue/legalcloud/" + detailCustId,
                {}
            );
            if (res.code === "200") {
                this.setState({
                    companyInfo: res.data.companyInfo || {},
                    legalCloudOperHis: res.data.legalCloudOperHis || [],
                    trailCompanyVO: res.data.trailCompanyVO || {},
                    clueFollowRecord: res.data.clueFollowRecord || []
                });
                this.TablesRef.current.getOperhisList()

            } else {
                message.error(res.message);
            }
        } catch (err) {
            // message.error(err);
            this.setState({ loading: false });
        }
    };

    render() {
        const cStyle = {
            padding: "12px 24px 12px 24px",
            marginBottom: "15px",
            borderRadius: "4px",
            background: "#fff",
            fontSize: "14px",
        };
        const tStyle = {
            color: "#222",
            lineHeight: "30px",
            fontWeight: "650",
            fontSize: "16px",
            margin: "0px -24px 15px -24px",
            borderBottom: "1px solid #efe5e5",
            padding: "0 24px 8px 24px",
        };
        const dStyle = {
            color: "#222",
            lineHeight: "30px",
            margin: "10px 0px 0px 0px",
        };
        const { companyInfo, trailCompanyVO, clueFollowRecord } = this.state;
        const formItemLayout = {
            labelCol: { span: 5 },
            wrapperCol: { span: 17 },
        };
        return (
            <div className="pageContainer">
                <Row className="headNav">
                    <Col span={24}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>
                                <Link to="/ThreadManage">
                                    线索客户管理
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>线索客户详情</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={24} className="title">
                        <span className="name">线索客户详情</span>
                        <Shengpi2
                            refreshTable={this.getData}
                            index={'4'}
                            detailCustId={detailCustId}
                            brokerUserId={companyInfo.brokerUserId}
                        ><Button type="primary">
                                分配业务员
                            </Button>
                        </Shengpi2>
                    </Col>
                </Row>
                <div className="scrollContainer">
                    <div style={cStyle}>
                        <div style={tStyle}>企业基本信息</div>
                        <Row style={dStyle}>
                            <Col span={8}>
                                企业名称：{companyInfo.companyName || "暂无"}
                            </Col>
                            <Col span={8}>
                                联系人：{companyInfo.linkman || "暂无"}
                            </Col>
                            <Col span={8}>
                                联系电话：{companyInfo.mobileNo || "暂无"}
                            </Col>
                        </Row>
                        <Row style={dStyle}>
                            <Col span={8}>
                                所属行业：{companyInfo.firstCategory || "暂无"}{" "}
                                {companyInfo.secondCategory || ""}{" "}
                                {companyInfo.thirdCategory || ""}
                            </Col>
                            <Col span={8}>
                                企业经营地：{companyInfo.province || "暂无"}{" "}
                                {companyInfo.city || ""}
                            </Col>
                            <Col span={8}>
                                业务员：{companyInfo.brokerUserName || "暂无"}
                            </Col>
                        </Row>
                    </div>
                    <div style={cStyle}>
                        <div style={tStyle}>操作日志</div>
                        <Table
                            dataSource={this.state.legalCloudOperHis}
                            columns={[
                                {
                                    title: "操作类型",
                                    dataIndex: "operAction",
                                },
                                {
                                    title: "操作人",
                                    dataIndex: "operName",
                                },
                                {
                                    title: "操作时间",
                                    dataIndex: "operDate",
                                    render: (t, r) =>
                                        filter.date(t) +
                                        " " +
                                        filter.time(r.operTime),
                                },
                                {
                                    title: "内容描述",
                                    dataIndex: "memo",
                                },
                            ]}
                            size="middle"
                            pagination={
                                this.state.legalCloudOperHis.length > 10
                                    ? {
                                        size: "large",
                                        showQuickJumper: true,
                                        pageSize: 10,
                                    }
                                    : false
                            }
                            bordered={true}
                        />
                    </div>
                    <div style={cStyle}>
                        <div style={tStyle}>跟进记录 <Button
                            type="primary"
                            style={{ float: "right" }}
                            onClick={() => this.setState({ visible: true })}
                        >
                            跟进
                        </Button></div>
                        {console.log(clueFollowRecord)}
                        {clueFollowRecord.map((record, index) =>
                            <div>
                                <h3>{record.operContent}    </h3>
                                <p style={{ marginTop: "-10px" }}>  {record.operName}&nbsp;&nbsp;&nbsp;&nbsp; {filter.date(record.operDate) + '  ' + filter.time(record.operTime)}</p>

                            </div>
                        )}
                    </div>
                    <div style={cStyle}>
                        <div style={tStyle}>
                            操作记录
                        </div>
                        <Tables code={4} detailCustId={this.props.match.params.id} ref={this.TablesRef} />
                    </div>
                </div>
                <Modal
                    title="跟进记录"
                    open={this.state.visible}
                    onOk={() => {
                        this.formRef.current.
                            validateFields(['operContent'])
                            .then(async (values) => {
                                values.id = detailCustId
                                try {
                                    let res = await Axios('post', '/brokeruser/clue/follow', values)
                                    if (res.code == '200') {
                                        this.getData();
                                        this.formRef.current.resetFields()
                                        message.success(res.message);

                                    } else {
                                        message.error(res.message);
                                    }
                                    this.setState({
                                        visible: false
                                    })

                                } catch (err) {
                                    message.error(err.message);

                                }
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info)
                            })
                    }}
                    onCancel={() => {
                        this.setState({ visible: false })
                    }}
                >
                    <Form layout='horizontal' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} ref={this.formRef}>
                        <Form.Item {...formItemLayout} label="跟进记录："
                            name="operContent"
                            rules={[{
                                required: true,
                                message: '请输入跟进记录'
                            }]}
                        >
                            <Input.TextArea rows={4} maxLength={200} placeholder="请输入跟进记录" style={{ width: "80%" }}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </div >
        );
    }
}

let Main = connect(
    (state) => {
        return state;
    },
    (dispatch) => ({
        actions: bindActionCreators({}, dispatch),
    })
)(MainM);
export default Main;

