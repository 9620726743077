import React, { Component } from "react";
import {
    Row,
    Breadcrumb,
    Button,
    Form,
    message,
    DatePicker,
    Modal,
    Input,
    Select,
    Col,
    Radio,
    Popover,
    Table,
    TreeSelect,
    InputNumber,
    Cascader,
    Alert,
} from "antd";
import { AreaSelect, popoverTable } from "../../common";
import Filter from "../../common/filter";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Axios } from "../../axios";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { industryLevel3 } from "../../common/industryLevel3";
import filter from "../../common/filter";
import { bindActionCreators } from "redux";
import { change_operInfo } from "../../redux/actions/operInfo";

const { Option } = Select;
const RangePicker = DatePicker.RangePicker;
let setPageData = {},
    sendBody = {},
    Timer;
let StatuspageNum = 1;

class EmployCompanyManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tData: [],
            loading: false,
            totalNum: 0,
            currentPage:
                window.routerCache.EmployCompanyManage.currentPage || 1,
        };
        sendBody = window.routerCache.EmployCompanyManage.sendBody || {};
        setPageData = {};
    }

    // 获取表格数据
    refreshTableByData = (data, totalNum, currentPage) => {
        this.setState(
            { tData: data, totalNum: totalNum, currentPage: currentPage },
            this.polling
        );
    };
    // 获取表格数据
    refreshTable = async () => {
        try {
            this.setState({
                loading: true,
            });
            let send = sendBody;
            send.workType =
                window.routerCache.EmployCompanyManage.workTypeStr || "";
            send.pageNum = this.state.currentPage + "";
            send.pageSize = "10";
            let res = await Axios("get", "/brokeruser/trial/legalcloud", send);
            this.setState(
                {
                    loading: false,
                    tData: res.data.dataList,
                    totalNum: res.data.totalNum,
                },
                () => {
                    this.setState({
                        tData: res.data.dataList,
                    });
                }
            );
            setPageData[this.state.currentPage + ""] = res.data.dataList;
        } catch (err) {
            // message.error(err);
            this.setState({ loading: false });
        }
    };

    handleTableChange = async (pagination) => {
        this.setState({ loading: true });
        if (Object.keys(setPageData).includes(pagination.current + "")) {
            this.setState({
                loading: false,
                tData: setPageData[pagination.current + ""],
                currentPage: parseInt(pagination.current),
            });
        } else {
            try {
                let send = sendBody;
                send.pageNum = pagination.current + "";
                send.pageSize = "10";
                let res = await Axios(
                    "get",
                    "/brokeruser/trial/legalcloud",
                    send
                );
                this.setState(
                    {
                        loading: false,
                        tData: res.data.dataList,
                        totalNum: res.data.totalNum,
                        currentPage: pagination.current,
                    },
                    this.polling
                );
                setPageData[pagination.current + ""] = res.data.dataList;
            } catch (err) {
                // message.error(err.message);
                this.setState({ loading: false });
            }
        }
    };

    // 组件渲染后获取外界数据(GET)
    componentDidMount() {
        this.refreshTable();
        window.winhcFn.changeDept = () => {
            this.setState(
                {
                    currentPage: 1,
                },
                () => {
                    this.refreshTable();
                }
            );
        };
    }

    componentWillUnmount() {
        window.routerCache.EmployCompanyManage.currentPage =
            this.state.currentPage;
        window.routerCache.EmployCompanyManage.sendBody = sendBody;
        if (Timer) {
            clearTimeout(Timer);
        }
    }

    polling = () => {
        let gonext = false;
        this.state.tData.forEach((item) => {
            if (item.status == "0") {
                gonext = true;
                return;
            }
        });
        if (!gonext) {
            return;
        }
        if (Timer) {
            clearTimeout(Timer);
        }
        // setTimeout(async () => {
        //     try {
        //         let send = sendBody;
        //         send.pageNum = this.state.currentPage + "";
        //         send.pageSize = '10';
        //         let res = await Axios('get', '/BrokerInfos/trial/legalcloud', send)
        //         if (this.state.currentPage == sendBody.pageNum) {
        //             this.setState({
        //                 tData: res.data.dataList,
        //                 totalNum: res.data.totalNum,
        //             }, this.polling);
        //             setPageData[this.state.currentPage + ''] = res.data.dataList;
        //         }
        //     } catch (err) {
        //         // message.error(err.message);
        //         console.log(err)
        //     }
        // }, 1000)
    };

    render() {
        let styleS = {
            display: "flex",
            background: "red",
            color: "white",
            borderRadius: "50%",
            width: "18px",
            height: "18px",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "12px",
            marginTop: "-5px",
        };
        const { refreshTable } = this;
        let columns = [
            {
                title: "企业ID",
                dataIndex: "id",
                fixed: "left",
                // width: 70,
            },
            {
                title: "企业名称",
                dataIndex: "companyName",
                // width: 210,
                fixed: "left",
                render: (text, r) => (
                    <div style={{ display: "flex", cursor: "pointer" }}>
                        {text && text.length > 11 ? (
                            <Popover
                                content={
                                    <div
                                        style={{
                                            padding: "10px",
                                            maxWidth: "500px",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {text}
                                    </div>
                                }
                            >
                                <span>{Filter.strLength(text, 11)}</span>
                            </Popover>
                        ) : (
                            text
                        )}{" "}
                        {r.transSign == "1" ? (
                            <span style={styleS}>转</span>
                        ) : (
                            ""
                        )}
                    </div>
                ),
            },
            {
                title: "联系人",
                dataIndex: "linkman",
                // width: 110,
                render: (text, r) => text || "-",
            },
            {
                title: "联系电话",
                dataIndex: "mobileNo",
                // width: 120,
            },
            {
                title: "企业来源",
                dataIndex: "sourceTypes",
                // width: 120,
                render: (t, r) => Filter.PayCompanyManageSourceType(t),
            },
            {
                title: "行业",
                dataIndex: "firstCategory",
                // width: 110,
                render: (text, r) => (
                    <div style={{ cursor: "pointer" }}>
                        {text && text.length > 5 ? (
                            <Popover
                                content={
                                    <div
                                        style={{
                                            padding: "10px",
                                            maxWidth: "500px",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {text}
                                        {r.secondCategory}
                                        {r.thirdCategory}
                                    </div>
                                }
                            >
                                <span>
                                    {Filter.strLength(
                                        text +
                                        r.secondCategory +
                                        r.thirdCategory,
                                        5
                                    )}
                                </span>
                            </Popover>
                        ) : (
                            text || "-"
                        )}
                    </div>
                ),
            },
            {
                title: "企业经营地",
                dataIndex: "province",
                // width: 150,
                // render: (t, r) =>
                // (t || "") + " " + (r.city || "") + " " + (r.county || ""),
                render: (text, r) => (
                    <div style={{ cursor: "pointer" }}>
                        {text &&
                            ((text || "") + (r.city || "") + (r.county || ""))
                                .length > 6 ? (
                            <Popover
                                content={
                                    <div
                                        style={{
                                            padding: "10px",
                                            maxWidth: "500px",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {text + " "}
                                        {r.city + " "}
                                        {r.county}
                                    </div>
                                }
                            >
                                <span>
                                    {Filter.strLength(
                                        text + " " + r.city + " " + r.county,
                                        6
                                    )}
                                </span>
                            </Popover>
                        ) : (
                            (text || "") +
                            " " +
                            (r.city || "") +
                            " " +
                            (r.county || "")
                        )}
                    </div>
                ),
            },
            {
                title: "客户等级",
                dataIndex: "customerLevel",
                // width: 140,
                render: (t) => Filter.EmployCustomerLevel(t),
            },
            {
                title: "业务员",
                dataIndex: "brokerUserName",
                // width: 120,
            },
            {
                title: "申请试用时间",
                dataIndex: "applyTime",
                // width: 160,
                render: (t) =>
                    t
                        ? new Date(t).toLocaleString("chinese", {
                            hour12: false,
                        })
                        : "-",
            },
            {
                title: "试用到期时间",
                dataIndex: "expireTime",
                // width: 160,
                render: (t) =>
                    t
                        ? new Date(t).toLocaleString("chinese", {
                            hour12: false,
                        })
                        : "-",
            },
            {
                title: "开通时间",
                dataIndex: "approveTime",
                // width: 160,
                render: (t) =>
                    t
                        ? new Date(t).toLocaleString("chinese", {
                            hour12: false,
                        })
                        : "-",
            },
            {
                title: "试用状态",
                dataIndex: "status",
                // width: 90,
                fixed: "right",
                render: (t, r) => Filter.PayCompanyManageEmploystatus(t),
            },
            {
                title: "操作",
                dataIndex: "operate",
                // width: 180,
                fixed: "right",
                render: (text, record) => (
                    <>
                        {record.status == "1" ? (
                            <span>&nbsp;&nbsp;已开通&nbsp;</span>
                        ) : record.isCanOpen === "Y" ? (
                            <a
                                onClick={() =>
                                    Modal.confirm({
                                        title: "你确认要开通吗?",
                                        onOk() {
                                            Axios(
                                                "post",
                                                "/brokeruser/backend/approve/" +
                                                record.id,
                                                { id: record.id }
                                            )
                                                .then((res) => {
                                                    console.log(res);
                                                    if (res.code === "200") {
                                                        message.success(
                                                            res.message
                                                        );
                                                        refreshTable();
                                                    } else {
                                                        message.error(
                                                            res.message
                                                        );
                                                    }
                                                })
                                                .catch((res) => {
                                                    message.error(res.message);
                                                });
                                        },
                                        onCancel() {
                                            console.log("Cancel");
                                        },
                                    })
                                }
                            >
                                开通试用
                            </a>
                        ) : (
                            <span>开通试用</span>
                        )}
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        <AddBroker
                            refreshTable={this.refreshTable}
                            record={record}
                        >
                            <a>修改</a>
                        </AddBroker>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        <Link to={"/EmployCompanyManageDetail/" + record.id}>
                            查看
                        </Link>
                    </>
                ),
            },
        ];
        return (
            <div className="pageContainer">
                <Row className="headNav">
                    <Col span={24}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>客户管理</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col className="title" span={24}>
                        <span className="name">意向客户管理</span>
                        {/* <span className='desc'>查询企业对象为公开案件信息的企业</span> */}
                        <AddBroker refreshTable={this.refreshTable} record={{}}>
                            <Button
                                type="primary"
                                style={{ float: "right" }}
                                icon={<PlusOutlined />}
                            >
                                新增
                            </Button>
                        </AddBroker>
                    </Col>
                </Row>
                <div className="scrollContainer">
                    <div className="scrollMain">
                        <Search refreshTableByData={this.refreshTableByData} />

                        <div style={{ backgroundColor: "#fff" }}>
                            <Row
                                type="flex"
                                justify="space-between"
                                align="middle"
                            ></Row>

                            <Table
                                scroll={{ x: 'max-content' }}
                                dataSource={this.state.tData}
                                columns={columns}
                                size="middle"
                                pagination={{
                                    size: "large",
                                    showQuickJumper: true,
                                    pageSize: 10,
                                    total: parseInt(this.state.totalNum),
                                    current: Number(this.state.currentPage),
                                }}
                                loading={this.state.loading}
                                onChange={this.handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return state;
    },
    (dispatch) => ({})
)(EmployCompanyManage);

class SearchM extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            province: sendBody.province || "",
            city: sendBody.city || "",
            qingkong: false,
            workTypeStr:
                window.routerCache.EmployCompanyManage.workTypeStr || "",
        };
    }

    // 查询
    query = async () => {
        setPageData = {};
        let _this = this;
        sendBody = this.formRef.current.getFieldsValue();
        _this.props.refreshTableByData([], 1, 1);
        delete sendBody.date;
        sendBody.pageNum = "1";
        sendBody.pageSize = "10";
        sendBody.workType =
            window.routerCache.EmployCompanyManage.workTypeStr || "";

        if (this.state.province) {
            sendBody.province = this.state.province;
        }
        if (this.state.city) {
            sendBody.city = this.state.city;
        }
        if (sendBody.sourceTypes == "全部") {
            delete sendBody.sourceTypes;
        }
        try {
            let res = await Axios(
                "get",
                "/brokeruser/trial/legalcloud",
                sendBody
            );

            _this.props.refreshTableByData(
                res.data.dataList,
                res.data.totalNum,
                1
            );
            setPageData["1"] = res.data.dataList;
        } catch (err) {
            // message.error(err.message);
        }
    };
    clearOptions = async () => {
        // 清空的时候清空地区
        this.setState(
            {
                province: "",
                city: "",
                qingkong: true,
            },
            () => {
                this.setState({
                    qingkong: false,
                });
                this.formRef.current.resetFields();
            }
        );
        sendBody = {};
        setPageData = {};
        sendBody.pageNum = "1";
        sendBody.pageSize = "10";
        window.routerCache.EmployCompanyManage.workTypeStr = "";
        this.props.refreshTableByData([], 1, 1);

        try {
            let res = await Axios(
                "get",
                "/brokeruser/trial/legalcloud",
                sendBody
            );

            this.props.refreshTableByData(
                res.data.dataList,
                res.data.totalNum,
                1
            );
            setPageData["1"] = res.data.dataList;
        } catch (err) {
            // message.error(err.message);
        }
    };
    // 选择日期范围
    dateChange = (value, b) => {
        console.log(value, b);
        if (value.length !== 0) {
            // this.setState({sDate: b[0].replace(/-/g, '')});
            // this.setState({eDate: b[1].replace(/-/g, '')});
            this.setState({ sDate: b[0] });
            this.setState({ eDate: b[1] });
        } else {
            this.setState({ sDate: "" });
            this.setState({ eDate: "" });
        }
    };

    //清楚工作台数据
    alertHandle = () => {
        window.routerCache.EmployCompanyManage.workTypeStr = "";
        this.setState({
            workTypeStr: "",
        });
        this.query();
    };

    // 组件渲染后获取外界数据(GET)
    componentDidMount() { }

    render() {
        /*控制查询按钮状态*/
        return (
            <div className="search_like_antD" style={{ marginTop: "-8px" }}>
                {this.state.workTypeStr && (
                    <Alert
                        message={this.state.workTypeStr}
                        type="info"
                        showIcon
                        closeText="清空"
                        onClose={this.alertHandle}
                        style={{ marginBottom: 5 }}
                    />
                )}

                <Form
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    ref={this.formRef}
                >
                    <Row type="flex" align="middle">
                        <Col span="8">
                            <Form.Item
                                label="企业名称"
                                style={{ width: "100%" }}
                                name="companyName"
                                initialValue={sendBody.companyName}
                            >
                                <Input
                                    placeholder="请输入"
                                    type="text"
                                    style={{ width: "80%" }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span="8">
                            <Form.Item
                                label="联系人"
                                style={{ width: "100%" }}
                                name="linkman"
                                initialValue={sendBody.linkman}
                            >
                                <Input
                                    placeholder="请输入"
                                    type="text"
                                    style={{ width: "80%" }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span="8">
                            <Form.Item
                                label="联系电话"
                                style={{ width: "100%" }}
                                name="mobileNo"
                                initialValue={sendBody.mobileNo}
                            >
                                <Input
                                    placeholder="请输入"
                                    type="text"
                                    style={{ width: "80%" }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row type="flex" align="middle">
                        <Col span={8}>
                            <Form.Item
                                label="试用状态"
                                style={{ width: "100%" }}
                                name="status"
                                initialValue={sendBody.status}
                            >
                                <Select
                                    placeholder="请选择"
                                    allowClear
                                    style={{ width: "80%" }}
                                >
                                    <Option key="0">待处理</Option>
                                    <Option key="1">申请通过</Option>
                                    <Option key="2">拒绝申请</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="企业经营地："
                                style={{ width: "100%" }}
                                name="area"
                                initialValue={sendBody.area}
                            >
                                <AreaSelect
                                    bodyStyle={{ width: "80%" }}
                                    province={this.state.province}
                                    city={this.state.city}
                                    hiddenCountry
                                    Hadoop={false}
                                    allowClear
                                    qingkong={this.state.qingkong}
                                    select={(province, city, county) => {
                                        this.setState({
                                            province: province || "",
                                            city: city || "",
                                        });
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="企业来源"
                                style={{ width: "100%" }}
                                name="sourceTypes"
                                initialValue={sendBody.sourceTypes}
                            >
                                <Select
                                    placeholder="请选择"
                                    allowClear
                                    style={{ width: "80%" }}
                                >
                                    <Option key="全部">全部</Option>
                                    <Option key="1">业务员分享链接</Option>
                                    <Option key="2">后台开通</Option>
                                    <Option key="3">APP首页申请</Option>
                                    <Option key="4">扫码申请</Option>
                                    <Option key="5">网页申请</Option>
                                    <Option key="6">云法务SAAS</Option>
                                    <Option key="7">经开区</Option>
                                    <Option key="8">新春大礼包</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row type="flex" align="middle">
                        <Col span={8}>
                            <Form.Item
                                label="客户等级"
                                style={{ width: "100%" }}
                                name="customerLevel"
                                initialValue={sendBody.customerLevel}
                            >
                                <Select
                                    placeholder="请选择"
                                    allowClear
                                    style={{ width: "80%" }}
                                >
                                    <Option key="A">A（关键客户）</Option>
                                    <Option key="B">B（重点客户）</Option>
                                    <Option key="C">C（一般客户）</Option>
                                    <Option key="D">D（低价值客户）</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}></Col>

                        <Col span={8}>
                            <Row
                                type="flex"
                                justify="end"
                                align="middle"
                                style={{ width: "85%", height: "30px" }}
                            >
                                <Form.Item style={{ marginRight: "16px" }}>
                                    <Button type="primary" onClick={this.query}>
                                        查询
                                    </Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button onClick={this.clearOptions}>
                                        重置
                                    </Button>
                                </Form.Item>
                            </Row>
                        </Col>
                    </Row>
                    <div>
                        <Row
                            type="flex"
                            justify="space-between"
                            align="middle"
                            style={
                                window.routerCache.EmployCompanyManage
                                    .workTypeStr
                                    ? {
                                        paddingBottom: "10px",
                                    }
                                    : { paddingBottom: "40px" }
                            }
                        ></Row>
                    </div>
                </Form>
                <style>{`
               .search_like_antD .ant-form ant-form-inline .ant-row-flex .ant-row-flex-space-between .ant-row-flex-middle{
                    padding: 0px !important;
                }
                `}</style>
            </div>
        );
    }
}

let Search = connect(
    (state) => {
        return state;
    },
    (dispatch) => ({})
)(SearchM);

class AddBrokerM extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            selectOption: [],
            productList: [],
            StatusList: [],
            loading: false,
            confirmLoading: false,
            province: "",
            city: "",
            qingkong: false,
        };
    }

    componentWillMount() { }

    // 单击确定按钮提交表单
    handleSubmit = (e) => {
        const { userRole } = this.props.operInfo;

        e.preventDefault();
        let arr = [
            "businessUserId",
            "companyName",
            "linkman",
            "mobileNo",
            "category",
            "customerLevel",
        ];
        this.formRef.current
            .validateFields(arr)
            .then(async (values) => {
                let sendData = values;
                const { record } = this.props;

                if (this.state.province) {
                    sendData.province = this.state.province;
                } else {
                    message.warning("请选择地区");
                    return;
                }

                if (this.state.city) {
                    sendData.city = this.state.city;
                } else {
                    message.warning("请选择城市");
                    return;
                }
                if (values.category) {
                    sendData.firstCategory = values.category[0];
                    sendData.secondCategory = values.category[1];
                    sendData.thirdCategory = values.category[2];
                    delete sendData.category;
                } else {
                    message.warning("请选择行业");
                    return;
                }
                const job = window.globalData.job;

                if (userRole === "1" || job == "senior") {
                    sendData.businessUserId = sendData.businessUserId * 1;
                } else {
                    sendData.businessUserId = this.props.operInfo.userId;
                }
                console.log(sendData);
                let url = "/brokeruser/createLegalCloud";
                if (record.id) {
                    url = "/brokeruser/trial/modify";
                    sendData.id = record.id;
                }
                try {
                    this.setState({ confirmLoading: true });
                    let res = await Axios("post", url, sendData);
                    if (res.errorCode == "200" || res.isSuccess == "T") {
                        this.setState(
                            {
                                province: "",
                                city: "",
                                qingkong: true,
                                confirmLoading: false,
                            },
                            () => {
                                this.setState({
                                    qingkong: false,
                                    visible: false,
                                });
                            }
                        );
                        this.props.refreshTable();
                        this.formRef.current.resetFields();
                        message.success(res.errorMsg);
                    } else {
                        message.error(res.message || res.errorMsg);
                        this.setState({ confirmLoading: false });
                    }
                } catch (err) {
                    message.error(err.message);
                    this.setState({ confirmLoading: false });
                }
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    };
    // 弹出框设置
    showModal = () => {
        this.setState({ visible: true });
    };
    handleCancel = (e) => {
        this.setState({ visible: false });
    };
    handleSelectChange = (value) => {
        console.log("select=" + value);
    };

    componentDidMount() { }

    StatusListFun = (pageNum) => {
        Axios("get", "/brokeruser/page", {
            pageNum: pageNum,
            pageSize: 10,
            status: "0",
        }).then((val) => {
            this.setState({
                StatusList: this.state.StatusList.concat(
                    val.data.dataList || []
                ),
            });
        });
    };

    render() {
        const { userRole } = this.props.operInfo;
        const { productList, StatusList } = this.state;
        const { record } = this.props;
        return (
            <span>
                <span
                    onClick={() => {
                        this.setState({ visible: true }, () => {
                            const { userRole } = this.props.operInfo;
                            const job = window.globalData.job;
                            if (userRole === "1" || job == "senior") {
                                this.StatusListFun(1);
                            }
                            if (record.province) {
                                console.log(record, "recordrecord");
                                this.setState(
                                    {
                                        province: record.province,
                                        city: record.city,
                                    },
                                    () => {
                                        this.formRef.current.setFieldsValue({
                                            companyName: record.companyName,
                                            linkman: record.linkman,
                                            mobileNo: record.mobileNo,
                                            customerLevel: record.customerLevel,
                                            businessUserId:
                                                record.brokerBusinessUserId * 1,
                                        });
                                    }
                                );
                            }
                        });
                    }}
                >
                    {this.props.children}
                </span>
                <Modal
                    title="新增意向客户"
                    open={this.state.visible}
                    onOk={this.handleSubmit}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={() => {
                        this.setState({ visible: false });
                    }}
                >
                    <Form
                        layout="horizontal"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 16 }}
                        ref={this.formRef}
                    >
                        <Form.Item
                            label="企业名称："
                            name="companyName"
                            // initialValue={record.companyName || undefined}
                            rules={[
                                {
                                    required: true,
                                    message: "企业名称不能为空",
                                },
                            ]}
                        >
                            <Input placeholder="请输入企业名称" type="text" />
                        </Form.Item>
                        <Form.Item
                            label="联系人："
                            name="linkman"
                            // initialValue={record.linkman || undefined}
                            rules={[
                                {
                                    required: true,
                                    message: "联系人不能为空",
                                },
                            ]}
                        >
                            <Input placeholder="请输入联系人" type="text" />
                        </Form.Item>
                        <Form.Item
                            label="手机号："
                            name="mobileNo"
                            // initialValue={record.mobileNo || undefined}
                            rules={[
                                {
                                    required: true,
                                    message: "手机号不能为空",
                                },
                                {
                                    message: "手机号不合法",
                                    pattern: /^((1[0-9]{2})+\d{8})$/,
                                },
                            ]}
                        >
                            <InputNumber
                                style={{ width: "100%" }}
                                placeholder="请输入联系人手机号"
                                maxLength={11}
                                disabled={record.mobileNo}
                            />
                        </Form.Item>
                        <Form.Item
                            label="企业经营地："
                            name="area"
                            rules={[
                                {
                                    required: true,
                                    message: "请选择企业经营地",
                                },
                            ]}
                        >
                            <AreaSelect
                                province={
                                    this.state.province || record.province
                                }
                                city={this.state.city || record.city}
                                hiddenCountry
                                Hadoop={false}
                                allowClear
                                qingkong={this.state.qingkong}
                                select={(province, city, county) => {
                                    this.setState({
                                        province: province || "",
                                        city: city || "",
                                    });
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="所属行业："
                            name="category"
                            initialValue={
                                record.firstCategory
                                    ? [
                                        record.firstCategory,
                                        record.secondCategory,
                                        record.thirdCategory,
                                    ]
                                    : null
                            }
                            rules={[
                                {
                                    required: true,
                                    message: "请选择行业",
                                },
                            ]}
                        >
                            <Cascader
                                options={industryLevel3}
                                placeholder="请选择"
                            />
                        </Form.Item>
                        <Form.Item
                            label="客户等级"
                            name="customerLevel"
                            rules={[
                                {
                                    required: true,
                                    message: "请选择客户等级",
                                },
                            ]}
                        // initialValue={record.customerLevel || undefined}
                        >
                            <Select
                                placeholder="请选择"
                                allowClear
                                style={{ width: "100%" }}
                            >
                                <Option value="A" key="A">
                                    A（关键客户）
                                </Option>
                                <Option value="B" key="B">
                                    B（重点客户）
                                </Option>
                                <Option value="C" key="C">
                                    C（一般客户）
                                </Option>
                                <Option value="D" key="D">
                                    D（低价值客户）
                                </Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="业务员"
                            style={{ width: "100%" }}
                            name="businessUserId"
                            initialValue={this.props.operInfo.userId * 1}
                        >
                            <Select
                                style={{ width: "100%" }}
                                placeholder="请选择"
                                allowClear
                                onPopupScroll={(e, value) => {
                                    e.persist();
                                    const { target } = e;
                                    if (
                                        target.scrollTop +
                                        target.offsetHeight >=
                                        target.scrollHeight
                                    ) {
                                        StatuspageNum = StatuspageNum + 1;
                                        this.StatusListFun(StatuspageNum);
                                    }
                                }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (Array.isArray(option.props.children)
                                        ? option.props.children.join("")
                                        : option.props.children
                                    )
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option
                                    key={this.props.userInfo.userId * 1}
                                    value={this.props.userInfo.userId * 1}
                                >
                                    {this.props.userInfo.userName || this.props.userInfo.brokerName || ''}
                                    （当前自己）
                                </Option>

                                {StatusList.map((v) => {
                                    if (this.props.userInfo.userId != v.userId)
                                        return (
                                            <Option
                                                key={v.userId * 1}
                                                value={v.userId * 1}
                                            >
                                                {v.userName}
                                            </Option>
                                        );
                                })}
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>
                <style>{`
                    .ant-input-number-handler-wrap{
                       display: none;   
                    }
                  `}</style>
            </span>
        );
    }
}

let AddBroker = connect(
    (state) => {
        return state;
    },
    (dispatch) => ({
        change_operInfo: bindActionCreators(change_operInfo, dispatch),
    })
)(AddBrokerM);
