import React, { Component } from "react";
import {
    Row,
    Breadcrumb,
    Button,
    Form,
    message,
    DatePicker,
    Modal,
    Input,
    Select,
    Col,
    Radio,
    Popover,
    Table,
    TreeSelect,
    Cascader,
} from "antd";
import { AreaSelect } from "../../common";
import Filter from "../../common/filter";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { PlusOutlined } from '@ant-design/icons';
import { Axios } from "../../axios";
import moment from "moment";
import { specialProvinceList } from "../../common/cityListBigData";
import { industryLevel3 } from "../../common/industryLevel3";
import { bindActionCreators } from "redux";
import { change_operInfo } from "../../redux/actions/operInfo";
import { AreaSelectForm, ImgViewer, popoverTable, rules } from "../../common";

const { Option } = Select;
const RangePicker = DatePicker.RangePicker;
let setPageData = {},
    sendBody = {},
    Timer;

class ThreadManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tData: [],
            loading: false,
            totalNum: 0,
            currentPage: window.routerCache.ThreadManage.currentPage || 1,
        };
        sendBody = window.routerCache.ThreadManage.sendBody || {};
        setPageData = {};
    }

    // 获取表格数据
    refreshTableByData = (data, totalNum, currentPage) => {
        this.setState(
            { tData: data, totalNum: totalNum, currentPage: currentPage },
        );
    };
    // 获取表格数据
    refreshTable = async () => {
        try {
            this.setState({
                loading: true,
            });
            let send = sendBody;
            // send.workType = window.routerCache.ThreadManage.send_workType || '';
            send.pageNum = this.state.currentPage + "";
            send.pageSize = "10";
            let res = await Axios("get", "/brokeruser/clue/legalcloud", send);
            this.setState(
                {
                    loading: false,
                    tData: res.data.dataList,
                    totalNum: res.data.totalNum,
                },
            );
            setPageData[this.state.currentPage + ""] = res.data.dataList;
        } catch (err) {
            // message.error(err);
            this.setState({ loading: false });
        }
    };

    handleTableChange = async (pagination) => {
        this.setState({ loading: true });
        if (Object.keys(setPageData).includes(pagination.current + "")) {
            this.setState({
                loading: false,
                tData: setPageData[pagination.current + ""],
                currentPage: parseInt(pagination.current),
            });
        } else {
            try {
                let send = sendBody;
                send.pageNum = pagination.current + "";
                send.pageSize = "10";
                let res = await Axios("get", "/brokeruser/clue/legalcloud", send);
                this.setState(
                    {
                        loading: false,
                        tData: res.data.dataList,
                        totalNum: res.data.totalNum,
                        currentPage: pagination.current,
                    },
                );
                setPageData[pagination.current + ""] = res.data.dataList;
            } catch (err) {
                // message.error(err.message);
                this.setState({ loading: false });
            }
        }
    };

    // 组件渲染后获取外界数据(GET)
    componentDidMount() {
        this.refreshTable();
        window.winhcFn.changeDept = () => {
            this.setState(
                {
                    currentPage: 1,
                },
                () => {
                    this.refreshTable();
                }
            );
        };
    }

    componentWillUnmount() {
        window.routerCache.ThreadManage.currentPage = this.state.currentPage;
        window.routerCache.ThreadManage.sendBody = sendBody;
        if (Timer) {
            clearTimeout(Timer);
        }
    }


    render() {
        const { refreshTable } = this;
        let styleS = {
            display: "flex",
            background: "red",
            color: "white",
            borderRadius: "50%",
            width: "18px",
            height: "18px",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "12px",
            marginTop: "-5px",
        };
        let columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                fixed: 'left',
                // width: 50,
            },
            {
                title: "企业名称",
                dataIndex: "companyName",
                // width: 210,
                fixed: "left",
                render: (text, r) => (
                    <div style={{ display: "flex", cursor: "pointer" }}>
                        {text && text.length > 11 ? (
                            <Popover
                                content={
                                    <div
                                        style={{
                                            padding: "10px",
                                            maxWidth: "500px",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {text}
                                    </div>
                                }
                            >
                                <span>{Filter.strLength(text, 11)}</span>
                            </Popover>
                        ) : (
                            text
                        )}{" "}
                        {r.transSign == "1" ? (
                            <span style={styleS}>转</span>
                        ) : (
                            ""
                        )}
                    </div>
                ),
            },
            {
                title: "联系人",
                dataIndex: "linkman",
                // width: 120,
            },
            {
                title: "手机号",
                dataIndex: "mobileNo",
                // width: 120,
            },
            {
                title: "行业",
                dataIndex: "firstCategory",
                // width: 110,
                render: (text, r) => (
                    <div style={{ cursor: "pointer" }}>
                        {text && text.length > 5 ? (
                            <Popover
                                content={
                                    <div
                                        style={{
                                            padding: "10px",
                                            maxWidth: "500px",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {text}
                                        {r.secondCategory}
                                        {r.thirdCategory}
                                    </div>
                                }
                            >
                                <span>
                                    {Filter.strLength(
                                        text +
                                        r.secondCategory +
                                        r.thirdCategory,
                                        5
                                    )}
                                </span>
                            </Popover>
                        ) : (
                            text
                        )}
                    </div>
                ),
            },
            {
                title: "企业经营地",
                dataIndex: "province",
                // width: 110,
                render: (t, r) =>
                    (t || "") + " " + (r.city || "") + " " + (r.county || ""),
            }, {
                title: "业务员",
                dataIndex: "brokerUserName",
                // width: 120,
            }, {
                title: "添加时间",
                dataIndex: "timeCreated",
                // width: 120,
            },
            {
                title: "操作",
                dataIndex: "operate",
                // width: 200,
                fixed: "right",
                render: (text, record) => (
                    <>
                        <AddBroker refreshTable={this.refreshTable} record={record} type={"edit"} />

                        &nbsp;&nbsp;|&nbsp;&nbsp;<a
                            onClick={() =>
                                Modal.confirm({
                                    title: "确定要把该线索置为意向客户吗?",
                                    onOk() {
                                        Axios(
                                            "PUT",
                                            "/brokeruser/toIntention/" +
                                            record.id,
                                        )
                                            .then((res) => {
                                                console.log(res);
                                                if (res.errorCode == "200" || res.isSuccess == 'T') {
                                                    message.success(
                                                        res.errorMsg
                                                    );
                                                    refreshTable();
                                                } else {
                                                    message.error(
                                                        res.errorMsg
                                                    );
                                                }
                                            })
                                            .catch((res) => {
                                                message.error(res.errorMsg);
                                            });
                                    },
                                    onCancel() {
                                        console.log("Cancel");
                                    },
                                })
                            }
                        >
                            置为意向客户
                        </a>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        <Link to={"/ThreadManageDetail/" + record.id}>
                            查看
                        </Link>
                    </>
                ),
            },
        ];
        return (
            <div className="pageContainer">
                <Row className="headNav">
                    <Col span={24}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>客户管理</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={24} className="title">
                        <span className="name">线索客户管理</span>
                        <AddBroker record={{}} refreshTable={this.refreshTable} />
                    </Col>
                </Row>
                <div className="scrollContainer">
                    <div className="scrollMain">
                        <Search refreshTableByData={this.refreshTableByData} />
                        <div style={{ backgroundColor: "#fff" }}>
                            <Row
                                type="flex"
                                justify="space-between"
                                align="middle"
                            ></Row>

                            <Table
                                scroll={{ x: 'max-content' }}
                                dataSource={this.state.tData}
                                columns={columns}
                                size="middle"
                                pagination={{
                                    size: "large",
                                    showQuickJumper: true,
                                    pageSize: 10,
                                    total: parseInt(this.state.totalNum),
                                    current: Number(this.state.currentPage),
                                }}
                                loading={this.state.loading}
                                onChange={this.handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default connect(
    (state) => {
        return state;
    },
    (dispatch) => ({})
)(ThreadManage);

class SearchM extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            sDate: "",
            eDate: "",
            StatusList: [],
            province: "",
            city: "",
            qingkong: false,
        };
    }

    // 查询
    query = async () => {
        setPageData = {};
        let _this = this;
        sendBody = this.formRef.current.getFieldsValue();

        _this.props.refreshTableByData([], 1, 1);
        if (this.state.province) {
            sendBody.province = this.state.province
        }
        if (this.city) {
            sendBody.city = this.state.city
        }


        sendBody.pageNum = "1";
        sendBody.pageSize = "10";


        try {
            let res = await Axios("get", "/brokeruser/clue/legalcloud", sendBody);

            _this.props.refreshTableByData(
                res.data.dataList,
                res.data.totalNum,
                1
            );
            setPageData["1"] = res.data.dataList;
        } catch (err) {
            // message.error(err.message);
        }
    };
    clearOptions = async () => {
        sendBody = {};
        setPageData = {};
        sendBody.pageNum = "1";
        sendBody.pageSize = "10";
        // 清空的时候清空地区
        this.setState(
            {
                province: "",
                city: "",
                qingkong: true,
            },
            () => {
                this.setState({
                    qingkong: false,
                });
                this.formRef.current.resetFields();

            }
        );
        // sendBody.workType = window.routerCache.ThreadManage.send_workType || '';
        this.props.refreshTableByData([], 1, 1);

        try {
            let res = await Axios("get", "/brokeruser/clue/legalcloud", sendBody);

            this.props.refreshTableByData(
                res.data.dataList,
                res.data.totalNum,
                1
            );
            setPageData["1"] = res.data.dataList;
        } catch (err) {
            // message.error(err.message);
        }
    };

    // 组件渲染后获取外界数据(GET)
    componentDidMount() {
        const job = window.globalData.job
        if (this.props.operInfo.userRole === "1" || job == 'senior') {
            Axios("get", "/brokeruser/page", {
                // status: "0",
            }).then((val) => {
                this.setState({
                    StatusList: (val.data || {}).dataList || [],
                });
            });
        }
    }

    render() {
        /*控制查询按钮状态*/
        const { StatusList } = this.state
        const { userRole } = this.props.operInfo;
        const job = window.globalData.job

        return (
            <div className="search_like_antD" style={{ marginTop: "-8px" }}>
                <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} ref={this.formRef}>
                    <Row
                        type="flex"
                        align="middle"
                    >
                        <Col span="8">
                            <Form.Item
                                label="企业名称"
                                style={{ width: "100%" }}
                                name="companyName"
                                initialValue={sendBody.companyName}>
                                <Input
                                    placeholder="请输入企业名称"
                                    type="text"
                                    style={{ width: "80%" }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span="8">
                            <Form.Item
                                label="联系人"
                                style={{ width: "100%" }}
                                name="linkman"
                                initialValue={sendBody.linkman}>
                                <Input
                                    placeholder="请输入联系人"
                                    type="text"
                                    style={{ width: "80%" }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span="8">
                            <Form.Item
                                label="手机号"
                                style={{ width: "100%" }}
                                name="mobileNo"
                                initialValue={sendBody.mobileNo}>
                                <Input
                                    placeholder="请输入手机号"

                                    type="text"
                                    style={{ width: "80%" }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row
                        type="flex"
                        align="middle"
                    >
                        <Col span="8">
                            <Form.Item
                                label="企业经营地："
                                style={{ width: "100%" }}
                                name="province"
                                initialValue={sendBody.province}>
                                <AreaSelect
                                    bodyStyle={{ width: "80%" }}
                                    province={this.state.province}
                                    city={this.state.city}
                                    hiddenCountry
                                    Hadoop={{}}
                                    allowClear
                                    qingkong={this.state.qingkong}
                                    select={(province, city, county) => {
                                        console.log(province, city, county);
                                        sendBody.province = province;
                                        sendBody.city = city;
                                        this.setState({
                                            province: province || "",
                                            city: city || "",
                                        });
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span="8">
                            {userRole === "1" || job == 'senior' ? (
                                <Form.Item
                                    label="业务员"
                                    style={{ width: "100%" }}
                                    name="brokerUserId"
                                    initialValue={sendBody.brokerUserId}>
                                    <Select
                                        style={{ width: "80%" }}
                                        placeholder="请选择"
                                        allowClear
                                    >
                                        <Option
                                            key={this.props.userInfo.id * 1}
                                            value={this.props.userInfo.id * 1}
                                        >
                                            {this.props.userInfo.userName || this.props.userInfo.brokerName || ''}
                                            （当前自己）
                                        </Option>
                                        {StatusList.map((v) => {
                                            if (this.props.userInfo.userId != v.userId) return (
                                                <Option
                                                    key={v.id}
                                                    value={v.id}
                                                >
                                                    {v.userName}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            ) : null}
                        </Col>
                        <Col span={8}>
                            <Row
                                type="flex"
                                justify="end"
                                align="middle"
                                style={{ width: "85%", height: '30px' }}
                            >
                                <Form.Item style={{ marginRight: '16px' }}
                                >
                                    <Button
                                        type="primary"
                                        onClick={this.query}
                                    >
                                        查询
                                    </Button>
                                </Form.Item>
                                <Form.Item >
                                    <Button onClick={this.clearOptions}>
                                        重置
                                    </Button>
                                </Form.Item>
                            </Row>
                        </Col>
                    </Row>

                    <div>
                        <Row
                            type="flex"
                            justify="space-between"
                            align="middle"
                            style={
                                window.routerCache.ThreadManage.workTypeStr
                                    ? {
                                        paddingBottom: "10px",
                                    }
                                    : { paddingBottom: "40px" }
                            }
                        ></Row>
                    </div>
                </Form>
                <style>{`
               .search_like_antD .ant-form ant-form-inline .ant-row-flex .ant-row-flex-space-between .ant-row-flex-middle{
                    padding: 0px !important;
                }
                `}</style>
            </div>
        );
    }
}

let Search = connect(
    (state) => {
        return state;
    },
    (dispatch) => ({})
)(SearchM);

class AddBrokerM extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            selectOption: [],
            StatusList: [],
            loading: false,
            confirmLoading: false,
            province: this.props.record.province,
            city: this.props.record.city,
            qingkong: false,
            custList: []
        };
    }

    componentWillMount() {
    }

    // 单击确定按钮提交表单
    handleSubmit = (e) => {
        const { userRole } = this.props.operInfo;
        e.preventDefault();
        let arr = [
            "companyName",
            "linkman",
            "mobileNo",
            "brokerUserId",
            "category",
        ];
        this.formRef.current.
            validateFields(arr)
            .then(async (values) => {
                let sendData = values;
                if (values.saleOperId) {
                    sendData.saleOperId = values.saleOperId;
                    sendData.saleOperName =
                        this.props.baseData.operTree.getOperInfo(
                            values.saleOperId
                        ).realName;
                }
                if (this.state.province) {
                    sendData.province = this.state.province;
                } else {
                    message.warning("请选择地区");
                    return;
                }
                if (this.state.city) {
                    sendData.city = this.state.city;
                } else {
                    message.warning("请选择城市");
                    return;
                }
                if (values.category && values.category[0]) {
                    sendData.firstCategory = values.category[0];
                    sendData.secondCategory = values.category[1];
                    sendData.thirdCategory = values.category[2];
                    delete sendData.category;
                } else {
                    message.warning("请选择行业");
                    return;
                }
                const job = window.globalData.job
                if (userRole === "1" || job == 'senior') {
                    sendData.brokerUserId = sendData.brokerUserId * 1;
                } else {
                    sendData.brokerUserId = this.props.userInfo.id
                }
                if (this.props.type == 'edit') {
                    sendData.id = this.props.record.id
                }
                try {
                    this.setState({ confirmLoading: true });
                    let res = await Axios("post", "/brokeruser/upload/clue", sendData);
                    if (res.code == "200" || res.code == "00") {
                        this.props.refreshTable();
                        this.formRef.current.resetFields();
                        message.success(res.message);
                        if (this.props.type == 'edit') {
                            this.setState(
                                {
                                    visible: false,
                                    qingkong: true,
                                    confirmLoading: false,
                                },
                                () => {
                                    this.setState({
                                        qingkong: false,
                                    });
                                }
                            );
                        } else {
                            this.setState(
                                {
                                    visible: false,
                                    qingkong: true,
                                    confirmLoading: false,
                                    province: '',
                                    city: '',
                                },
                                () => {
                                    this.setState({
                                        qingkong: false,
                                    });
                                }
                            );
                        }

                    } else {
                        message.error(res.data);
                        this.setState({ confirmLoading: false });
                    }
                } catch (err) {
                    message.error(err.msg);
                    this.setState({ confirmLoading: false });
                }
            })
            .catch((info) => {
                console.log('Validate Failed:', info)
            })

    };
    // 弹出框设置
    showModal = () => {
        this.setState({ visible: true });
    };
    handleCancel = (e) => {
        this.setState({ visible: false });
    };
    handleSelectChange = (value) => {
        console.log("select=" + value);
    };
    checkData = () => {
        const job = window.globalData.job
        this.setState({ visible: true });
        if (this.props.operInfo.userRole === "1" || job == 'senior') {
            Axios("get", "/brokeruser/page", {
                status: "0",
            }).then((val) => {
                this.setState({
                    StatusList: (val.data || {}).dataList || [],
                });
            });
        }
    }


    getCustList = async (v) => {
        if (Timer) {
            clearTimeout(Timer)
        }
        Timer = setTimeout(async () => {
            try {
                let res = await Axios('get', '/custInfos/open/Eci/simple/v2', { companyName: v }, 'fireflyErp')
                console.log("企业列表=", res)
                if (res.isSuccess == 'T' && res.body) {
                    let custList = [];
                    JSON.parse(res.body).result.forEach((item) => {
                        if (item.name) {
                            let names = [];
                            for (let i = 0; i < item.name.length; i++) {
                                let yes = false;
                                for (let j = 0; j < v.length; j++) {
                                    if (v[j] == item.name[i]) {
                                        yes = true;
                                        break;
                                    }
                                }
                                if (yes) {
                                    names.push(<span style={{ color: '#FD485E' }}>{item.name[i]}</span>)
                                } else {
                                    names.push(item.name[i])
                                }
                            }
                            custList.push(<Option key={item.keyNo} value={item.name}>{names}</Option>)
                        }
                    })
                    this.setState({
                        custList
                    })
                } else {
                    this.setState({
                        custList: []
                    })
                }
            } catch (err) {
                if (err && err.message) {
                    message.error(err.message)
                }
            }
        }, 700)
    }
    searchCust = async (companyId) => {
        try {
            let res = await Axios('get', `/legalCloud/open/area/${companyId}`, {}, 'cloud-legal')
            if (res.isSuccess == "T" && res.body) {
                if (res.body.province) {
                    res.body.province = specialProvinceList.find(e => e.includes(res.body.province))
                }
                //设置相应数据
                this.setState({
                    province: res.body.province,
                    city: res.body.city
                })
                this.formRef.current.setFieldsValue({
                    category: [res.body.cateFirst, res.body.cateSecond, res.body.cateThird]
                })
            } else {
                this.setState({
                    province: "",
                    city: ""
                })
                this.formRef.current.setFieldsValue({
                    category: undefined
                })
            }
        } catch (err) {

        }
    }

    render() {
        const { userRole } = this.props.operInfo;
        const { StatusList, priceData } = this.state;
        const { type, record } = this.props
        const job = window.globalData.job

        return (
            <span>
                {
                    type == 'edit' ?
                        <a style={{ color: '#1890ff', }} onClick={this.checkData}>修改</a> :
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            style={{ float: "right" }}
                            onClick={this.checkData}
                        >
                            新增
                        </Button>
                }

                <Modal
                    title={type == 'edit' ? '编辑线索' : '新增线索'}
                    open={this.state.visible}
                    onOk={this.handleSubmit}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={() => {
                        this.setState({ visible: false });
                    }}
                >
                    <Form
                        ref={this.formRef}
                        layout="horizontal"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 16 }}
                    >
                        <Form.Item label="企业名称："
                            name="companyName"
                            initialValue={type === "edit" ? record.companyName : undefined}
                            rules={[
                                {
                                    required: true,
                                    message: "企业名称不能为空",
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                optionLabelProp="value"
                                placeholder='请输入企业名称'
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                onSearch={(v) => {
                                    setTimeout(() => {
                                        if (v) {
                                            v = v.replace(/\s/g, '')
                                            this.formRef.current.setFieldsValue({ companyName: v })
                                            this.getCustList(v)
                                        }
                                    })
                                }}
                                notFoundContent={null}
                                onSelect={(v, options) => {
                                    if (v) {
                                        v = v.replace(/\s/g, '')
                                    }
                                    if (v) {
                                        this.searchCust(options.key)
                                    }
                                }}
                            >
                                {this.state.custList}
                            </Select>
                        </Form.Item>
                        <Form.Item label="联系人："
                            name="linkman"
                            initialValue={type === "edit" ? record.linkman : null}
                            rules={[
                                {
                                    required: true,
                                    message: "联系人不能为空",
                                },
                            ]}
                        >
                            <Input placeholder="请输入联系人" type="text" />
                        </Form.Item>
                        <Form.Item label="手机号："
                            name="mobileNo"
                            initialValue={type === "edit" ? record.mobileNo : null}
                            rules={[
                                {
                                    required: true,
                                    message: "手机号不能为空",
                                }, {
                                    pattern: /^(?:(?:\+|00)86)?1[2-9]\d{9}$/,
                                    message: "手机号格式不正确",
                                },
                            ]}
                        >
                            <Input placeholder="请输入手机号" type="text" maxLength={11} />
                        </Form.Item>
                        <Form.Item label="企业经营地："
                            name="edit"
                            rules={[
                                {
                                    required: true,
                                    message: "企业经营地不能为空",
                                },
                            ]}
                        >
                            <AreaSelect
                                province={this.state.province}
                                city={this.state.city}
                                controlled={true}
                                hiddenCountry
                                Hadoop={{}}
                                allowClear
                                qingkong={this.state.qingkong}

                                select={(province, city, county) => {
                                    this.setState({
                                        province: province || "",
                                        city: city || "",
                                    });
                                }}
                            />
                        </Form.Item>


                        <Form.Item label="所属行业："
                            name="category"
                            initialValue={type === "edit" ? (record.firstCategory ? [record.firstCategory, record.secondCategory, record.thirdCategory] : '') : ''}
                            rules={[
                                {
                                    required: true,
                                    message: "请选择行业",
                                },
                            ]}
                        >
                            <Cascader
                                options={industryLevel3}
                                placeholder="请选择"

                            />
                        </Form.Item>
                        {userRole === "1" || job == 'senior' ? (
                            <Form.Item label="业务员" style={{ width: "100%" }}
                                name="brokerUserId"
                                initialValue={type === "edit" ? record.brokerUserId * 1 : this.props.userInfo.id * 1}

                            >
                                <Select
                                    style={{ width: "100%" }}
                                    placeholder="请选择"
                                    allowClear
                                >
                                    <Option
                                        key={this.props.userInfo.id * 1}
                                        value={this.props.userInfo.id * 1}
                                    >
                                        {this.props.userInfo.userName || this.props.userInfo.brokerName || ''}
                                        （当前自己）
                                    </Option>

                                    {StatusList.map((v) => {
                                        if (this.props.userInfo.userId != v.userId) return (
                                            <Option
                                                key={v.id}
                                                value={v.id}
                                            >
                                                {v.userName}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        ) : null}
                    </Form>
                </Modal>
            </span>
        );
    }
}

let AddBroker = connect(
    (state) => {
        return state;
    },
    (dispatch) => ({
        change_operInfo: bindActionCreators(change_operInfo, dispatch),
    })
)(AddBrokerM);
