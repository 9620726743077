export const Industry = [{
    value: 10,
    label: "信息传输、软件和信息技术服务业",
    rank: 1,
    children: [{
        value: 17,
        parentvalue: 10,
        label: "软件和信息技术服务业",
        rank: 2,
        children: [{value: 120, parentvalue: 17, label: "其他信息技术服务业", rank: 3}, {
            value: 146,
            parentvalue: 17,
            label: "软件开发",
            rank: 3
        }, {value: 308, parentvalue: 17, label: "集成电路设计", rank: 3}, {
            value: 343,
            parentvalue: 17,
            label: "数据处理和存储服务",
            rank: 3
        }, {value: 416, parentvalue: 17, label: "信息技术咨询服务", rank: 3}, {
            value: 422,
            parentvalue: 17,
            label: "信息系统集成服务",
            rank: 3
        }]
    }, {
        value: 85,
        parentvalue: 10,
        label: "电信、广播电视和卫星传输服务",
        rank: 2,
        children: [{value: 306, parentvalue: 85, label: "广播电视传输服务", rank: 3}, {
            value: 444,
            parentvalue: 85,
            label: "电信",
            rank: 3
        }, {value: 499, parentvalue: 85, label: "卫星传输服务", rank: 3}]
    }, {
        value: 94,
        parentvalue: 10,
        label: "互联网和相关服务",
        rank: 2,
        children: [{value: 260, parentvalue: 94, label: "互联网接入及相关服务", rank: 3}, {
            value: 461,
            parentvalue: 94,
            label: "互联网信息服务",
            rank: 3
        }, {value: 497, parentvalue: 94, label: "其他互联网服务", rank: 3}]
    }]
}, {
    value: 11,
    label: "制造业",
    rank: 1,
    children: [{
        value: 10,
        parentvalue: 11,
        label: "黑色金属冶炼和压延加工业",
        rank: 2,
        children: [{value: 234, parentvalue: 10, label: "炼铁", rank: 3}, {
            value: 279,
            parentvalue: 10,
            label: "炼钢",
            rank: 3
        }, {value: 319, parentvalue: 10, label: "黑色金属铸造", rank: 3}, {
            value: 470,
            parentvalue: 10,
            label: "铁合金冶炼",
            rank: 3
        }, {value: 529, parentvalue: 10, label: "钢压延加工", rank: 3}]
    }, {
        value: 11,
        parentvalue: 11,
        label: "皮革、毛皮、羽毛及其制品和制鞋业",
        rank: 2,
        children: [{value: 142, parentvalue: 11, label: "皮革鞣制加工", rank: 3}, {
            value: 156,
            parentvalue: 11,
            label: "羽毛(绒)加工及制品制造",
            rank: 3
        }, {value: 355, parentvalue: 11, label: "皮革制品制造", rank: 3}, {
            value: 436,
            parentvalue: 11,
            label: "制鞋业",
            rank: 3
        }, {value: 457, parentvalue: 11, label: "毛皮鞣制及制品加工", rank: 3}]
    }, {
        value: 12,
        parentvalue: 11,
        label: "纺织业",
        rank: 2,
        children: [{value: 110, parentvalue: 12, label: "非家用纺织制成品制造", rank: 3}, {
            value: 179,
            parentvalue: 12,
            label: "针织或钩针编织物及其制品制造",
            rank: 3
        }, {value: 259, parentvalue: 12, label: "家用纺织制成品制造", rank: 3}, {
            value: 309,
            parentvalue: 12,
            label: "化纤织造及印染精加工",
            rank: 3
        }, {value: 329, parentvalue: 12, label: "棉纺织及印染精加工", rank: 3}, {
            value: 431,
            parentvalue: 12,
            label: "麻纺织及染整精加工",
            rank: 3
        }, {value: 439, parentvalue: 12, label: "毛纺织及染整精加工", rank: 3}, {
            value: 512,
            parentvalue: 12,
            label: "丝绢纺织及印染精加工",
            rank: 3
        }]
    }, {
        value: 15,
        parentvalue: 11,
        label: "金属制品、机械和设备修理业",
        rank: 2,
        children: [{value: 141, parentvalue: 15, label: "铁路、船舶、航空航天等运输设备修理", rank: 3}, {
            value: 162,
            parentvalue: 15,
            label: "通用设备修理",
            rank: 3
        }, {value: 210, parentvalue: 15, label: "电气设备修理", rank: 3}, {
            value: 248,
            parentvalue: 15,
            label: "其他机械和设备修理业",
            rank: 3
        }, {value: 251, parentvalue: 15, label: "专用设备修理", rank: 3}, {
            value: 414,
            parentvalue: 15,
            label: "仪器仪表修理",
            rank: 3
        }, {value: 462, parentvalue: 15, label: "金属制品修理", rank: 3}]
    }, {
        value: 22,
        parentvalue: 11,
        label: "化学原料和化学制品制造业",
        rank: 2,
        children: [{value: 235, parentvalue: 22, label: "涂料、油墨、颜料及类似产品制造", rank: 3}, {
            value: 265,
            parentvalue: 22,
            label: "肥料制造",
            rank: 3
        }, {value: 294, parentvalue: 22, label: "专用化学产品制造", rank: 3}, {
            value: 296,
            parentvalue: 22,
            label: "基础化学原料制造",
            rank: 3
        }, {value: 386, parentvalue: 22, label: "合成材料制造", rank: 3}, {
            value: 443,
            parentvalue: 22,
            label: "日用化学产品制造",
            rank: 3
        }, {value: 483, parentvalue: 22, label: "农药制造", rank: 3}, {
            value: 507,
            parentvalue: 22,
            label: "炸药、火工及焰火产品制造",
            rank: 3
        }]
    }, {
        value: 31,
        parentvalue: 11,
        label: "通用设备制造业",
        rank: 2,
        children: [{value: 169, parentvalue: 31, label: "通用零部件制造", rank: 3}, {
            value: 221,
            parentvalue: 31,
            label: "泵、阀门、压缩机及类似机械制造",
            rank: 3
        }, {value: 244, parentvalue: 31, label: "金属加工机械制造", rank: 3}, {
            value: 267,
            parentvalue: 31,
            label: "烘炉、风机、衡器、包装等设备制造",
            rank: 3
        }, {value: 299, parentvalue: 31, label: "物料搬运设备制造", rank: 3}, {
            value: 328,
            parentvalue: 31,
            label: "锅炉及原动设备制造",
            rank: 3
        }, {value: 394, parentvalue: 31, label: "轴承、齿轮和传动部件制造", rank: 3}, {
            value: 459,
            parentvalue: 31,
            label: "文化、办公用机械制造",
            rank: 3
        }, {value: 498, parentvalue: 31, label: "其他通用设备制造业", rank: 3}]
    }, {
        value: 33,
        parentvalue: 11,
        label: "化学纤维制造业",
        rank: 2,
        children: [{value: 132, parentvalue: 33, label: "合成纤维制造", rank: 3}, {
            value: 302,
            parentvalue: 33,
            label: "纤维素纤维原料及纤维制造",
            rank: 3
        }]
    }, {
        value: 35,
        parentvalue: 11,
        label: "木材加工和木、竹、藤、棕、草制品业",
        rank: 2,
        children: [{value: 167, parentvalue: 35, label: "人造板制造", rank: 3}, {
            value: 342,
            parentvalue: 35,
            label: "木材加工",
            rank: 3
        }, {value: 352, parentvalue: 35, label: "竹、藤、棕、草等制品制造", rank: 3}, {
            value: 521,
            parentvalue: 35,
            label: "木制品制造",
            rank: 3
        }]
    }, {
        value: 38,
        parentvalue: 11,
        label: "纺织服装、服饰业",
        rank: 2,
        children: [{value: 186, parentvalue: 38, label: "针织或钩针编织服装制造", rank: 3}, {
            value: 231,
            parentvalue: 38,
            label: "服饰制造",
            rank: 3
        }, {value: 474, parentvalue: 38, label: "机织服装制造", rank: 3}]
    }, {
        value: 39,
        parentvalue: 11,
        label: "造纸和纸制品业",
        rank: 2,
        children: [{value: 236, parentvalue: 39, label: "纸浆制造", rank: 3}, {
            value: 335,
            parentvalue: 39,
            label: "造纸",
            rank: 3
        }, {value: 519, parentvalue: 39, label: "纸制品制造", rank: 3}]
    }, {
        value: 40,
        parentvalue: 11,
        label: "食品制造业",
        rank: 2,
        children: [{value: 166, parentvalue: 40, label: "焙烤食品制造", rank: 3}, {
            value: 216,
            parentvalue: 40,
            label: "其他食品制造",
            rank: 3
        }, {value: 249, parentvalue: 40, label: "罐头食品制造", rank: 3}, {
            value: 252,
            parentvalue: 40,
            label: "乳制品制造",
            rank: 3
        }, {value: 402, parentvalue: 40, label: "方便食品制造", rank: 3}, {
            value: 424,
            parentvalue: 40,
            label: "调味品、发酵制品制造",
            rank: 3
        }, {value: 518, parentvalue: 40, label: "糖果、巧克力及蜜饯制造", rank: 3}]
    }, {
        value: 46,
        parentvalue: 11,
        label: "计算机、通信和其他电子设备制造业",
        rank: 2,
        children: [{value: 180, parentvalue: 46, label: "广播电视设备制造", rank: 3}, {
            value: 203,
            parentvalue: 46,
            label: "电子元件制造",
            rank: 3
        }, {value: 223, parentvalue: 46, label: "计算机制造", rank: 3}, {
            value: 258,
            parentvalue: 46,
            label: "雷达及配套设备制造",
            rank: 3
        }, {value: 304, parentvalue: 46, label: "视听设备制造", rank: 3}, {
            value: 307,
            parentvalue: 46,
            label: "通信设备制造",
            rank: 3
        }, {value: 415, parentvalue: 46, label: "其他电子设备制造", rank: 3}, {
            value: 530,
            parentvalue: 46,
            label: "电子器件制造",
            rank: 3
        }]
    }, {
        value: 47,
        parentvalue: 11,
        label: "酒、饮料和精制茶制造业",
        rank: 2,
        children: [{value: 153, parentvalue: 47, label: "酒的制造", rank: 3}, {
            value: 168,
            parentvalue: 47,
            label: "精制茶加工",
            rank: 3
        }, {value: 490, parentvalue: 47, label: "饮料制造", rank: 3}]
    }, {
        value: 51,
        parentvalue: 11,
        label: "农副食品加工业",
        rank: 2,
        children: [{value: 124, parentvalue: 51, label: "谷物磨制", rank: 3}, {
            value: 126,
            parentvalue: 51,
            label: "蔬菜、水果和坚果加工",
            rank: 3
        }, {value: 161, parentvalue: 51, label: "植物油加工", rank: 3}, {
            value: 182,
            parentvalue: 51,
            label: "制糖业",
            rank: 3
        }, {value: 190, parentvalue: 51, label: "饲料加工", rank: 3}, {
            value: 257,
            parentvalue: 51,
            label: "屠宰及肉类加工",
            rank: 3
        }, {value: 293, parentvalue: 51, label: "其他农副食品加工", rank: 3}, {
            value: 297,
            parentvalue: 51,
            label: "水产品加工",
            rank: 3
        }]
    }, {
        value: 52,
        parentvalue: 11,
        label: "仪器仪表制造业",
        rank: 2,
        children: [{value: 204, parentvalue: 52, label: "通用仪器仪表制造", rank: 3}, {
            value: 214,
            parentvalue: 52,
            label: "光学仪器及眼镜制造",
            rank: 3
        }, {value: 392, parentvalue: 52, label: "钟表与计时仪器制造", rank: 3}, {
            value: 472,
            parentvalue: 52,
            label: "专用仪器仪表制造",
            rank: 3
        }, {value: 473, parentvalue: 52, label: "其他仪器仪表制造业", rank: 3}]
    }, {
        value: 53,
        parentvalue: 11,
        label: "医药制造业",
        rank: 2,
        children: [{value: 320, parentvalue: 53, label: "中药饮片加工", rank: 3}, {
            value: 333,
            parentvalue: 53,
            label: "中成药生产",
            rank: 3
        }, {value: 387, parentvalue: 53, label: "兽用药品制造", rank: 3}, {
            value: 403,
            parentvalue: 53,
            label: "卫生材料及医药用品制造",
            rank: 3
        }, {value: 441, parentvalue: 53, label: "化学药品原料药制造", rank: 3}, {
            value: 447,
            parentvalue: 53,
            label: "化学药品制剂制造",
            rank: 3
        }, {value: 516, parentvalue: 53, label: "生物药品制造", rank: 3}]
    }, {
        value: 55,
        parentvalue: 11,
        label: "专用设备制造业",
        rank: 2,
        children: [{value: 139, parentvalue: 55, label: "纺织、服装和皮革加工专用设备制造", rank: 3}, {
            value: 154,
            parentvalue: 55,
            label: "采矿、冶金、建筑专用设备制造",
            rank: 3
        }, {value: 228, parentvalue: 55, label: "化工、木材、非金属加工专用设备制造", rank: 3}, {
            value: 245,
            parentvalue: 55,
            label: "农、林、牧、渔专用机械制造",
            rank: 3
        }, {value: 246, parentvalue: 55, label: "医疗仪器设备及器械制造", rank: 3}, {
            value: 341,
            parentvalue: 55,
            label: "电子和电工机械专用设备制造",
            rank: 3
        }, {value: 425, parentvalue: 55, label: "环保、社会公共服务及其他专用设备制造", rank: 3}, {
            value: 435,
            parentvalue: 55,
            label: "印刷、制药、日化及日用品生产专用设备制造",
            rank: 3
        }, {value: 453, parentvalue: 55, label: "食品、饮料、烟草及饲料生产专用设备制造　　　", rank: 3}]
    }, {
        value: 58,
        parentvalue: 11,
        label: "铁路、船舶、航空航天和其他运输设备制造业",
        rank: 2,
        children: [{value: 109, parentvalue: 58, label: "城市轨道交通设备制造", rank: 3}, {
            value: 185,
            parentvalue: 58,
            label: "自行车制造",
            rank: 3
        }, {value: 220, parentvalue: 58, label: "船舶及相关装置制造", rank: 3}, {
            value: 334,
            parentvalue: 58,
            label: "潜水救捞及其他未列明运输设备制造",
            rank: 3
        }, {value: 368, parentvalue: 58, label: "航空、航天器及设备制造", rank: 3}, {
            value: 487,
            parentvalue: 58,
            label: "非公路休闲车及零配件制造",
            rank: 3
        }, {value: 494, parentvalue: 58, label: "铁路运输设备制造", rank: 3}, {
            value: 505,
            parentvalue: 58,
            label: "摩托车制造",
            rank: 3
        }]
    }, {
        value: 64,
        parentvalue: 11,
        label: "金属制品业",
        rank: 2,
        children: [{value: 115, parentvalue: 64, label: "结构性金属制品制造", rank: 3}, {
            value: 151,
            parentvalue: 64,
            label: "其他金属制品制造",
            rank: 3
        }, {value: 178, parentvalue: 64, label: "金属制日用品制造", rank: 3}, {
            value: 239,
            parentvalue: 64,
            label: "金属表面处理及热处理加工",
            rank: 3
        }, {value: 241, parentvalue: 64, label: "建筑、安全用金属制品制造", rank: 3}, {
            value: 398,
            parentvalue: 64,
            label: "搪瓷制品制造",
            rank: 3
        }, {value: 419, parentvalue: 64, label: "金属丝绳及其制品制造", rank: 3}, {
            value: 511,
            parentvalue: 64,
            label: "集装箱及金属包装容器制造",
            rank: 3
        }, {value: 515, parentvalue: 64, label: "金属工具制造", rank: 3}]
    }, {
        value: 66,
        parentvalue: 11,
        label: "家具制造业",
        rank: 2,
        children: [{value: 102, parentvalue: 66, label: "其他家具制造", rank: 3}, {
            value: 119,
            parentvalue: 66,
            label: "木质家具制造",
            rank: 3
        }, {value: 173, parentvalue: 66, label: "竹、藤家具制造", rank: 3}, {
            value: 175,
            parentvalue: 66,
            label: "塑料家具制造",
            rank: 3
        }, {value: 463, parentvalue: 66, label: "金属家具制造", rank: 3}]
    }, {
        value: 67,
        parentvalue: 11,
        label: "其他制造业",
        rank: 2,
        children: [{value: 254, parentvalue: 67, label: "其他未列明制造业", rank: 3}, {
            value: 388,
            parentvalue: 67,
            label: "煤制品制造",
            rank: 3
        }, {value: 477, parentvalue: 67, label: "核辐射加工", rank: 3}, {
            value: 527,
            parentvalue: 67,
            label: "日用杂品制造",
            rank: 3
        }]
    }, {
        value: 70,
        parentvalue: 11,
        label: "石油加工、炼焦和核燃料加工业",
        rank: 2,
        children: [{value: 208, parentvalue: 70, label: "核燃料加工", rank: 3}, {
            value: 371,
            parentvalue: 70,
            label: "精炼石油产品制造",
            rank: 3
        }, {value: 445, parentvalue: 70, label: "炼焦", rank: 3}]
    }, {
        value: 71,
        parentvalue: 11,
        label: "废弃资源综合利用业",
        rank: 2,
        children: [{value: 437, parentvalue: 71, label: "非金属废料和碎屑加工处理", rank: 3}, {
            value: 504,
            parentvalue: 71,
            label: "金属废料和碎屑加工处理",
            rank: 3
        }]
    }, {
        value: 73,
        parentvalue: 11,
        label: "非金属矿物制品业",
        rank: 2,
        children: [{value: 158, parentvalue: 73, label: "水泥、石灰和石膏制造", rank: 3}, {
            value: 255,
            parentvalue: 73,
            label: "石墨及其他非金属矿物制品制造",
            rank: 3
        }, {value: 289, parentvalue: 73, label: "陶瓷制品制造", rank: 3}, {
            value: 312,
            parentvalue: 73,
            label: "砖瓦、石材等建筑材料制造",
            rank: 3
        }, {value: 313, parentvalue: 73, label: "玻璃纤维和玻璃纤维增强塑料制品制造", rank: 3}, {
            value: 315,
            parentvalue: 73,
            label: "石膏、水泥制品及类似制品制造",
            rank: 3
        }, {value: 349, parentvalue: 73, label: "玻璃制品制造", rank: 3}, {
            value: 383,
            parentvalue: 73,
            label: "耐火材料制品制造",
            rank: 3
        }, {value: 420, parentvalue: 73, label: "玻璃制造", rank: 3}]
    }, {
        value: 76,
        parentvalue: 11,
        label: "有色金属冶炼和压延加工业",
        rank: 2,
        children: [{value: 198, parentvalue: 76, label: "有色金属压延加工", rank: 3}, {
            value: 226,
            parentvalue: 76,
            label: "常用有色金属冶炼",
            rank: 3
        }, {value: 353, parentvalue: 76, label: "有色金属铸造", rank: 3}, {
            value: 384,
            parentvalue: 76,
            label: "有色金属合金制造",
            rank: 3
        }, {value: 397, parentvalue: 76, label: "稀有稀土金属冶炼", rank: 3}, {
            value: 452,
            parentvalue: 76,
            label: "贵金属冶炼",
            rank: 3
        }]
    }, {
        value: 82,
        parentvalue: 11,
        label: "电气机械和器材制造业",
        rank: 2,
        children: [{value: 136, parentvalue: 82, label: "非电力家用器具制造", rank: 3}, {
            value: 207,
            parentvalue: 82,
            label: "电池制造",
            rank: 3
        }, {value: 269, parentvalue: 82, label: "照明器具制造", rank: 3}, {
            value: 336,
            parentvalue: 82,
            label: "电线、电缆、光缆及电工器材制造",
            rank: 3
        }, {value: 339, parentvalue: 82, label: "其他电气机械及器材制造", rank: 3}, {
            value: 363,
            parentvalue: 82,
            label: "输配电及控制设备制造",
            rank: 3
        }, {value: 456, parentvalue: 82, label: "电机制造", rank: 3}, {
            value: 478,
            parentvalue: 82,
            label: "家用电力器具制造",
            rank: 3
        }]
    }, {
        value: 84,
        parentvalue: 11,
        label: "文教、工美、体育和娱乐用品制造业",
        rank: 2,
        children: [{value: 103, parentvalue: 84, label: "游艺器材及娱乐用品制造", rank: 3}, {
            value: 159,
            parentvalue: 84,
            label: "工艺美术品制造",
            rank: 3
        }, {value: 206, parentvalue: 84, label: "体育用品制造", rank: 3}, {
            value: 261,
            parentvalue: 84,
            label: "玩具制造",
            rank: 3
        }, {value: 284, parentvalue: 84, label: "文教办公用品制造", rank: 3}, {
            value: 390,
            parentvalue: 84,
            label: "乐器制造",
            rank: 3
        }]
    }, {
        value: 87,
        parentvalue: 11,
        label: "印刷和记录媒介复制业",
        rank: 2,
        children: [{value: 144, parentvalue: 87, label: "装订及印刷相关服务", rank: 3}, {
            value: 280,
            parentvalue: 87,
            label: "记录媒介复制",
            rank: 3
        }, {value: 506, parentvalue: 87, label: "印刷", rank: 3}]
    }, {
        value: 96,
        parentvalue: 11,
        label: "烟草制品业",
        rank: 2,
        children: [{value: 360, parentvalue: 96, label: "卷烟制造", rank: 3}, {
            value: 476,
            parentvalue: 96,
            label: "烟叶复烤",
            rank: 3
        }, {value: 500, parentvalue: 96, label: "其他烟草制品制造", rank: 3}]
    }, {
        value: 101,
        parentvalue: 11,
        label: "汽车制造业",
        rank: 2,
        children: [{value: 149, parentvalue: 101, label: "改装汽车制造", rank: 3}, {
            value: 194,
            parentvalue: 101,
            label: "汽车零部件及配件制造",
            rank: 3
        }, {value: 292, parentvalue: 101, label: "低速载货汽车制造", rank: 3}, {
            value: 325,
            parentvalue: 101,
            label: "汽车车身、挂车制造",
            rank: 3
        }, {value: 405, parentvalue: 101, label: "汽车整车制造", rank: 3}, {
            value: 503,
            parentvalue: 101,
            label: "电车制造",
            rank: 3
        }]
    }, {
        value: 103,
        parentvalue: 11,
        label: "橡胶和塑料制品业",
        rank: 2,
        children: [{value: 193, parentvalue: 103, label: "塑料制品业", rank: 3}, {
            value: 305,
            parentvalue: 103,
            label: "橡胶制品业",
            rank: 3
        }]
    }]
}, {
    value: 12,
    label: "建筑业",
    rank: 1,
    children: [{
        value: 18,
        parentvalue: 12,
        label: "房屋建筑业",
        rank: 2,
        children: [{value: 330, parentvalue: 18, label: "房屋建筑业", rank: 3}]
    }, {
        value: 20,
        parentvalue: 12,
        label: "建筑装饰和其他建筑业",
        rank: 2,
        children: [{value: 224, parentvalue: 20, label: "提供施工设备服务", rank: 3}, {
            value: 276,
            parentvalue: 20,
            label: "其他未列明建筑业",
            rank: 3
        }, {value: 288, parentvalue: 20, label: "建筑装饰业", rank: 3}, {
            value: 501,
            parentvalue: 20,
            label: "工程准备活动",
            rank: 3
        }]
    }, {
        value: 59,
        parentvalue: 12,
        label: "建筑安装业",
        rank: 2,
        children: [{value: 157, parentvalue: 59, label: "其他建筑安装业", rank: 3}, {
            value: 464,
            parentvalue: 59,
            label: "电气安装",
            rank: 3
        }, {value: 514, parentvalue: 59, label: "管道和设备安装", rank: 3}]
    }, {
        value: 104,
        parentvalue: 12,
        label: "土木工程建筑业",
        rank: 2,
        children: [{value: 219, parentvalue: 104, label: "海洋工程建筑", rank: 3}, {
            value: 222,
            parentvalue: 104,
            label: "铁路、道路、隧道和桥梁工程建筑",
            rank: 3
        }, {value: 375, parentvalue: 104, label: "水利和内河港口工程建筑", rank: 3}, {
            value: 486,
            parentvalue: 104,
            label: "其他土木工程建筑",
            rank: 3
        }, {value: 513, parentvalue: 104, label: "工矿工程建筑", rank: 3}, {
            value: 522,
            parentvalue: 104,
            label: "架线和管道工程建筑",
            rank: 3
        }]
    }]
}, {
    value: 13,
    label: "国际组织",
    rank: 1,
    children: [{
        value: 41,
        parentvalue: 13,
        label: "国际组织",
        rank: 2,
        children: [{value: 357, parentvalue: 41, label: "国际组织", rank: 3}]
    }]
}, {
    value: 14,
    label: "采矿业",
    rank: 1,
    children: [{
        value: 28,
        parentvalue: 14,
        label: "黑色金属矿采选业",
        rank: 2,
        children: [{value: 181, parentvalue: 28, label: "其他黑色金属矿采选", rank: 3}, {
            value: 347,
            parentvalue: 28,
            label: "铁矿采选",
            rank: 3
        }, {value: 528, parentvalue: 28, label: "锰矿、铬矿采选", rank: 3}]
    }, {
        value: 36,
        parentvalue: 14,
        label: "有色金属矿采选业",
        rank: 2,
        children: [{value: 105, parentvalue: 36, label: "稀有稀土金属矿采选", rank: 3}, {
            value: 233,
            parentvalue: 36,
            label: "贵金属矿采选",
            rank: 3
        }, {value: 427, parentvalue: 36, label: "常用有色金属矿采选", rank: 3}]
    }, {
        value: 69,
        parentvalue: 14,
        label: "开采辅助活动",
        rank: 2,
        children: [{value: 145, parentvalue: 69, label: "其他开采辅助活动", rank: 3}, {
            value: 165,
            parentvalue: 69,
            label: "煤炭开采和洗选辅助活动",
            rank: 3
        }, {value: 382, parentvalue: 69, label: "石油和天然气开采辅助活动", rank: 3}]
    }, {
        value: 72,
        parentvalue: 14,
        label: "其他采矿业",
        rank: 2,
        children: [{value: 283, parentvalue: 72, label: "其他采矿业", rank: 3}]
    }, {
        value: 75,
        parentvalue: 14,
        label: "石油和天然气开采业",
        rank: 2,
        children: [{value: 176, parentvalue: 75, label: "石油开采", rank: 3}, {
            value: 242,
            parentvalue: 75,
            label: "天然气开采",
            rank: 3
        }]
    }, {
        value: 98,
        parentvalue: 14,
        label: "煤炭开采和洗选业",
        rank: 2,
        children: [{value: 209, parentvalue: 98, label: "褐煤开采洗选", rank: 3}, {
            value: 218,
            parentvalue: 98,
            label: "烟煤和无烟煤开采洗选",
            rank: 3
        }, {value: 377, parentvalue: 98, label: "其他煤炭采选", rank: 3}]
    }, {
        value: 99,
        parentvalue: 14,
        label: "非金属矿采选业",
        rank: 2,
        children: [{value: 188, parentvalue: 99, label: "石棉及其他非金属矿采选", rank: 3}, {
            value: 277,
            parentvalue: 99,
            label: "土砂石开采",
            rank: 3
        }, {value: 391, parentvalue: 99, label: "采盐", rank: 3}, {
            value: 467,
            parentvalue: 99,
            label: "化学矿开采",
            rank: 3
        }]
    }]
}, {
    value: 15,
    label: "水利、环境和公共设施管理业",
    rank: 1,
    children: [{
        value: 42,
        parentvalue: 15,
        label: "生态保护和环境治理业",
        rank: 2,
        children: [{value: 143, parentvalue: 42, label: "环境治理业", rank: 3}, {
            value: 187,
            parentvalue: 42,
            label: "生态保护",
            rank: 3
        }]
    }, {
        value: 89,
        parentvalue: 15,
        label: "水利管理业",
        rank: 2,
        children: [{value: 135, parentvalue: 89, label: "水文服务", rank: 3}, {
            value: 138,
            parentvalue: 89,
            label: "防洪除涝设施管理",
            rank: 3
        }, {value: 338, parentvalue: 89, label: "天然水收集与分配", rank: 3}, {
            value: 354,
            parentvalue: 89,
            label: "其他水利管理业",
            rank: 3
        }, {value: 434, parentvalue: 89, label: "水资源管理", rank: 3}]
    }, {
        value: 91,
        parentvalue: 15,
        label: "公共设施管理业",
        rank: 2,
        children: [{value: 164, parentvalue: 91, label: "市政设施管理", rank: 3}, {
            value: 240,
            parentvalue: 91,
            label: "绿化管理",
            rank: 3
        }, {value: 243, parentvalue: 91, label: "环境卫生管理", rank: 3}, {
            value: 310,
            parentvalue: 91,
            label: "公园和游览景区管理",
            rank: 3
        }, {value: 492, parentvalue: 91, label: "城乡市容管理", rank: 3}]
    }]
}, {
    value: 16,
    label: "交通运输、仓储和邮政业",
    rank: 1,
    children: [{
        value: 16,
        parentvalue: 16,
        label: "航空运输业",
        rank: 2,
        children: [{value: 106, parentvalue: 16, label: "通用航空服务", rank: 3}, {
            value: 192,
            parentvalue: 16,
            label: "航空运输辅助活动",
            rank: 3
        }, {value: 373, parentvalue: 16, label: "航空客货运输", rank: 3}]
    }, {
        value: 25,
        parentvalue: 16,
        label: "装卸搬运和运输代理业",
        rank: 2,
        children: [{value: 123, parentvalue: 25, label: "装卸搬运", rank: 3}, {
            value: 482,
            parentvalue: 25,
            label: "运输代理业",
            rank: 3
        }]
    }, {
        value: 48,
        parentvalue: 16,
        label: "铁路运输业",
        rank: 2,
        children: [{value: 200, parentvalue: 48, label: "铁路货物运输", rank: 3}, {
            value: 385,
            parentvalue: 48,
            label: "铁路运输辅助活动",
            rank: 3
        }, {value: 471, parentvalue: 48, label: "铁路旅客运输", rank: 3}]
    }, {
        value: 50,
        parentvalue: 16,
        label: "道路运输业",
        rank: 2,
        children: [{value: 113, parentvalue: 50, label: "道路货物运输", rank: 3}, {
            value: 314,
            parentvalue: 50,
            label: "城市公共交通运输",
            rank: 3
        }, {value: 411, parentvalue: 50, label: "道路运输辅助活动", rank: 3}, {
            value: 481,
            parentvalue: 50,
            label: "公路旅客运输",
            rank: 3
        }]
    }, {
        value: 65,
        parentvalue: 16,
        label: "邮政业",
        rank: 2,
        children: [{value: 196, parentvalue: 65, label: "快递服务", rank: 3}, {
            value: 400,
            parentvalue: 65,
            label: "邮政基本服务",
            rank: 3
        }]
    }, {
        value: 77,
        parentvalue: 16,
        label: "水上运输业",
        rank: 2,
        children: [{value: 114, parentvalue: 77, label: "水上运输辅助活动", rank: 3}, {
            value: 195,
            parentvalue: 77,
            label: "水上货物运输",
            rank: 3
        }, {value: 356, parentvalue: 77, label: "水上旅客运输", rank: 3}]
    }, {
        value: 79,
        parentvalue: 16,
        label: "仓储业",
        rank: 2,
        children: [{value: 197, parentvalue: 79, label: "谷物、棉花等农产品仓储", rank: 3}, {
            value: 362,
            parentvalue: 79,
            label: "其他仓储业",
            rank: 3
        }]
    }, {
        value: 105,
        parentvalue: 16,
        label: "管道运输业",
        rank: 2,
        children: [{value: 201, parentvalue: 105, label: "管道运输业", rank: 3}]
    }]
}, {
    value: 17,
    label: "居民服务、修理和其他服务业",
    rank: 1,
    children: [{
        value: 14,
        parentvalue: 17,
        label: "其他服务业",
        rank: 2,
        children: [{value: 396, parentvalue: 14, label: "清洁服务", rank: 3}, {
            value: 517,
            parentvalue: 14,
            label: "其他未列明服务业",
            rank: 3
        }]
    }, {
        value: 29,
        parentvalue: 17,
        label: "机动车、电子产品和日用产品修理业",
        rank: 2,
        children: [{value: 117, parentvalue: 29, label: "汽车、摩托车修理与维护", rank: 3}, {
            value: 238,
            parentvalue: 29,
            label: "其他日用产品修理业",
            rank: 3
        }, {value: 285, parentvalue: 29, label: "家用电器修理", rank: 3}, {
            value: 523,
            parentvalue: 29,
            label: "计算机和办公设备维修",
            rank: 3
        }]
    }, {
        value: 56,
        parentvalue: 17,
        label: "居民服务业",
        rank: 2,
        children: [{value: 122, parentvalue: 56, label: "洗浴服务", rank: 3}, {
            value: 271,
            parentvalue: 56,
            label: "家庭服务",
            rank: 3
        }, {value: 290, parentvalue: 56, label: "洗染服务", rank: 3}, {
            value: 345,
            parentvalue: 56,
            label: "理发及美容服务",
            rank: 3
        }, {value: 361, parentvalue: 56, label: "婚姻服务", rank: 3}, {
            value: 374,
            parentvalue: 56,
            label: "保健服务",
            rank: 3
        }, {value: 380, parentvalue: 56, label: "殡葬服务", rank: 3}, {
            value: 428,
            parentvalue: 56,
            label: "托儿所服务",
            rank: 3
        }, {value: 455, parentvalue: 56, label: "其他居民服务业", rank: 3}]
    }]
}, {
    value: 18,
    label: "文化、体育和娱乐业",
    rank: 1,
    children: [{
        value: 13,
        parentvalue: 18,
        label: "体育",
        rank: 2,
        children: [{value: 202, parentvalue: 13, label: "体育组织", rank: 3}, {
            value: 324,
            parentvalue: 13,
            label: "其他体育",
            rank: 3
        }, {value: 451, parentvalue: 13, label: "体育场馆", rank: 3}, {
            value: 524,
            parentvalue: 13,
            label: "休闲健身活动",
            rank: 3
        }]
    }, {
        value: 37,
        parentvalue: 18,
        label: "娱乐业",
        rank: 2,
        children: [{value: 152, parentvalue: 37, label: "游乐园", rank: 3}, {
            value: 184,
            parentvalue: 37,
            label: "其他娱乐业",
            rank: 3
        }, {value: 237, parentvalue: 37, label: "室内娱乐活动", rank: 3}, {
            value: 404,
            parentvalue: 37,
            label: "文化、娱乐、体育经纪代理",
            rank: 3
        }, {value: 526, parentvalue: 37, label: "彩票活动", rank: 3}]
    }, {
        value: 49,
        parentvalue: 18,
        label: "广播、电视、电影和影视录音制作业",
        rank: 2,
        children: [{value: 118, parentvalue: 49, label: "电影和影视节目发行", rank: 3}, {
            value: 121,
            parentvalue: 49,
            label: "录音制作",
            rank: 3
        }, {value: 215, parentvalue: 49, label: "电影放映", rank: 3}, {
            value: 291,
            parentvalue: 49,
            label: "电影和影视节目制作",
            rank: 3
        }, {value: 469, parentvalue: 49, label: "广播", rank: 3}, {
            value: 520,
            parentvalue: 49,
            label: "电视",
            rank: 3
        }]
    }, {
        value: 54,
        parentvalue: 18,
        label: "新闻和出版业",
        rank: 2,
        children: [{value: 281, parentvalue: 54, label: "出版业", rank: 3}, {
            value: 321,
            parentvalue: 54,
            label: "新闻业",
            rank: 3
        }]
    }, {
        value: 88,
        parentvalue: 18,
        label: "文化艺术业",
        rank: 2,
        children: [{value: 116, parentvalue: 88, label: "文物及非物质文化遗产保护", rank: 3}, {
            value: 147,
            parentvalue: 88,
            label: "群众文化活动",
            rank: 3
        }, {value: 163, parentvalue: 88, label: "其他文化艺术业", rank: 3}, {
            value: 183,
            parentvalue: 88,
            label: "博物馆",
            rank: 3
        }, {value: 348, parentvalue: 88, label: "烈士陵园、纪念馆", rank: 3}, {
            value: 432,
            parentvalue: 88,
            label: "图书馆与档案馆",
            rank: 3
        }, {value: 458, parentvalue: 88, label: "艺术表演场馆", rank: 3}, {
            value: 510,
            parentvalue: 88,
            label: "文艺创作与表演",
            rank: 3
        }]
    }]
}, {
    value: 19,
    label: "公共管理、社会保障和社会组织",
    rank: 1,
    children: [{
        value: 21,
        parentvalue: 19,
        label: "基层群众自治组织",
        rank: 2,
        children: [{value: 417, parentvalue: 21, label: "社区自治组织", rank: 3}, {
            value: 442,
            parentvalue: 21,
            label: "村民自治组织",
            rank: 3
        }]
    }, {
        value: 23,
        parentvalue: 19,
        label: "社会保障",
        rank: 2,
        children: [{value: 337, parentvalue: 23, label: "社会保障", rank: 3}]
    }, {
        value: 27,
        parentvalue: 19,
        label: "人民政协、民主党派",
        rank: 2,
        children: [{value: 205, parentvalue: 27, label: "人民政协", rank: 3}, {
            value: 502,
            parentvalue: 27,
            label: "民主党派",
            rank: 3
        }]
    }, {
        value: 30,
        parentvalue: 19,
        label: "中国共产党机关",
        rank: 2,
        children: [{value: 344, parentvalue: 30, label: "中国共产党机关", rank: 3}]
    }, {
        value: 68,
        parentvalue: 19,
        label: "国家机构",
        rank: 2,
        children: [{value: 230, parentvalue: 68, label: "其他国家机构", rank: 3}, {
            value: 295,
            parentvalue: 68,
            label: "人民法院和人民检察院",
            rank: 3
        }, {value: 298, parentvalue: 68, label: "国家行政机构", rank: 3}, {
            value: 423,
            parentvalue: 68,
            label: "国家权力机构",
            rank: 3
        }]
    }, {
        value: 92,
        parentvalue: 19,
        label: "群众团体、社会团体和其他成员组织",
        rank: 2,
        children: [{value: 331, parentvalue: 92, label: "社会团体", rank: 3}, {
            value: 369,
            parentvalue: 92,
            label: "群众团体",
            rank: 3
        }, {value: 378, parentvalue: 92, label: "基金会", rank: 3}, {
            value: 379,
            parentvalue: 92,
            label: "宗教组织",
            rank: 3
        }]
    }]
}, {
    value: 20,
    label: "批发和零售业",
    rank: 1,
    children: [{
        value: 60,
        parentvalue: 20,
        label: "零售业",
        rank: 2,
        children: [{value: 101, parentvalue: 60, label: "纺织、服装及日用品专门零售", rank: 3}, {
            value: 217,
            parentvalue: 60,
            label: "医药及医疗器材专门零售",
            rank: 3
        }, {value: 274, parentvalue: 60, label: "家用电器及电子产品专门零售", rank: 3}, {
            value: 350,
            parentvalue: 60,
            label: "食品、饮料及烟草制品专门零售",
            rank: 3
        }, {value: 358, parentvalue: 60, label: "货摊、无店铺及其他零售业", rank: 3}, {
            value: 381,
            parentvalue: 60,
            label: "五金、家具及室内装饰材料专门零售",
            rank: 3
        }, {value: 430, parentvalue: 60, label: "汽车、摩托车、燃料及零配件专门零售", rank: 3}, {
            value: 448,
            parentvalue: 60,
            label: "文化、体育用品及器材专门零售",
            rank: 3
        }, {value: 525, parentvalue: 60, label: "综合零售", rank: 3}]
    }, {
        value: 81,
        parentvalue: 20,
        label: "批发业",
        rank: 2,
        children: [{value: 108, parentvalue: 81, label: "机械设备、五金产品及电子产品批发", rank: 3}, {
            value: 133,
            parentvalue: 81,
            label: "文化、体育用品及器材批发",
            rank: 3
        }, {value: 148, parentvalue: 81, label: "贸易经纪与代理", rank: 3}, {
            value: 174,
            parentvalue: 81,
            label: "农、林、牧产品批发",
            rank: 3
        }, {value: 177, parentvalue: 81, label: "医药及医疗器材批发", rank: 3}, {
            value: 263,
            parentvalue: 81,
            label: "纺织、服装及家庭用品批发",
            rank: 3
        }, {value: 282, parentvalue: 81, label: "矿产品、建材及化工产品批发", rank: 3}, {
            value: 366,
            parentvalue: 81,
            label: "食品、饮料及烟草制品批发",
            rank: 3
        }, {value: 466, parentvalue: 81, label: "其他批发业", rank: 3}]
    }]
}, {
    value: 21,
    label: "住宿和餐饮业",
    rank: 1,
    children: [{
        value: 93,
        parentvalue: 21,
        label: "住宿业",
        rank: 2,
        children: [{value: 155, parentvalue: 93, label: "旅游饭店", rank: 3}, {
            value: 372,
            parentvalue: 93,
            label: "其他住宿业",
            rank: 3
        }, {value: 450, parentvalue: 93, label: "一般旅馆", rank: 3}]
    }, {
        value: 100,
        parentvalue: 21,
        label: "餐饮业",
        rank: 2,
        children: [{value: 128, parentvalue: 100, label: "正餐服务", rank: 3}, {
            value: 253,
            parentvalue: 100,
            label: "快餐服务",
            rank: 3
        }, {value: 287, parentvalue: 100, label: "其他餐饮业", rank: 3}, {
            value: 364,
            parentvalue: 100,
            label: "饮料及冷饮服务",
            rank: 3
        }]
    }]
}, {
    value: 22,
    label: "电力、热力、燃气及水生产和供应业",
    rank: 1,
    children: [{
        value: 74,
        parentvalue: 22,
        label: "电力、热力生产和供应业",
        rank: 2,
        children: [{value: 134, parentvalue: 74, label: "热力生产和供应", rank: 3}, {
            value: 160,
            parentvalue: 74,
            label: "电力供应",
            rank: 3
        }, {value: 440, parentvalue: 74, label: "电力生产", rank: 3}]
    }, {
        value: 80,
        parentvalue: 22,
        label: "燃气生产和供应业",
        rank: 2,
        children: [{value: 407, parentvalue: 80, label: "燃气生产和供应业", rank: 3}]
    }, {
        value: 83,
        parentvalue: 22,
        label: "水的生产和供应业",
        rank: 2,
        children: [{value: 247, parentvalue: 83, label: "其他水的处理、利用与分配", rank: 3}, {
            value: 408,
            parentvalue: 83,
            label: "自来水生产和供应",
            rank: 3
        }, {value: 475, parentvalue: 83, label: "污水处理及其再生利用", rank: 3}]
    }]
}, {
    value: 23,
    label: "卫生和社会工作",
    rank: 1,
    children: [{
        value: 32,
        parentvalue: 23,
        label: "卫生",
        rank: 2,
        children: [{value: 100, parentvalue: 32, label: "医院", rank: 3}, {
            value: 107,
            parentvalue: 32,
            label: "专科疾病防治院（所、站）",
            rank: 3
        }, {value: 172, parentvalue: 32, label: "计划生育技术服务活动", rank: 3}, {
            value: 199,
            parentvalue: 32,
            label: "门诊部（所）",
            rank: 3
        }, {value: 401, parentvalue: 32, label: "妇幼保健院（所、站）", rank: 3}, {
            value: 421,
            parentvalue: 32,
            label: "疾病预防控制中心",
            rank: 3
        }, {value: 449, parentvalue: 32, label: "社区医疗与卫生院", rank: 3}, {
            value: 496,
            parentvalue: 32,
            label: "其他卫生活动",
            rank: 3
        }]
    }, {
        value: 57,
        parentvalue: 23,
        label: "社会工作",
        rank: 2,
        children: [{value: 412, parentvalue: 57, label: "不提供住宿社会工作", rank: 3}, {
            value: 485,
            parentvalue: 57,
            label: "提供住宿社会工作",
            rank: 3
        }]
    }]
}, {
    value: 24,
    label: "金融业",
    rank: 1,
    children: [{
        value: 34,
        parentvalue: 24,
        label: "保险业",
        rank: 2,
        children: [{value: 129, parentvalue: 34, label: "人身保险", rank: 3}, {
            value: 229,
            parentvalue: 34,
            label: "保险经纪与代理服务",
            rank: 3
        }, {value: 272, parentvalue: 34, label: "保险监管服务", rank: 3}, {
            value: 326,
            parentvalue: 34,
            label: "再保险",
            rank: 3
        }, {value: 418, parentvalue: 34, label: "养老金", rank: 3}, {
            value: 479,
            parentvalue: 34,
            label: "其他保险活动",
            rank: 3
        }, {value: 484, parentvalue: 34, label: "财产保险", rank: 3}]
    }, {
        value: 45,
        parentvalue: 24,
        label: "货币金融服务",
        rank: 2,
        children: [{value: 104, parentvalue: 45, label: "中央银行服务", rank: 3}, {
            value: 270,
            parentvalue: 45,
            label: "货币银行服务",
            rank: 3
        }, {value: 322, parentvalue: 45, label: "非货币银行服务", rank: 3}, {
            value: 488,
            parentvalue: 45,
            label: "银行监管服务",
            rank: 3
        }]
    }, {
        value: 86,
        parentvalue: 24,
        label: "资本市场服务",
        rank: 2,
        children: [{value: 125, parentvalue: 86, label: "证券期货监管服务", rank: 3}, {
            value: 301,
            parentvalue: 86,
            label: "资本投资服务",
            rank: 3
        }, {value: 340, parentvalue: 86, label: "证券市场服务", rank: 3}, {
            value: 351,
            parentvalue: 86,
            label: "其他资本市场服务",
            rank: 3
        }, {value: 395, parentvalue: 86, label: "期货市场服务", rank: 3}]
    }, {
        value: 90,
        parentvalue: 24,
        label: "其他金融业",
        rank: 2,
        children: [{value: 212, parentvalue: 90, label: "其他未列明金融业", rank: 3}, {
            value: 232,
            parentvalue: 90,
            label: "金融信息服务",
            rank: 3
        }, {value: 256, parentvalue: 90, label: "金融信托与管理服务", rank: 3}, {
            value: 406,
            parentvalue: 90,
            label: "控股公司服务",
            rank: 3
        }, {value: 446, parentvalue: 90, label: "非金融机构支付服务", rank: 3}]
    }]
}, {
    value: 25,
    label: "教育",
    rank: 1,
    children: [{
        value: 19,
        parentvalue: 25,
        label: "教育",
        rank: 2,
        children: [{value: 227, parentvalue: 19, label: "学前教育", rank: 3}, {
            value: 359,
            parentvalue: 19,
            label: "高等教育",
            rank: 3
        }, {value: 367, parentvalue: 19, label: "特殊教育", rank: 3}, {
            value: 376,
            parentvalue: 19,
            label: "技能培训、教育辅助及其他教育",
            rank: 3
        }, {value: 508, parentvalue: 19, label: "初等教育", rank: 3}, {
            value: 531,
            parentvalue: 19,
            label: "中等教育",
            rank: 3
        }]
    }]
}, {
    value: 26,
    label: "房地产业",
    rank: 1,
    children: [{
        value: 43,
        parentvalue: 26,
        label: "房地产业",
        rank: 2,
        children: [{value: 127, parentvalue: 43, label: "物业管理", rank: 3}, {
            value: 264,
            parentvalue: 43,
            label: "房地产中介服务",
            rank: 3
        }, {value: 303, parentvalue: 43, label: "其他房地产业", rank: 3}, {
            value: 316,
            parentvalue: 43,
            label: "自有房地产经营活动",
            rank: 3
        }, {value: 465, parentvalue: 43, label: "房地产开发经营", rank: 3}]
    }]
}, {
    value: 27,
    label: "科学研究和技术服务业",
    rank: 1,
    children: [{
        value: 24,
        parentvalue: 27,
        label: "专业技术服务业",
        rank: 2,
        children: [{value: 140, parentvalue: 24, label: "质检技术服务", rank: 3}, {
            value: 150,
            parentvalue: 24,
            label: "地震服务",
            rank: 3
        }, {value: 211, parentvalue: 24, label: "气象服务", rank: 3}, {
            value: 262,
            parentvalue: 24,
            label: "工程技术",
            rank: 3
        }, {value: 323, parentvalue: 24, label: "环境与生态监测", rank: 3}, {
            value: 332,
            parentvalue: 24,
            label: "地质勘查",
            rank: 3
        }, {value: 468, parentvalue: 24, label: "海洋服务", rank: 3}, {
            value: 493,
            parentvalue: 24,
            label: "其他专业技术服务业",
            rank: 3
        }, {value: 495, parentvalue: 24, label: "测绘服务", rank: 3}]
    }, {
        value: 61,
        parentvalue: 27,
        label: "研究和试验发展",
        rank: 2,
        children: [{value: 266, parentvalue: 61, label: "自然科学研究和试验发展", rank: 3}, {
            value: 389,
            parentvalue: 61,
            label: "农业科学研究和试验发展",
            rank: 3
        }, {value: 438, parentvalue: 61, label: "工程和技术研究和试验发展", rank: 3}, {
            value: 460,
            parentvalue: 61,
            label: "医学研究和试验发展",
            rank: 3
        }, {value: 509, parentvalue: 61, label: "社会人文科学研究", rank: 3}]
    }, {
        value: 97,
        parentvalue: 27,
        label: "科技推广和应用服务业",
        rank: 2,
        children: [{value: 131, parentvalue: 97, label: "技术推广服务", rank: 3}, {
            value: 393,
            parentvalue: 97,
            label: "其他科技推广和应用服务业",
            rank: 3
        }, {value: 429, parentvalue: 97, label: "科技中介服务", rank: 3}]
    }]
}, {
    value: 28,
    label: "租赁和商务服务业",
    rank: 1,
    children: [{
        value: 63,
        parentvalue: 28,
        label: "商务服务业",
        rank: 2,
        children: [{value: 171, parentvalue: 63, label: "其他商务服务业", rank: 3}, {
            value: 189,
            parentvalue: 63,
            label: "广告业",
            rank: 3
        }, {value: 250, parentvalue: 63, label: "企业管理服务", rank: 3}, {
            value: 273,
            parentvalue: 63,
            label: "知识产权服务",
            rank: 3
        }, {value: 300, parentvalue: 63, label: "法律服务", rank: 3}, {
            value: 317,
            parentvalue: 63,
            label: "咨询与调查",
            rank: 3
        }, {value: 409, parentvalue: 63, label: "安全保护服务", rank: 3}, {
            value: 413,
            parentvalue: 63,
            label: "旅行社及相关服务",
            rank: 3
        }, {value: 454, parentvalue: 63, label: "人力资源服务", rank: 3}]
    }, {
        value: 78,
        parentvalue: 28,
        label: "租赁业",
        rank: 2,
        children: [{value: 213, parentvalue: 78, label: "文化及日用品出租", rank: 3}, {
            value: 346,
            parentvalue: 78,
            label: "机械设备租赁",
            rank: 3
        }]
    }]
}, {
    value: 29,
    label: "农、林、牧、渔业",
    rank: 1,
    children: [{
        value: 26,
        parentvalue: 29,
        label: "渔业",
        rank: 2,
        children: [{value: 137, parentvalue: 26, label: "水产养殖", rank: 3}, {
            value: 191,
            parentvalue: 26,
            label: "水产捕捞",
            rank: 3
        }]
    }, {
        value: 44,
        parentvalue: 29,
        label: "农、林、牧、渔服务业",
        rank: 2,
        children: [{value: 112, parentvalue: 44, label: "渔业服务业", rank: 3}, {
            value: 268,
            parentvalue: 44,
            label: "农业服务业",
            rank: 3
        }, {value: 311, parentvalue: 44, label: "林业服务业", rank: 3}, {
            value: 480,
            parentvalue: 44,
            label: "畜牧服务业",
            rank: 3
        }]
    }, {
        value: 62,
        parentvalue: 29,
        label: "农业",
        rank: 2,
        children: [{value: 170, parentvalue: 62, label: "坚果、含油果、香料和饮料作物种植", rank: 3}, {
            value: 225,
            parentvalue: 62,
            label: "水果种植",
            rank: 3
        }, {value: 278, parentvalue: 62, label: "谷物种植", rank: 3}, {
            value: 365,
            parentvalue: 62,
            label: "中药材种植",
            rank: 3
        }, {value: 370, parentvalue: 62, label: "豆类、油料和薯类种植", rank: 3}, {
            value: 410,
            parentvalue: 62,
            label: "其他农业",
            rank: 3
        }, {value: 426, parentvalue: 62, label: "棉、麻、糖、烟草种植", rank: 3}, {
            value: 433,
            parentvalue: 62,
            label: "蔬菜、食用菌及园艺作物种植",
            rank: 3
        }]
    }, {
        value: 95,
        parentvalue: 29,
        label: "畜牧业",
        rank: 2,
        children: [{value: 111, parentvalue: 95, label: "牲畜饲养", rank: 3}, {
            value: 130,
            parentvalue: 95,
            label: "家禽饲养",
            rank: 3
        }, {value: 489, parentvalue: 95, label: "其他畜牧业", rank: 3}, {
            value: 491,
            parentvalue: 95,
            label: "狩猎和捕捉动物",
            rank: 3
        }]
    }, {
        value: 102,
        parentvalue: 29,
        label: "林业",
        rank: 2,
        children: [{value: 275, parentvalue: 102, label: "林产品采集", rank: 3}, {
            value: 286,
            parentvalue: 102,
            label: "木材和竹材采运",
            rank: 3
        }, {value: 318, parentvalue: 102, label: "森林经营和管护", rank: 3}, {
            value: 327,
            parentvalue: 102,
            label: "造林和更新",
            rank: 3
        }, {value: 399, parentvalue: 102, label: "林木育种和育苗", rank: 3}]
    }]
}]
