import React, { Component } from "react";
import {
    Row,
    Breadcrumb,
    Button,
    Form,
    message,
    DatePicker,
    Modal,
    Input,
    Select,
    Col,
    Radio,
    Popover,
    Table,
    TreeSelect,
} from "antd";
import { AreaSelect, industryList, popoverTable } from "../../common";
import Filter from "../../common/filter";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Axios } from "../../axios";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;
let setPageData = {},
    sendBody = {},
    Timer;

class PayCompanyManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tData: [],
            loading: false,
            totalNum: 0,
            productList: [],
            currentPage: window.routerCache.PayCompanyManage.currentPage || 1,
        };
        sendBody = window.routerCache.PayCompanyManage.sendBody || {};
        setPageData = {};
    }

    // 获取表格数据
    refreshTableByData = (data, totalNum, currentPage) => {
        this.setState(
            { tData: data, totalNum: totalNum, currentPage: currentPage },
            this.polling
        );
    };
    // 获取表格数据
    refreshTable = async () => {
        try {
            this.setState({
                loading: true,
            });
            let send = sendBody;
            // send.workType = window.routerCache.PayCompanyManage.send_workType || '';
            send.pageNum = this.state.currentPage + "";
            send.pageSize = "10";
            let res = await Axios("get", "/brokeruser/payCompany/list", send);
            this.setState(
                {
                    loading: false,
                    tData: res.data.dataList,
                    totalNum: res.data.totalNum,
                },
                () => {
                    this.setState({
                        tData: res.data.dataList,
                    });
                }
            );
            setPageData[this.state.currentPage + ""] = res.data.dataList;
        } catch (err) {
            // message.error(err);
            this.setState({ loading: false });
        }
    };

    getProduct = async () => {
        let res = await Axios("get", "/order/meal/list", {});
        if (res.code === "00") {
            this.setState({
                productList: JSON.parse(res.body),
            });
        } else {
            message.error("获取套餐失败");
        }
    };
    handleTableChange = async (pagination) => {
        this.setState({ loading: true });
        if (Object.keys(setPageData).includes(pagination.current + "")) {
            this.setState({
                loading: false,
                tData: setPageData[pagination.current + ""],
                currentPage: parseInt(pagination.current),
            });
        } else {
            try {
                let send = sendBody;
                send.pageNum = pagination.current + "";
                send.pageSize = "10";
                let res = await Axios(
                    "get",
                    "/brokeruser/payCompany/list",
                    send
                );
                this.setState(
                    {
                        loading: false,
                        tData: res.data.dataList,
                        totalNum: res.data.totalNum,
                        currentPage: pagination.current,
                    },
                    this.polling
                );
                setPageData[pagination.current + ""] = res.data.dataList;
            } catch (err) {
                // message.error(err.message);
                this.setState({ loading: false });
            }
        }
    };

    // 组件渲染后获取外界数据(GET)
    componentDidMount() {
        this.refreshTable();
        this.getProduct();
        window.winhcFn.changeDept = () => {
            this.setState(
                {
                    currentPage: 1,
                },
                () => {
                    this.refreshTable();
                }
            );
        };
    }

    componentWillUnmount() {
        window.routerCache.PayCompanyManage.currentPage =
            this.state.currentPage;
        window.routerCache.PayCompanyManage.sendBody = sendBody;
        if (Timer) {
            clearTimeout(Timer);
        }
    }

    polling = () => {
        let gonext = false;
        this.state.tData.forEach((item) => {
            if (item.status == "0") {
                gonext = true;
                return;
            }
        });
        if (!gonext) {
            return;
        }
        if (Timer) {
            clearTimeout(Timer);
        }
        // setTimeout(async () => {
        //     try {
        //         let send = sendBody;
        //         send.pageNum = this.state.currentPage + "";
        //         send.pageSize = "10";
        //         let res = await Axios(
        //             "get",
        //             "/brokeruser/payCompany/list",
        //             send
        //         );
        //         if (this.state.currentPage == sendBody.pageNum) {
        //             this.setState(
        //                 {
        //                     tData: res.data.dataList,
        //                     totalNum: res.data.totalNum,
        //                 },
        //                 this.polling
        //             );
        //             setPageData[this.state.currentPage + ""] =
        //                 res.data.dataList;
        //         }
        //     } catch (err) {
        //         // message.error(err.message);
        //         console.log(err);
        //     }
        // }, 1000);
    };

    render() {
        let styleS = {
            display: "flex",
            background: "red",
            color: "white",
            borderRadius: "50%",
            width: "18px",
            height: "18px",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "12px",
            marginTop: "-5px",
        };
        let columns = [
            {
                title: "企业ID",
                dataIndex: "id",
                fixed: "left",
                // width: 70,
            },
            {
                title: "企业名称",
                dataIndex: "companyName",
                // width: 210,
                fixed: "left",
                render: (text, r) => (
                    <div style={{ display: "flex", cursor: "pointer" }}>
                        {text && text.length > 11 ? (
                            <Popover
                                content={
                                    <div
                                        style={{
                                            padding: "10px",
                                            maxWidth: "500px",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {text}
                                    </div>
                                }
                            >
                                <span>{Filter.strLength(text, 11)}</span>
                            </Popover>
                        ) : (
                            text
                        )}{" "}
                        {r.transSign == "1" ? (
                            <span style={styleS}>转</span>
                        ) : (
                            ""
                        )}
                    </div>
                ),
            },
            popoverTable(10, {
                title: "服务律师团名称",
                dataIndex: "teamName",
                // width: 180,
            }),
            {
                title: "业务员",
                dataIndex: "brokerUserName",
                // width: 180,
            },
            {
                title: "联系人",
                dataIndex: "linkman",
                // width: 120,
            },
            {
                title: "联系电话",
                dataIndex: "mobileNo",
                // width: 120,
            },
            {
                title: "企业来源",
                dataIndex: "sourceType",
                // width: 120,
                render: (t, r) => Filter.PayCompanyManageSourceType(t),
            },
            {
                title: "首要需求",
                dataIndex: "firstDemand",
                // width: 120,
                render: (t, r) => Filter.FirstDemandType(t),
            },
            {
                title: "行业",
                dataIndex: "firstCategory",
                // width: 110,
                render: (text, r) => (
                    <div style={{ cursor: "pointer" }}>
                        {text && text.length > 5 ? (
                            <Popover
                                content={
                                    <div
                                        style={{
                                            padding: "10px",
                                            maxWidth: "500px",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {text}
                                        {r.secondCategory}
                                        {r.thirdCategory}
                                    </div>
                                }
                            >
                                <span>
                                    {Filter.strLength(
                                        text +
                                        r.secondCategory +
                                        r.thirdCategory,
                                        5
                                    )}
                                </span>
                            </Popover>
                        ) : (
                            text
                        )}
                    </div>
                ),
            },
            {
                title: "企业经营地",
                dataIndex: "province",
                // width: 110,
                render: (t, r) =>
                    (t || "") + " " + (r.city || "") + " " + (r.county || ""),
            },
            // {
            //
            //     title: "代理商",
            //     dataIndex: "brokerName", width: 110,
            //     render: (text) => (
            //         <div style={{cursor: "pointer"}}>
            //             {text && text.length > 5 ?
            //                 <Popover content={<div
            //                     style={{padding: '10px', maxWidth: '500px', fontSize: '14px'}}>{text}</div>}>
            //                     <span>{Filter.strLength(text, 5)}</span>
            //                 </Popover>
            //                 : text}
            //         </div>)
            // },
            {
                title: "法务助理",
                dataIndex: "assistants",
                render: (text) => (text ? text.join("、") : ""),
                // width: 120,
            },
            {
                title: "订单数",
                dataIndex: "orderCount",
                // width: 160,
            },
            {
                title: "当前套餐",
                dataIndex: "costAmt",
                // width: 145,
                // render: (t, r) =>
                //     t
                //         ? filter.legalspecification(t) + " " + r.costAmt + "元"
                //         : "",
                render: (t, r) => (t ? "￥" + t + "/" + r.serviceMode : ""),
            },
            {
                title: "购买时间",
                dataIndex: "payTime",
                // width: 160,
                render: (t) =>
                    t
                        ? new Date(t).toLocaleString("chinese", {
                            hour12: false,
                        })
                        : "-",
            },
            {
                title: "会员到期时间",
                dataIndex: "nextCheckDate",
                // width: 160,
                render: (t) =>
                    t
                        ? new Date(t).toLocaleString("chinese", {
                            hour12: false,
                        })
                        : "-",
            },
            {
                title: "客户状态",
                dataIndex: "customStatus",
                fixed: "right",
                // width: 105,
                render: (text) =>
                    text == 1 ? "履约中" : text == 2 ? "已到期" : "",
            },
            {
                title: "操作",
                dataIndex: "operate",
                // width: 110,
                fixed: "right",
                render: (text, r) => (
                    <Link to={"/PayCompanyManageDetail/" + r.id}>查看</Link>
                ),
            },
        ];
        return (
            <div className="pageContainer">
                <Row className="headNav">
                    <Col span={24}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>客户管理</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={24} className="title">
                        <span className="name">付费企业客户管理</span>
                        {/* <span className='desc'>查询企业对象为公开案件信息的企业</span> */}
                    </Col>
                </Row>
                <div className="scrollContainer">
                    <div className="scrollMain">
                        <Search
                            refreshTableByData={this.refreshTableByData}
                            productList={this.state.productList}
                        />

                        <div style={{ backgroundColor: "#fff" }}>
                            <Row
                                type="flex"
                                justify="space-between"
                                align="middle"
                            ></Row>

                            <Table
                                scroll={{ x: 'max-content' }}
                                dataSource={this.state.tData}
                                columns={columns}
                                size="middle"
                                pagination={{
                                    size: "large",
                                    showQuickJumper: true,
                                    pageSize: 10,
                                    total: parseInt(this.state.totalNum),
                                    current: Number(this.state.currentPage),
                                }}
                                loading={this.state.loading}
                                onChange={this.handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return state;
    },
    (dispatch) => ({})
)(PayCompanyManage);

class SearchM extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            // sDate: "",
            // eDate: "",
            province: "",
            city: "",
            qingkong: false,
            StatusList: [],
            brokerIds: 0,
        };
    }

    // 查询
    query = async () => {
        setPageData = {};
        let _this = this;
        sendBody = this.formRef.current.getFieldsValue();
        if (sendBody.date1) {
            sendBody.startPayTime = Filter.timetrans(sendBody.date1[0], "day");
            sendBody.endPayTime = Filter.timetrans(sendBody.date1[1], "day");
            delete sendBody.date1;
        }
        if (sendBody.date2) {
            sendBody.startNextCheckDate = Filter.timetrans(
                sendBody.date2[0],
                "day"
            );
            sendBody.endNextCheckDate = Filter.timetrans(
                sendBody.date2[1],
                "day"
            );
            delete sendBody.date2;
        }
        _this.props.refreshTableByData([], 1, 1);
        sendBody.pageNum = "1";
        sendBody.pageSize = "10";
        if (this.state.province) {
            sendBody.province = this.state.province;
        }
        if (this.state.city) {
            sendBody.city = this.state.city;
        }
        console.log(sendBody);
        try {
            let res = await Axios(
                "get",
                "/brokeruser/payCompany/list",
                sendBody
            );

            _this.props.refreshTableByData(
                res.data.dataList,
                res.data.totalNum,
                1
            );
            setPageData["1"] = res.data.dataList;
        } catch (err) {
            // message.error(err.message);
        }
    };
    clearOptions = async () => {
        // 清空的时候清空地区
        this.setState(
            {
                province: "",
                city: "",
                qingkong: true,
            },
            () => {
                this.setState({
                    qingkong: false,
                });
                this.formRef.current.resetFields();
            }
        );

        sendBody = {};
        setPageData = {};
        sendBody.pageNum = "1";
        sendBody.pageSize = "10";
        // sendBody.workType = window.routerCache.PayCompanyManage.send_workType || '';
        this.props.refreshTableByData([], 1, 1);
        try {
            let res = await Axios(
                "get",
                "/brokeruser/payCompany/list",
                sendBody
            );

            this.props.refreshTableByData(
                res.data.dataList,
                res.data.totalNum,
                1
            );
            setPageData["1"] = res.data.dataList;
        } catch (err) {
            // message.error(err.message);
        }
    };
    // 选择日期范围
    // dateChange = (value, b) => {
    //     console.log(value, b)
    //     if (value.length !== 0) {
    //         // this.setState({sDate: b[0].replace(/-/g, '')});
    //         // this.setState({eDate: b[1].replace(/-/g, '')});
    //         this.setState({sDate: b[0]});
    //         this.setState({eDate: b[1]});
    //     } else {
    //         this.setState({sDate: ""});
    //         this.setState({eDate: ""});
    //     }
    //
    // }

    // 组件渲染后获取外界数据(GET)
    componentDidMount() {
        const { userRole } = this.props.operInfo;
        const job = window.globalData.job;
        if (userRole === "1" || job == "senior") {
            Axios("get", "/brokeruser/page", {}).then((val) => {
                this.setState({
                    StatusList: (val.data || {}).dataList || [],
                    brokerIds: localStorage.getItem("brokerIds"),
                });
            });
        }
    }

    render() {
        /*控制查询按钮状态*/
        const { StatusList } = this.state;

        return (
            <div className="search_like_antD" style={{ marginTop: "-8px" }}>
                <Form
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    ref={this.formRef}
                >
                    <Row type="flex" align="middle">
                        <Col span="8">
                            <Form.Item
                                label="企业名称"
                                style={{ width: "100%" }}
                                name="companyName"
                                initialValue={sendBody.companyName}
                            >
                                <Input
                                    placeholder="请输入"
                                    type="text"
                                    style={{ width: "80%" }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span="8">
                            <Form.Item
                                label="联系电话"
                                style={{ width: "100%" }}
                                name="mobileNo"
                                initialValue={sendBody.mobileNo}
                            >
                                <Input
                                    placeholder="请输入"
                                    type="text"
                                    style={{ width: "80%" }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span="8">
                            <Form.Item
                                label="联系人"
                                style={{ width: "100%" }}
                                name="linkman"
                                initialValue={sendBody.linkman}
                            >
                                <Input
                                    placeholder="请输入"
                                    type="text"
                                    style={{ width: "80%" }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row type="flex" align="middle">
                        <Col span="8">
                            <Form.Item
                                label="所属地区"
                                style={{ width: "100%" }}
                                name="province"
                                initialValue={sendBody.province}
                            >
                                <AreaSelect
                                    bodyStyle={{ width: "80%" }}
                                    province={this.state.province}
                                    city={this.state.city}
                                    placeholder={true}
                                    allowClear
                                    qingkong={this.state.qingkong}
                                    hiddenCountry
                                    autoselect
                                    select={(province, city, county) => {
                                        console.log(province, city, county);
                                        this.setState({
                                            province: province || "",
                                            city: city || "",
                                            county: county || "",
                                        });
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span="8">
                            <Form.Item
                                label="客户状态"
                                style={{ width: "100%" }}
                                name="customStatus"
                                initialValue={sendBody.customStatus}
                            >
                                <Select
                                    placeholder="请选择"
                                    allowClear
                                    style={{ width: "80%" }}
                                >
                                    <Option key="1">履约中</Option>
                                    <Option key="2">已到期</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span="8">
                            <Form.Item
                                label="开始购买时间"
                                style={{ width: "100%" }}
                                name="date1"
                                initialValue={sendBody.date1}
                            >
                                <RangePicker
                                    style={{ width: "80%" }}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row type="flex" align="middle">
                        <Col span="8">
                            {" "}
                            <Form.Item
                                label="结束购买时间"
                                style={{ width: "100%" }}
                                name="date2"
                                initialValue={sendBody.date2}
                            >
                                <RangePicker
                                    style={{ width: "80%" }}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                        <Col span="8">
                            <Form.Item
                                label="首要需求"
                                style={{ width: "100%" }}
                                name="firstDemand"
                                initialValue={sendBody.firstDemand}
                            >
                                <Select
                                    placeholder="请选择"
                                    allowClear
                                    style={{ width: "80%" }}
                                >
                                    <Option key={1}>应收帐款催款</Option>
                                    <Option key={2}>劳动人事咨询</Option>
                                    <Option key={3}>合同起草</Option>
                                    <Option key={4}>合同审核</Option>
                                    <Option key={5}>起草/发送律师函</Option>
                                    <Option key={8}>应收账款催款</Option>
                                    <Option key={9}>代写起诉状/答辩状</Option>
                                    <Option key={6}>其他咨询</Option>
                                    <Option key={7}>其他</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="业务员"
                                style={{ width: "100%" }}
                                name="brokerUserId"
                                initialValue={sendBody.brokerUserId}
                            >
                                <Select
                                    style={{ width: "80%" }}
                                    placeholder="请选择"
                                    allowClear
                                >
                                    <Option
                                        key={this.props.userInfo.id}
                                        value={this.props.userInfo.id}
                                    >
                                        {this.props.userInfo.userName || this.props.userInfo.brokerName || ''}
                                        （当前自己）
                                    </Option>
                                    {StatusList.map((v) => {
                                        if (
                                            this.props.userInfo.userId !=
                                            v.userId
                                        )
                                            return (
                                                <Option key={v.id} value={v.id}>
                                                    {v.userName}
                                                </Option>
                                            );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row type="flex" align="middle">
                        <Col span="8"></Col>
                        <Col span="8"></Col>
                        <Col span={8}>
                            <Row
                                type="flex"
                                justify="end"
                                align="middle"
                                style={{ width: "85%", height: "30px" }}
                            >
                                <Form.Item style={{ marginRight: "16px" }}>
                                    <Button type="primary" onClick={this.query}>
                                        查询
                                    </Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button onClick={this.clearOptions}>
                                        重置
                                    </Button>
                                </Form.Item>
                            </Row>
                        </Col>
                    </Row>
                    <div>
                        <Row
                            type="flex"
                            justify="space-between"
                            align="middle"
                            style={
                                window.routerCache.PayCompanyManage.workTypeStr
                                    ? {
                                        paddingBottom: "10px",
                                    }
                                    : { paddingBottom: "40px" }
                            }
                        ></Row>
                    </div>
                </Form>
                <style>{`
               .search_like_antD .ant-form ant-form-inline .ant-row-flex .ant-row-flex-space-between .ant-row-flex-middle{
                    padding: 0px !important;
                }
                `}</style>
            </div>
        );
    }
}

let Search = connect(
    (state) => {
        return state;
    },
    (dispatch) => ({})
)(SearchM);
