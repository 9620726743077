/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react'
import styles from "../../assets/css/index.module.less";
import { SeatchData, SeatchType } from "../../common/SeatchData";
import { Tabs, Checkbox, Tag, Dropdown, Menu, Button, Cascader, Space, Input, Tooltip, DatePicker, Popover, message } from 'antd'
import { Axios } from "../../axios";
import "../../assets/css/antdless.less";
import { cityData, cityByCodeData, Credit } from "../../common/common";
import Filter from "../../common/filter";
import { Industry } from "../../common/Industry";
import useGetState from "../hooks/useGetState";
import { CaretDownOutlined, CloseOutlined, SyncOutlined, LoadingOutlined } from '@ant-design/icons'
import moment from 'moment'
import SearchHistoryDrawer from '../common/SearchHistoryDrawer'
let includesKey = ['hasMicroCompany']
let creditSecurityfount = 'ALL',
  socialSecurityfount = 'ALL',
  regCapitalfount = 'ALL',
  actualfount = 'ALL', creditDataArr = []

const searchTypes = [
  {
    label: '企业名',
    value: '1',
  },
  {
    label: '法定代表人',
    value: '2',
  },
  {
    label: '股东',
    value: '3',
  },
  {
    label: '高管',
    value: '4',
  },
  {
    label: '品牌_产品',
    value: '5',
  },
  {
    label: '地址',
    value: '6',
  },
]
const navigateLegalWebsite = (uri) => {
  const sessionId = localStorage.getItem("sessionId") || "";
  const base64SessionId = window.btoa(sessionId);
  // const baseUrl = 'http://10.1.10.207:6009' //本地环境测试
  window.open(
    `${window.legalWebUrl
    }/TransitPage?K=${base64SessionId}&redirect=${encodeURIComponent(uri)}`,
    "_blank"
  );
  // window.open(`${baseUrl}/TransitPage?K=${base64SessionId}&redirect=${encodeURIComponent(uri)}`, '_blank')
};
const objType = {
  成立年限: 'timeRanges&timeCode',
  备案网站: 'hasIcp&hasIcp',
  一般纳税人: 'taxpayer&taxpayer',
  注册资本: 'regCapitalRanges&MAX',
  实缴资本: 'actualCapitalRanges&MAX',
  登记状态: 'regStatusStds&ARR',
  企业类型: 'companyOrgTypeStds&ARR',
  组织机构: 'companyTypes&ARR',
  资本类型: 'regCapitalCurrencies&ARR',
  参保人数: 'socialSecurityStaffNums&MAX',
  融资轮次: 'financeRounds&ARR',
  科技型企业: 'technologyCompanies&ARR',
}
const SeatchDataObj = {
  成立年限: '1',
  注册资本: '2',
  实缴资本: '3',
  登记状态: '4',
  企业类型: '5',
  组织机构: '6',
  资本类型: '7',
  参保人数: '8',
  联系电话: '9',
  备案网站: '10',
  一般纳税人: '11',
  融资轮次: '12',
  科技型企业: '13',
  联系邮箱: '14',
  失信被执行人: '15',
  破产重整: '16',
  裁判文书: '17',
  行政处罚: '18',
  清算信息: '19',
  动产抵押: '20',
  经营异常: '21',
  股权冻结: '22',
  被执行人: '23',
  限制高消费: '24',
  终本案件: '25',
  司法案件: '26',
  小微企业: '27',
}

const obj1 = {
  hasPhones: '联系电话',
  hasMicroCompany: '小微企业',
  hasEmail: '联系邮箱',
  hasIcp: '备案网站',
  taxpayer: '一般纳税人',
  hasDishonest: '失信被执行人',
  hasBankruptcy: '破产重整',
  hasWenshu: '裁判文书',
  hasPunishment: '行政处罚',
  hasLiquidating: '清算信息',
  hasMortgage: '动产抵押',
  hasAbnormal: '经营异常',
  hasJudicial: '股权冻结',
  hasZxr: '被执行人',
  hasRestrict: '限制高消费',
  hasFinalCase: '终本案件',
  hasJudicialCase: '司法案件',
}
const obj2 = {
  联系电话: 'hasPhones',
  小微企业: 'hasMicroCompany',
  联系邮箱: 'hasEmail',
  备案网站: 'hasIcp',
  一般纳税人: 'taxpayer',
  失信被执行人: 'hasDishonest',
  破产重整: 'hasBankruptcy',
  裁判文书: 'hasWenshu',
  行政处罚: 'hasPunishment',
  清算信息: 'hasLiquidating',
  动产抵押: 'hasMortgage',
  经营异常: 'hasAbnormal',
  股权冻结: 'hasJudicial',
  被执行人: 'hasZxr',
  限制高消费: 'hasRestrict',
  终本案件: 'hasFinalCase',
  司法案件: 'hasJudicialCase',
}
const disabledDate = (current) => {
  return current && current > moment().subtract(15, 'days')
}
const EnterpriseQuery = () => {
  const [Loading, setLoading] = useState(false)
  const [PageNum, setPageNum] = useState(1)
  const [PageSize, setPageSize] = useState(10)
  const [totalNum, setTotalNum] = useState(0)
  const [Type, setType] = useState('0')
  const [handleChecked, setHandleChecked] = useState({})
  const [handleData, setHandleData] = useState([])
  const [DropdownData, setDropdownData] = useState([{}])
  const [subscription, setSubscription] = useState([
    {
      keyWord: '成立年限',
      content: '成立年限',
    },
  ])
  const [demessionVisable, setDemessionoVisable] = useState(false)
  const [demessionVisable1, setDemessionoVisable1] = useState(false)
  const [SearchStatus, setSearchStatus] = useState(false)
  const [urlData, setUrlData] = useState([])
  const [tagData, setTagData] = useState([])
  const [areaData, setareaData] = useState([])
  const [nationalData, setnationalData] = useState([])

  const [infoTypeList, setInfoTypeList, getInfoTypeList] = useGetState([])
  const [cityByData, setCityByData, getCityByData] = useGetState([])
  const [companyName, setCompanyName] = useState('')
  const [SearchType, setSearchType, getSearchType] = useGetState([])

  const [value1name, setvalue1name] = useState('ALL')

  const [open, setopen, getopen] = useGetState(false)
  const [visible, setvisible] = useState(false)
  const [visible1, setvisible1] = useState(false)
  const [visible2, setvisible2] = useState(false)
  const [visible3, setvisible3] = useState(false)
  const [cityDataArr, setCityDataArr] = useState([])
  const [industryDataArr, setIndustryDataArr] = useState([])

  const [regCapitalRangeMin, setregCapitalRangeMin] = useState('')
  const [regCapitalRangeMax, setregCapitalRangeMax] = useState('')

  const [actualCapitalRangesMin, setactualCapitalRangesMin] = useState('')
  const [actualCapitalRangesMax, setactualCapitalRangesMax] = useState('')
  const [socialSecurityMin, setsocialSecurityMin] = useState('')
  const [socialSecurityMax, setsocialSecurityMax] = useState('')
  const [CreditData, setCreditData] = useState([])
  const [CreditDataList, setCreditDataList] = useState([])
  const [CreditNum, setCreditNum] = useState(0)
  const [hisOpen, setHisOpen] = useState(false)

  const search = (info) => { }

  const reset = () => { }

  useEffect(() => {
    GetList()
  }, [DropdownData, handleChecked, companyName, SearchType, areaData, nationalData, CreditData])
  useEffect(() => {
    // GetOpp()
  }, [])
  const PostOpp = async () => { }
  const resetfilter = () => {
    creditSecurityfount = 'ALL'
    socialSecurityfount = 'ALL'
    regCapitalfount = 'ALL'
    actualfount = 'ALL'
    setvalue1name('ALL')
    setCreditData([])
    setCreditDataList([])
    setnationalData([])
    setInfoTypeList([])
    setareaData([])
    setCityByData([])
    setHandleData([])
    handleCheckedCitiesChange([])
    setCompanyName('')
    setDropdownData([{}])
  }
  const GetList = async () => {
    const searchType = SearchType.flat(Infinity)
    const obj = {
      pageNum: 1,
      pageSize: 10,
      ...DropdownData[0],
      ...handleChecked,
    }
    if (companyName) {
      obj.companyName = companyName
      obj.searchTypes = searchType
    } else {
      delete obj.searchTypes
    }
    if (handleData.find((v) => v == value1name + '&成立年限&' + value1name)) {
      obj.timeRanges = [
        {
          establishRangeEnd: value1name.slice(11),
          establishRangeStart: value1name.slice(0, 10),
        },
      ]
    }
    if (areaData.length) {
      obj.areaCodes = areaData
    } else {
      delete obj.areaCodes
    }
    if (areaData.length) {
      obj.areaCodes = areaData
    } else {
      delete obj.areaCodes
    }
    if (nationalData.length) {
      obj.cateGoryCodes = nationalData
    } else {
      delete obj.cateGoryCodes
    }
    if (CreditData.length && handleData.includes('ALL&企业类型&ALL')) {
      obj.creditCodes = CreditData
    } else {
      delete obj.creditCodes
    }
    const obj1 = obj
    delete obj1.pageNum
    delete obj1.pageSize
    if (JSON.stringify(obj1) == '{}') {
      return
    } else {
      obj.pageNum = 1
      obj.pageSize = 10
    }
    setLoading(true)
    let res = await Axios(
      "post",
      "/custInfos/company/info/v1",
      obj,
      "fireflyErp"
    );
    if (res.isSuccess === 'T') {
      if (!res.body || !res.body.totalNum) {
        // message.error('请输入更准确的关键词或更换筛选条件')
        setTotalNum(0)
      }
      setTotalNum(res.body.totalNum || 0)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }
  const PageChange = async (page, pageSize) => {
    setPageNum(page)
    setPageSize(pageSize)
  }
  const handleClose = async (tag) => {
    if (tag === '国民行业') {
      setnationalData([])
      setInfoTypeList([])
    } else if (tag === '省份地区') {
      setareaData([])
      setCityByData([])
    }

    const arr = []
    let name = ''
    handleData.forEach((e) => {
      name = e.split('&')
      if (name[1] != tag) {
        arr.push(e)
      }
    })
    setHandleData(arr)
    handleCheckedCitiesChange(arr)
  }
  const handleClose1 = async (tag) => {
    delete DropdownData[0][obj2[tag]]
    setDropdownData([...DropdownData])
  }
  const handle = async (search1) => {
    let name = []
    const obj = {}
    const arr = []
    search1.map((v) => {
      name = v.split('&')
      obj.keyWord = name[1]
      obj.content = name[2]
    })
  }

  const ReplaceFun = (array) => {
    const arr = []
    let str = ''
    let str1 = ''
    array.forEach((v) => {
      str1 = v.split('&')[1]
      str = v.replace(str1, SeatchDataObj[str1])
      arr.push(str)
    })
    return arr
  }
  const handleVisibleChange = (value) => {
    if (value === 0) {
      setvisible(!visible)
    } else if (value === 1) {
      setvisible1(!visible1)
    } else if (value === 2) {
      setvisible2(!visible2)
    }
  }
  const RangePickerChange = (value, dateString) => {
    console.log('Formatted Selected Time: ', dateString)
    setvalue1name(dateString[0] + '-' + dateString[1])
    setopen(!getopen())
    handleCheckedCitiesChange([...handleData, dateString[0] + '-' + dateString[1] + '&成立年限&' + dateString[0] + '-' + dateString[1]])
  }

  const openDatePanel = () => {
    if (value1name == 'ALL') {
      return
    }
    setopen(!getopen())
  }

  const handleCheckedCitiesChange = (value) => {
    if (value.includes('ALL&注册资本&ALL') && regCapitalfount == 'ALL') {
      setvisible(!visible)
      return
    } else if (value.includes('ALL&成立年限&ALL') && value1name == 'ALL') {
      setopen(!getopen())
      return
    } else if (value.includes('ALL&实缴资本&ALL') && actualfount == 'ALL') {
      setvisible1(!visible1)
      return
    } else if (value.includes('ALL&企业类型&ALL') && creditSecurityfount == 'ALL') {
      setvisible3(!visible3)
      return
    } else if (value.includes('ALL&参保人数&ALL') && socialSecurityfount == 'ALL') {
      setvisible2(!visible2)
      return
    }
    const arr = {}
    const search1 = {}
    let name = []
    let name1 = []
    let obj = {}
    const key = []
    value.forEach((e) => {
      name = e.split('&')
      if (name[2] !== '自定义') {
        name1 = objType[name[1]].split('&')
        obj = {}

        if (name1[1] == 'MAX') {
          obj.max = name[0].split('-')[1]
          obj.min = name[0].split('-')[0]
        } else if (name1[1] == 'ARR') {
          obj = name[0]
        } else {
          obj[name1[1]] = name[0]
        }
        if (name[0] != 'ALL') {
          if (arr[name[1]]) {
            arr[name[1]] = arr[name[1]] + ',' + name[2]
            search1[name1[0]] = search1[name1[0]].concat([obj])
          } else {
            arr[name[1]] = name[2]

            search1[name1[0]] = [obj]
          }
        }
      }
    })
    setHandleChecked(search1)
    setHandleData(value)
    setUrlData(value)
    handle(value)
    console.log(value)
    if (creditSecurityfount == '企业类型' && value.includes('ALL&企业类型&ALL')) {
      if (arr['企业类型']) {
        arr['企业类型'] = arr['企业类型'] + ',' + (creditDataArr || []).toString()
      } else {
        arr['企业类型'] = (creditDataArr || []).toString()
      }
    }
    for (const val in arr) {
      key.push({
        name: val,
        data: arr[val],
      })
    }
    setTagData([...key])
  }
  const nationalChange = (value) => {
    const obj = []
    area(value, true).map((v) => {
      if (JSON.stringify(v) != '{}') {
        obj.push(v)
      }
    })
    setIndustryDataArr(cityData(2, obj) || [])
    setnationalData(obj)
    setInfoTypeList(value)
  }
  const onCreditChange = (value) => {
    setCreditNum(value.length)
    setCreditData(cityFun2(value))
    creditDataArr = cityData(3, cityFun2(value) || []) || []
    setCreditDataList(value)
    if (value.length) {
      creditSecurityfount = '企业类型'
      let arr = Array.from(new Set([...handleData, ...['ALL&企业类型&ALL']]))
      handleCheckedCitiesChange(arr)
    } else {
      creditSecurityfount = 'ALL'
      handleCheckedCitiesChange(handleData.filter((v) => v != 'ALL&企业类型&ALL'))
    }
  }
  const cityFun2 = (e) => {
    let arr = [],
      obj = {}
    e.forEach((v) => {
      obj = {}
      if (v.length == 1) {
        obj.f = v[0]
        arr.push(obj)
      } else {
        if (arr.filter((p) => p.f == v[0]).length) {
          arr.map((k) => {
            if (k.f == v[0]) {
              k.s = k.s.concat({ s: v[1] })
            }
          })
        } else {
          obj.f = v[0]
          obj.s = [{ s: v[1] }]
          arr.push(obj)
        }
      }
    })
    return arr
  }
  const SearchTypeChange = (value) => {
    setSearchType(value)
  }
  const changes = (obj) => {
    const arr = []
    for (const p in obj)
      if (obj.hasOwnProperty(p)) {
        arr.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
      }
    return arr.join('&')
  }
  const onareaChange = (value) => {
    console.log(value)
    const arr = []
    value.map((v) => {
      arr.push(v[v.length - 1])
    })
    console.log(arr)
    // const obj = []
    // area(value).map((v) => {
    //   if (JSON.stringify(v) != '{}') {
    //     obj.push(v)
    //   }
    // })
    const obj11 = {}
    let obj12 = {}
    cityByCodeData.forEach((v) => {
      obj12 = {}
      v.children &&
        v.children.forEach((e) => {
          if (e.children) {
            obj12[e.value.slice(2, 4)] = e.children.length
          }
        })
      obj11[v.value.slice(0, 2)] = obj12
    })
    const obj = cityFun1(arr, obj11)
    console.log(obj, 'obj')
    obj.forEach((v) => {
      if (v.s) {
        v.s.forEach((e) => {
          if (e.s == '00') {
            delete v.s
          } else {
            if (e.t) {
              e.t.forEach((i) => {
                console.log(e.t, 'i.t')
                if (e.t) {
                  if (e.t.includes('00')) {
                    delete e.t
                  }
                }
              })
            }
          }
        })
      }
    })
    setCityDataArr(cityData(1, obj) || [])
    setareaData(obj)
    setCityByData(value)
  }
  const cityFun1 = (e, DataLength) => {
    let f, s, t
    let arr = [],
      obj = {},
      obj1 = {}

    e.forEach((v) => {
      f = v.slice(0, 2)
      s = v.slice(2, 4)
      t = v.slice(4, 9)

      if (obj[f]) {
        if (obj[f][s]) {
          obj1[s] = [t].concat(obj[f][s])
          obj[f] = obj1
        } else {
          obj1[s] = [t]
          obj[f] = obj1
        }
      } else {
        obj1 = {}
        obj1[s] = [t]
        obj[f] = obj1
      }
    })
    let arr4 = [],
      arr5 = []
    for (const key in obj) {
      arr5 = []
      for (const val in obj[key]) {
        arr5.push({ s: val, t: obj[key][val] })
      }
      arr4.push({
        f: key,
        s: arr5,
      })
    }
    const arr6 = []
    let istrue = false
    let num12 = 0
    let num13 = 0
    const obj320 = {}
    const cityByCodeListArr = [...cityByCodeData]
    cityByCodeListArr.forEach((v) => {
      num13 = 0
      v.children &&
        v.children.forEach((e) => {
          num13 += e.children ? e.children.length : 0
        })
      obj320[v.value.slice(0, 2)] = num13
    })
    // this.obj121 = obj320;
    arr4.forEach((v) => {
      num12 = 0
      v.s.forEach((e) => {
        num12 += e.t.length
      })
      if (num12 === obj320[v.f]) {
        delete v.s
      }
    })
    arr4.forEach((v) => {
      istrue = true
      if (v.s) {
        v.s.forEach((e, index) => {
          if (DataLength[v.f][e.s] === e.t.length) {
            delete v.s[index].t
          } else {
            istrue = false
          }
        })
        if (v.s.length == Object.keys(DataLength[v.f]).length && istrue) {
          delete v.s
        }
      }
      arr6.push(v)
    })

    return arr6
  }
  const area = (value, isS) => {
    let ff, ss, tt
    let obj = {}
    const arr = []
    value.map((v) => {
      obj = {}
      if (v.length === 1) {
        ff = isS ? v[0] : v[0].slice(0, 2)
        obj['f'] = ff
      } else if (v.length === 2) {
        ff = isS ? v[0] : v[0].slice(0, 2)
        ss = isS ? v[1] : v[1].slice(2, 4)
        if (
          arr.findIndex(function (value) {
            return value.f === ff
          }) + 1
        ) {
          arr.map((e) => {
            if (e.f === ff) {
              e['s'] = e['s'].concat({ s: ss })
            }
          })
        } else {
          obj['f'] = ff
          obj['s'] = [{ s: ss }]
        }
      } else if (v.length === 3) {
        ff = isS ? v[0] : v[0].slice(0, 2)
        ss = isS ? v[1] : v[1].slice(2, 4)
        tt = isS ? v[2] : v[2].slice(4, 6)
        if (
          arr.findIndex(function (value) {
            return value.f === ff
          }) + 1
        ) {
          let cen = []
          arr.map((val) => {
            if (val.f === ff) {
              cen = val.s
            }
          })
          if (
            cen.findIndex(function (value) {
              return value.s === ss
            }) + 1
          ) {
            arr.map((e) => {
              if (e.f === ff) {
                e.s.map((p) => {
                  if (p.s === ss) {
                    p['t'] = p.t.concat(tt)
                  }
                })
              }
            })
          } else {
            arr.map((e) => {
              if (e.f === ff) {
                e['s'] = e.s.concat([{ s: ss, t: [tt] }])
              }
            })
          }
        } else {
          obj['f'] = ff
          obj['s'] = [{ s: ss, t: [tt] }]
        }
      }
      arr.push(obj)
    })
    return arr
  }
  const DropdownFun = () => {
    const arr = []
    let name = ''
    for (const i in DropdownData[0]) {
      if (i === 'hasPhones') {
        name = DropdownData[0][i] * 1 === 3 ? '有固定电话' : '有手机号'
        arr.push(DropdownData[0][i] * 1 === 3 ? `3&${obj1[i]}&${name}` : `2&${obj1[i]}&${name}`)
      } else {
        name = DropdownData[0][i] * 1 ? (includesKey.includes(i) ? '是' : '有') : includesKey.includes(i) ? '非' : '无'
        arr.push(DropdownData[0][i] + '&' + obj1[i] + '&' + name + obj1[i])
      }
    }
    return arr
  }
  const DropdownChange = (value, index) => {
    const obj = DropdownData[0]
    obj[value] = index
    setDropdownData([obj])
  }

  return (
    <div className='EnterpriseQuery'>
      <div className={styles.top}>
        <div
          className={styles.h1}
          style={{
            justifyContent: "left",
            alignItems: "center",
            borderTop: "1px solid #ebebeb",
            justifyContent: "space-between"
          }}
        >
          <div>
            <p style={{ margin: "0" }}>企业精准查询</p>
            <div style={{ marginLeft: "20px", marginTop: "2px" }}>
              <span style={{ marginRight: "2px" }}>400+</span>
              筛选条件，多种组合，快速从2.8亿级企业数据中筛选出目标客户
            </div>
          </div>
          <a style={{ fontSize: '16px' }} onClick={() => setHisOpen(true)}>
            历史记录
          </a>
        </div>
        {tagData.length || infoTypeList.length || cityByData.length || DropdownFun().length ? (
          <div className={styles.h2}>
            <div>
              已选条件：
              {tagData.map((value, index) =>
                (value.name + value.data).length > 12 ? (
                  <Dropdown
                    overlayStyle={{ maxWidth: 300, maxHeight: 300, overflow: 'auto' }}
                    key={index}
                    overlay={
                      <Menu
                        items={[
                          {
                            key: '1',
                            label: (
                              <>
                                {value.name} &nbsp;:&nbsp;&nbsp; {value.data}
                              </>
                            ),
                          },
                        ]}
                      />
                    }
                    placement='bottom'
                  >
                    <Tag key={index} style={{ fontSize: 16, color: '#409eff', marginTop: 10 }}>
                      {String(value.name + '  :  ' + value.data).slice(0, 16) + '  ...'}
                      <CloseOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => handleClose(value.name)} />
                    </Tag>
                  </Dropdown>
                ) : (
                  <Tag key={index} style={{ fontSize: 16, color: '#409eff', marginTop: 10 }}>
                    {value.name} &nbsp;:&nbsp;&nbsp; {value.data}
                    <CloseOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => handleClose(value.name)} />
                  </Tag>
                ),
              )}
              {DropdownFun().map((value, index) => (
                <Tag key={index} style={{ fontSize: 16, color: '#409eff', marginTop: 10 }}>
                  {value.split('&')[1]} &nbsp;:&nbsp;&nbsp; {value.split('&')[2]}
                  <CloseOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => handleClose1(value.split('&')[1])} />
                </Tag>
              ))}
              {cityByData.length ? (
                <Dropdown
                  overlayStyle={{ maxWidth: 300, maxHeight: 300, overflow: 'auto' }}
                  key={999}
                  overlay={
                    <Menu
                      items={[
                        {
                          key: '1',
                          label: (cityDataArr || []).toString(),
                        },
                      ]}
                    />
                  }
                  placement='bottom'
                >
                  <Tag style={{ fontSize: 16, color: '#409eff', marginTop: 10 }}>
                    <span style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      display: 'inline-block',
                      maxWidth: 192,
                      marginBottom: -5
                    }}>
                      {(cityDataArr || []).toString()}
                    </span>

                    <CloseOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => handleClose('省份地区')} />
                  </Tag>
                </Dropdown>
              ) : (
                ''
              )}
              {infoTypeList.length ? (
                <Dropdown
                  overlayStyle={{ maxWidth: 300, maxHeight: 300, overflow: 'auto' }}
                  key={998}
                  overlay={
                    <Menu
                      items={[
                        {
                          key: '1',
                          label: (industryDataArr || []).toString(),
                        },
                      ]}
                    />
                  }
                  placement='bottom'
                >
                  <Tag style={{ fontSize: 16, color: '#409eff', marginTop: 10 }}>
                    <span style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      display: 'inline-block',
                      maxWidth: 192,
                      marginBottom: -5
                    }}>
                      {(industryDataArr || []).toString()}
                    </span>

                    <CloseOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => handleClose('国民行业')} />
                  </Tag>
                </Dropdown>
              ) : (
                ''
              )}
            </div>
            <div className={styles.condition1} onClick={() => resetfilter()}>
              <SyncOutlined
                style={{
                  marginRight: '5px',
                }}
              />
              重置筛选
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className={styles.Center}>
        <div className={styles.CenterDiv} style={{ paddingTop: '0' }}>
          <div className={styles.CenterDiv1} style={{ alignItems: 'center' }}>
            <div className={styles.CenterDiv3}>关键词</div>
            <p style={{ display: 'flex', alignItems: 'center' }}>
              <Input
                placeholder='请输入关键词，非必填'
                style={{
                  height: '40px',
                  width: '336px',
                  borderRadius: '4px',
                }}
                onChange={(e) => setCompanyName(e.target.value)}
                value={companyName}
              />
              {!companyName ? (
                <Tooltip title='请输入关键词' placement='bottom'>
                  {/* <a ></a> */}

                  <Button type='link' disabled={!companyName} style={{ color: 'rgba(0, 0, 0, 0.65)' }}>
                    {' '}
                    查找范围 <CaretDownOutlined />
                  </Button>
                </Tooltip>
              ) : (
                <div style={{ position: 'relative' }}>
                  <Cascader
                    // getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    style={{ width: 230, opacity: 0, position: 'absolute', height: '15px' }}
                    allowClear={false}
                    options={searchTypes}
                    onChange={(e) => SearchTypeChange(e)}
                    onDropdownVisibleChange={(visable) => setSearchStatus(visable)}
                    multiple
                    maxTagCount={0}
                    bordered={false}
                    value={SearchType}
                    expandTrigger='hover'
                  />
                  <span
                    onClick={(e) => e.preventDefault()}
                    style={{ color: SearchStatus ? '#266FE8' : 'rgba(0, 0, 0, 0.9000)', marginLeft: 16, cursor: 'pointer' }}
                  >
                    <span>
                      查找范围 <CaretDownOutlined />
                    </span>
                  </span>
                </div>
              )}
            </p>
          </div>
          <div className={styles.CenterDiv1}>
            <div className={styles.CenterDiv3} style={{ alignItems: 'end' }}>国民行业</div>
            <div>
              <Cascader
                // getPopupContainer={(triggerNode) => triggerNode.parentNode}
                style={{ width: 0, opacity: 0, height: '20px' }}
                options={Industry}
                onChange={(e) => nationalChange(e)}
                onDropdownVisibleChange={(visable) => setDemessionoVisable(visable)}
                multiple
                allowClear={false}
                maxTagCount={0}
                bordered={false}
                value={infoTypeList}
                expandTrigger='hover'
              />
              <a
                onClick={(e) => e.preventDefault()}
                style={demessionVisable ? { color: '#266FE8' } : { color: 'rgba(0, 0, 0, 0.9000)' }}
              >
                <Space style={{ width: '120px' }}>
                  {infoTypeList.length ? (
                    <span style={{ color: 'rgb(51, 115, 252)', display: 'inline-block', marginTop: '8px' }}>
                      已选{infoTypeList.length}项
                      <CaretDownOutlined style={{ fontSize: 12, marginLeft: '12px' }} />
                    </span>
                  ) : (
                    <>
                      全部行业
                      <CaretDownOutlined
                        style={demessionVisable ? { color: '#266FE8', fontSize: 12 } : { color: 'rgba(0, 0, 0, 0.9000)', fontSize: 12 }}
                      />
                    </>
                  )}
                </Space>
              </a>
            </div>
          </div>
          <div className={styles.CenterDiv1}>
            <div className={styles.CenterDiv3} style={{ alignItems: 'end' }}>省份地区</div>
            <div>
              <Cascader
                // getPopupContainer={(triggerNode) => triggerNode.parentNode}
                style={{ width: 0, opacity: 0, height: '20px' }}
                options={cityByCodeData}
                onChange={(e) => onareaChange(e)}
                onDropdownVisibleChange={(visable) => setDemessionoVisable1(visable)}
                allowClear={false}
                multiple
                maxTagCount={0}
                bordered={false}
                value={cityByData}
                expandTrigger='hover'
              />
              <a
                onClick={(e) => e.preventDefault()}
                style={demessionVisable1 ? { color: '#266FE8' } : { color: 'rgba(0, 0, 0, 0.9000)' }}
              >
                <Space style={{ width: '120px' }}>
                  {cityByData.length ? (
                    <span style={{ color: 'rgb(51, 115, 252)', display: 'inline-block', marginTop: '8px' }}>
                      已选{cityByData.length}项
                      <CaretDownOutlined style={{ fontSize: 12, marginLeft: '12px' }} />
                    </span>
                  ) : (
                    <>
                      全部地区
                      <CaretDownOutlined
                        style={
                          demessionVisable1 ? { color: '#266FE8', fontSize: 12 } : { color: 'rgba(0, 0, 0, 0.9000)', fontSize: 12 }
                        }
                      />
                    </>
                  )}
                </Space>
              </a>
            </div>
          </div>
        </div>

        <Checkbox.Group style={{ width: '100%' }} value={handleData} onChange={handleCheckedCitiesChange}>
          {SeatchData.map((value, index) => {
            return (
              <div key={index} className={styles.CenterDiv}>
                <h6> {value.name}</h6>
                {value.data.map((value1, index1) => {
                  return (
                    <div key={index1} className={styles.CenterDiv1} style={{ position: 'relative' }}>
                      <div className={styles.CenterDiv3}>{value1.name}</div>
                      {value1.name == '企业类型' ? (
                        <div
                          style={{
                            position: 'absolute',
                            left: '116px',
                            // bottom: '24px',
                            background: 'white',
                            zIndex: 3,
                            paddingLeft: '8px',
                            width: 196,
                          }}
                        >
                          <Cascader
                            style={{
                              opacity: 0,
                              height: '15px',
                              width: '196px',
                              position: 'absolute',
                              zIndex: '1',
                              cursor: 'pointer',
                            }}
                            value={CreditDataList}
                            options={Credit}
                            onChange={(e) => onCreditChange(e)}
                            multiple
                            maxTagCount='responsive'
                            expandTrigger='hover'
                          />
                          <span onClick={(e) => e.preventDefault()}>
                            统一社会信用代码前两位
                            <CaretDownOutlined style={{ fontSize: 12, marginLeft: 6 }} />
                          </span>
                        </div>
                      ) : (
                        ' '
                      )}
                      {value1.name == '成立年限' ? (
                        <DatePicker.RangePicker
                          style={{
                            position: 'absolute',
                            right: '500px',
                            background: 'white',
                            zIndex: 0,
                            paddingLeft: '8px',
                            width: 196,
                          }}
                          open={open}
                          key={value1name}
                          value={[null, null]}
                          bordered={false}
                          onChange={RangePickerChange}
                          disabledDate={disabledDate}
                        // getPopupContainer={(triggerNode) => triggerNode.parentNode}
                        />
                      ) : (
                        ''
                      )}

                      <div className={styles.checks} style={{ position: 'relative' }} id='area'>
                        {value1.data.map((value2, index2) => {
                          return value1.Code === 'drop' ? (
                            <Dropdown
                              // getPopupContainer={() => document.getElementById('area')}
                              key={index2}
                              overlay={
                                <Menu
                                  items={
                                    value2.model === 'hasPhones' ? [
                                      {
                                        label: <a onClick={() => DropdownChange(value2.model, '3')}>有固定电话</a>,
                                        key: '3',
                                      },
                                      {
                                        label: <a onClick={() => DropdownChange(value2.model, '2')}>有手机号</a>,
                                        key: '2',
                                      },
                                    ] :
                                      [
                                        {
                                          label: <a onClick={() => DropdownChange(value2.model, '1')}>{(includesKey.includes(value2.model) ? '是' : '有') + value2.type}</a>,
                                          key: '1',
                                        },
                                        {
                                          label: <a onClick={() => DropdownChange(value2.model, '0')}>{(includesKey.includes(value2.model) ? '非' : '无') + value2.type}</a>,
                                          key: '0',
                                        },
                                      ]
                                  }
                                />
                              }
                              trigger={['hover']}
                            >
                              <span
                                className={styles.span}
                                style={{
                                  color: DropdownData[0][value2.model] ? '#1775FF' : '',
                                  width: 'auto',
                                }}
                              >
                                {value2.model === 'hasPhones' ? (
                                  <span>
                                    {DropdownData[0][value2.model]
                                      ? DropdownData[0][value2.model] * 1 === 3
                                        ? '有固定电话'
                                        : '有手机号'
                                      : '联系方式'}
                                  </span>
                                ) : (
                                  <span>
                                    {DropdownData[0][value2.model]
                                      ? DropdownData[0][value2.model] * 1
                                        ? includesKey.includes(value2.model)
                                          ? '是'
                                          : '有'
                                        : includesKey.includes(value2.model)
                                          ? '非'
                                          : '无'
                                      : ''}
                                    {value2.type}
                                  </span>
                                )}
                                <CaretDownOutlined />
                              </span>
                            </Dropdown>
                          ) : value2.type == '自定义' && value1.name == '成立年限' ? (
                            <Checkbox key={index2} value={value1name + '&' + value1.name + '&' + value1name}>
                              <span
                                onClick={(e) => {
                                  openDatePanel()
                                }}
                              >
                                {value1name == 'ALL' ? '自定义' : value1name}
                                <span style={{ color: 'red', fontSize: '12px' }}>(近15天新成立企业不可查询)</span>
                              </span>
                            </Checkbox>
                          ) : value2.type == '自定义' && value1.name == '注册资本' ? (
                            <Checkbox key={index2} value={regCapitalfount + '&' + value1.name + '&' + regCapitalfount}>
                              <Popover
                                key={1}
                                open={visible}
                                trigger='hover'
                                placement='bottom'
                                onVisibleChange={() => handleVisibleChange(0)}
                                content={
                                  <div
                                    style={{
                                      padding: 10,
                                      maxWidth: '500px',
                                      fontSize: '14px',
                                      wordBreak: 'break-word',
                                    }}
                                  >
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                      从
                                      <Input
                                        value={regCapitalRangeMin}
                                        style={{ width: '140px', marginLeft: 5 }}
                                        suffix='万'
                                        onChange={(e) => setregCapitalRangeMin(e.target.value)}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginTop: 10,
                                      }}
                                    >
                                      至
                                      <Input
                                        value={regCapitalRangeMax}
                                        suffix='万'
                                        style={{ width: '140px', marginLeft: 5 }}
                                        onChange={(e) => setregCapitalRangeMax(e.target.value)}
                                      />
                                    </div>
                                    <div style={{ textAlign: 'right', marginTop: '15px' }}>
                                      <Button
                                        type='primary'
                                        onClick={() => {
                                          let handleDatas = handleData.filter((v) => v != regCapitalfount + '&注册资本&' + regCapitalfount)
                                          if (regCapitalRangeMin || regCapitalRangeMax) {
                                            let str = ''
                                            if (regCapitalRangeMin && regCapitalRangeMax) {
                                              str = '-'
                                            } else {
                                              str = ''
                                            }
                                            regCapitalfount = regCapitalRangeMin + str + regCapitalRangeMax
                                            let arr = Array.from(
                                              new Set([...handleDatas, ...[regCapitalfount + '&注册资本&' + regCapitalfount]]),
                                            )
                                            handleCheckedCitiesChange(arr)
                                            setvisible(!visible)
                                          } else {
                                            message.error('请输入更准确的关键词或更换筛选条件')
                                          }
                                        }}
                                      >
                                        确定
                                      </Button>
                                    </div>
                                  </div>
                                }
                              >
                                <span
                                  onClick={() => {
                                    if (!(regCapitalfount == 'ALL')) setvisible(!visible)
                                  }}
                                >
                                  {regCapitalfount == 'ALL' ? '自定义' : regCapitalfount + '万'}
                                </span>
                              </Popover>
                            </Checkbox>
                          ) : value2.type == '自定义' && value1.name == '实缴资本' ? (
                            <Checkbox key={index2} value={actualfount + '&' + value1.name + '&' + actualfount}>
                              <Popover
                                key={2}
                                open={visible1}
                                trigger='hover'
                                placement='bottom'
                                onVisibleChange={() => handleVisibleChange(1)}
                                content={
                                  <div
                                    style={{
                                      padding: 10,
                                      maxWidth: '500px',
                                      fontSize: '14px',
                                      wordBreak: 'break-word',
                                    }}
                                  >
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                      从
                                      <Input
                                        value={actualCapitalRangesMin}
                                        style={{ width: '140px', marginLeft: 5 }}
                                        suffix='万'
                                        onChange={(e) => setactualCapitalRangesMin(e.target.value)}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginTop: 10,
                                      }}
                                    >
                                      至
                                      <Input
                                        value={actualCapitalRangesMax}
                                        suffix='万'
                                        style={{ width: '140px', marginLeft: 5 }}
                                        onChange={(e) => setactualCapitalRangesMax(e.target.value)}
                                      />
                                    </div>
                                    <div style={{ textAlign: 'right', marginTop: '15px' }}>
                                      <Button
                                        type='primary'
                                        onClick={() => {
                                          let handleDatas = handleData.filter((v) => v != actualfount + '&实缴资本&' + actualfount)
                                          if (actualCapitalRangesMin || actualCapitalRangesMax) {
                                            let str = ''
                                            if (actualCapitalRangesMin && actualCapitalRangesMax) {
                                              str = '-'
                                            } else {
                                              str = ''
                                            }
                                            actualfount = actualCapitalRangesMin + str + actualCapitalRangesMax
                                            let arr = Array.from(
                                              new Set([...handleDatas, ...[actualfount + '&实缴资本&' + actualfount]]),
                                            )
                                            handleCheckedCitiesChange(arr)
                                            setvisible1(!visible1)
                                          } else {
                                            message.error('请输入更准确的关键词或更换筛选条件')
                                          }
                                        }}
                                      >
                                        确定
                                      </Button>
                                    </div>
                                  </div>
                                }
                              >
                                <span
                                  onClick={() => {
                                    if (!(actualfount == 'ALL')) setvisible1(!visible1)
                                  }}
                                >
                                  {actualfount == 'ALL' ? '自定义' : actualfount + '万'}
                                </span>
                              </Popover>
                            </Checkbox>
                          ) : value2.type == '自定义' && value1.name == '参保人数' ? (
                            <Checkbox key={index2} value={socialSecurityfount + '&' + value1.name + '&' + socialSecurityfount}>
                              <Popover
                                key={3}
                                open={visible2}
                                trigger='hover'
                                placement='bottom'
                                onVisibleChange={() => handleVisibleChange(2)}
                                content={
                                  <div
                                    style={{
                                      padding: 10,
                                      maxWidth: '500px',
                                      fontSize: '14px',
                                      wordBreak: 'break-word',
                                    }}
                                  >
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                      从
                                      <Input
                                        value={socialSecurityMin}
                                        style={{ width: '140px', marginLeft: 5 }}
                                        suffix='人'
                                        onChange={(e) => setsocialSecurityMin(e.target.value)}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginTop: 10,
                                      }}
                                    >
                                      至
                                      <Input
                                        value={socialSecurityMax}
                                        suffix='人'
                                        style={{ width: '140px', marginLeft: 5 }}
                                        onChange={(e) => setsocialSecurityMax(e.target.value)}
                                      />
                                    </div>
                                    <div style={{ textAlign: 'right', marginTop: '15px' }}>
                                      <Button
                                        type='primary'
                                        onClick={() => {
                                          let handleDatas = handleData.filter(
                                            (v) => v != socialSecurityfount + '&参保人数&' + socialSecurityfount,
                                          )
                                          if (socialSecurityMin || socialSecurityMax) {
                                            let str = ''
                                            if (socialSecurityMin && socialSecurityMax) {
                                              str = '-'
                                            } else {
                                              str = ''
                                            }
                                            socialSecurityfount = socialSecurityMin + str + socialSecurityMax
                                            let arr = Array.from(
                                              new Set([...handleDatas, ...[socialSecurityfount + '&参保人数&' + socialSecurityfount]]),
                                            )
                                            handleCheckedCitiesChange(arr)
                                            setvisible2(!visible2)
                                          } else {
                                            message.error('请输入更准确的关键词或更换筛选条件')
                                          }
                                        }}
                                      >
                                        确定
                                      </Button>
                                    </div>
                                  </div>
                                }
                              >
                                <span
                                  onClick={() => {
                                    if (!(socialSecurityfount == 'ALL')) setvisible2(!visible2)
                                  }}
                                >
                                  {socialSecurityfount == 'ALL' ? '自定义' : socialSecurityfount + '人'}
                                </span>
                              </Popover>
                            </Checkbox>
                          ) : value2.type == '自定义' && value1.name == '企业类型' ? (
                            <Checkbox key={index2} value={'ALL&' + value1.name + '&ALL'}>
                              <span style={{ display: 'inline-block', width: '170px' }}></span>
                            </Checkbox>
                          ) : (
                            <Checkbox key={index2} value={value2.model + '&' + value1.name + '&' + value2.type}>
                              {value2.type}
                            </Checkbox>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </Checkbox.Group>
        {/* 历史数据抽屉 */}
        <SearchHistoryDrawer
          open={hisOpen}
          onClose={() => {
            setHisOpen(false)
          }}
        />
      </div>

      {tagData.length || DropdownFun().length || cityByData.length || companyName || infoTypeList.length ? (
        <div className={styles.Footer}>
          <p>
            为您找到 <span style={{ color: totalNum ? '#1775ff' : 'red' }}>{Loading ? <LoadingOutlined /> : totalNum}</span>{' '}
            家符合条件的企业
          </p>
          <p>
            <Button onClick={() => resetfilter()} className={styles.Button} style={{ width: '96px', height: '40px' }}>
              重置筛选
            </Button>
            <Button
              onClick={() => {
                let arr = []
                if (urlData.includes('ALL&企业类型&ALL')) {
                  arr.push('5&企业类型&企业类型')
                  urlData.push('ALL&企业类型&自定义')
                }
                const nameStr = value1name + '&成立年限&' + value1name
                const urlDataAtt = urlData.filter((v) => v != nameStr).filter((v) => v != 'ALL&企业类型&ALL')
                let time = ''
                if (handleData.find((v) => v == nameStr)) {
                  time = value1name.slice(0, 10) + '至' + value1name.slice(11)
                  arr = [time + '&1&' + time]
                }
                const query =
                  '?checkedCities=' +
                  JSON.stringify(ReplaceFun([...urlDataAtt, ...DropdownFun()])).replaceAll('&', '@') +
                  '&cateGoryCodes=' +
                  JSON.stringify(nationalData) +
                  '&areaCodes=' +
                  JSON.stringify(areaData) +
                  '&creditCodes=' +
                  JSON.stringify(CreditData) +
                  '&companyName=' +
                  companyName +
                  '&searchTypes=' +
                  JSON.stringify(SearchType.flat(Infinity)) +
                  '&arr12=' +
                  JSON.stringify(arr).replaceAll('&', '@');
                const url = '/advancedSearch/advancedList' + query;
                // console.log('123===', new URLSearchParams(url))
                const params = new URLSearchParams(query)
                const paramObj = {}
                params.forEach((value, key) => {
                  // 将参数名作为属性，参数值作为属性值添加到对象中
                  paramObj[key] = value
                })
                //开始存储查询条件
                const his_sto = localStorage.getItem('advanceHisStorage')
                  ? JSON.parse(localStorage.getItem('advanceHisStorage'))
                  : []
                if (his_sto.length > 9) {
                  his_sto.pop()
                }
                his_sto.unshift({
                  date: Filter.timetrans(new Date(), 'time_HM'),
                  data: paramObj,
                })
                localStorage.setItem('advanceHisStorage', JSON.stringify(his_sto))
                console.log(paramObj)
                navigateLegalWebsite(url)
              }}
              type='primary'
              style={{ width: '160px', marginLeft: 16, height: '40px' }}
            >
              查询
            </Button>
          </p>
        </div>
      ) : (
        ''
      )}
      <style>
        {`
                    .ant-select-selector{
                        width: 196px !important
                    }
                `}
      </style>
    </div>
  )
}

export default EnterpriseQuery
